import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#1C1B1F",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
  main: "#8B8484",
  light: "#919eab52",
  dark: "#2d393e",
};

const themePalettes = [
  {
    slug: "cherry",
    lighter: "#dcd9e0",
    light: "#bab3c2",
    main: "#524266",
    dark: "#756885",
    darker: "#978ea3",
    contrastText: "#fff",
  },
  {
    slug: "blueberry",
    lighter: "#e6e0fd",
    light: "#cdc2fb",
    main: "#8366f6",
    dark: "#b5a3fa",
    darker: "#9c85f8",
    contrastText: "#fff",
  },
  {
    slug: "kiwi",
    lighter: "#e7f6f3",
    light: "#ceede7",
    main: "#76baad",
    dark: "#b6e5dc",
    darker: "#9ddcd0",
    contrastText: "#fff",
  },
  {
    slug: "pineapple",
    lighter: "#FFF4D7",
    light: "#FBE39F",
    main: "#D9A930",
    dark: "#F8D67F",
    darker: "#F4C242",
    contrastText: "#fff",
  },
];

const INFO = {
  lighter: "#E7F9FF",
  light: "#D0F2FF",
  main: "#2BBCEA",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#02EC27",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const UPLOAD = {
  lighter: "#E8CEE2",
  light: "#F7D2FF",
  main: "#AA5095",
  dark: "#68315B",
  darker: "#45213D",
  contrastText: "#8A00B8",
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#FFB800",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFEFEF",
  light: "#ffd3d3",
  main: "#FF0000",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const GRADIENTS = {
  primary: createGradient(themePalettes[0].light, themePalettes[0].main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
  red1: ["#a8000f", "#d11d28", "#e53c37", "#F07E7B"],
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...themePalettes[0] },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  upload: { ...UPLOAD },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  form: { border: GREY[400], focus: GREY[600] },
  text: { primary: "#8B8484", secondary: GREY[600], disabled: GREY[500] },
  background: { paper: "#fff", default: GREY[100], neutral: GREY[200], card: "#F7F7F8" },
  themePalettes,
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
