import { createSlice } from "@reduxjs/toolkit";

import { handleGetPageData, handleSubmitForm, populateFormState, updateGameById } from "./actions";
import { additionalInfoCats, allCurrencies, countriesList, device, menuItems } from "./staticData";

const formSlice = createSlice({
  name: "form",
  initialState: {
    formData: null,
    isFormChaged: false,
    isTemplateChanged: false,
    isLoading: false,
    isSubmitting: false,
    orderCategory: "",
    defaultPalette: null,
    activePalette: null,
    isFormLoading: false,
    readyForSubmit: false,
    pageData: {
      loading: false,
      singleData: null,
      error: null,
      all: null,
      allOrder: null,
      categories: null,
      dndOrderOptions: [],
    },
    staticData: {
      "st-currencies": allCurrencies,
      "st-countries-list": countriesList,
      "st-menu-items": menuItems,
      "st-additional-info-cats": additionalInfoCats,
      "st-device": device,
    },
  },
  reducers: {
    clearPageData: (state, action) => {
      return {
        ...state,
        pageData: {
          loading: false,
          singleData: null,
          error: null,
          all: null,
          allOrder: null,
          categories: null,
          providers: null,
          tags: null,
          device: null,
        },
      };
    },
    handleChangeOrderCategory: (state, action) => {
      const { category, data } = action.payload;
      return {
        ...state,
        orderCategory: category,
        pageData: {
          ...state.pageData,
          singleData: data,
        },
      };
    },
    handleDefaultPalette: (state, action) => {
      return {
        ...state,
        defaultPalette: action.payload,
      };
    },
    handleActivePalette: (state, action) => {
      return {
        ...state,
        activePalette: action.payload === state.activePalette ? null : action.payload,
      };
    },
    handleFormSubmitting: (state, action) => {
      return {
        ...state,
        isSubmitting: action.payload,
      };
    },
    handleReadySubmit: (state, action) => {
      return {
        ...state,
        readyForSubmit: !state.readyForSubmit,
      };
    },
    clearFormData: (state, action) => {
      return {
        ...state,
        formData: null,
      };
    },
    handlePageLoading: (state) => {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    },
    handleAddDNDOrderOptions: (state, action) => {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          dndOrderOptions: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateFormState.pending, (state, action) => {
      return {
        ...state,
        isFormLoading: true,
      };
    });
    builder.addCase(populateFormState.fulfilled, (state, action) => {
      return {
        ...state,
        isFormLoading: false,
        formData: action.payload.state,
        isFormChaged: action.payload.isChanged,
      };
    });
    builder.addCase(handleGetPageData.pending, (state, action) => {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          loading: true,
        },
      };
    });
    builder.addCase(handleGetPageData.fulfilled, (state, action) => {
      const { objPath, data } = action.payload;
      return {
        ...state,
        pageData: {
          ...state.pageData,
          loading: false,
          [objPath]: data,
        },
      };
    });
    builder.addCase(updateGameById.pending, (state, action) => {
      return {
        ...state,
      };
    });
    builder.addCase(updateGameById.fulfilled, (state, action) => {
      const { gameID, data, toGames } = action.payload;
      const updatedGamesData = toGames ? [...state.pageData.games] : [...state.pageData.all];
      const index = updatedGamesData?.findIndex((item) => item?.id === parseInt(gameID));
      updatedGamesData[index] = data;
      return {
        ...state,
        isLoading: false,
        pageData: {
          ...state.pageData,
          [toGames ? "games" : "all"]: updatedGamesData,
        },
      };
    });
    builder.addCase(handleSubmitForm.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        isSubmitting: true,
      };
    });
    builder.addCase(handleSubmitForm.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const pageType = meta?.arg?.pageType || null;

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        ...(pageType &&
          pageType === "games" && {
            pageData: {
              ...state.pageData,
              singleData: payload,
            },
          }),
      };
    });
    builder.addCase(handleSubmitForm.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        pageData: {
          ...state.pageData,
          error: action.error,
        },
      };
    });
  },
});

const { reducer, actions } = formSlice;
export const {
  clearPageData,
  handleChangeOrderCategory,
  handleDefaultPalette,
  handleFormSubmitting,
  handleActivePalette,
  handleReadySubmit,
  clearFormData,
  handlePageLoading,
  handleAddDNDOrderOptions,
} = actions;
export default reducer;
