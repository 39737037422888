import dayjs from "dayjs";

import instance from "../api_config";

const getPageData = (apiPath, signal) => {
  return instance.get(`${apiPath}&created_at_lte=${dayjs().format("YYYY-MM-DDTHH:mm:ss")}`, {
    signal: signal,
  });
};

const getPageCategoryData = (apiPath) => {
  return instance.get(apiPath);
};

const deletePage = (apiPath, data) => {
  return instance.put(apiPath, data);
};

const updateGameById = (id, data) => {
  return instance.put(`/games/${id}`, data);
};
const getGameById = (id) => {
  return instance.get(`/games/${id}`);
}
const formSubmit = (apiPath, apiMeth, portalIdentifier, data, isSettingsLayout) => {
  const overridePath = isSettingsLayout ? apiPath : `${apiPath}?portal=${portalIdentifier}&_limit=-1`;
  return instance[apiMeth](overridePath, data);
};

const formService = {
  getPageData,
  getPageCategoryData,
  deletePage,
  updateGameById,
  getGameById,
  formSubmit,
};

export default formService;
