import { useEffect, useState } from "react";

const useCustomPagination = (data, defaultRowsPerPage = 3, pageType) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [goToPageInput, setGoToPageInput] = useState({ isValid: true, inputValue: null });
  const paginationPageOptions = data ? Math.ceil(data.length / rowsPerPage) : 1;
  const arrayOfPageOptions = Array.from({ length: paginationPageOptions }, (value, index) => index + 1);
  const sliceArgFirst = rowsPerPage === -1 ? 0 : page * rowsPerPage;
  const sliceArgSecond = rowsPerPage === -1 ? data.length : page * rowsPerPage + rowsPerPage;

  useEffect(() => {
    setPage(0);
  }, [pageType]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const handleChangePagination = (e, selectedPage) => {
    setPage(selectedPage - 1);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      isNaN(parseInt(e.target.value)) ? setPage(0) : setPage(e.target.value - 1);
    }
  };

  const handleGoToPageOnEnter = (e) => {
    if (arrayOfPageOptions.includes(parseInt(e.target.value)) || e.target.value === "") {
      setGoToPageInput({ isValid: true, inputValue: parseInt(e.target.value) });
    } else setGoToPageInput({ isValid: false, inputValue: null });
  };

  const handleGoToPageOnClick = (e) => {
    isNaN(parseInt(goToPageInput.inputValue)) ? setPage(0) : setPage(goToPageInput.inputValue - 1);
  };

  return {
    page,
    rowsPerPage,
    goToPageInput,
    paginationPageOptions,
    sliceArgFirst,
    sliceArgSecond,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangePagination,
    handleKeyDown,
    handleGoToPageOnEnter,
    handleGoToPageOnClick,
  };
};

export default useCustomPagination;
