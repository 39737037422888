import React from "react";

import { Grid } from "@mui/material";

import { StyledInput } from "./StyledAccordion";

function PreviewField({ formEl, typography, changeFont }) {
  const typographyElement = typography?.[formEl]?.forms?.[0];

  const baseFontSizeRem =
    typographyElement?.[`${formEl}FontSize`]?.value || typographyElement?.[`${formEl}FontSize`]?.deffValue;
  const textStyle = typographyElement?.[`${formEl}TextStyle`]?.value;
  const fontWeight = typographyElement?.[`${formEl}FontWeight`]?.value;
  const letterSpacing = typographyElement?.[`${formEl}LetterSpacing`]?.value * baseFontSizeRem;
  const lineSpacing = typographyElement?.[`${formEl}LineSpacing`]?.value;

  const secondaryFont = typography?.secondaryFont?.value;
  const mainFont = typography?.mainFont?.value;
  const fontFamily = changeFont ? secondaryFont : mainFont;

  const text =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero voluptatibus nesciunt rerum minus quibusdam, vitae neque non at nam laborum ipsam ab, consequuntur officia facere repellat quod praesentium ratione corporis! Quibusdam, aut et facilis laborum quaerat nemo iure nihil molestias officia numquam doloremque dolor enim rem est cum ab!";
  const inputValue = formEl !== "bodyText" ? text.substring(0, 50) + "..." : text;

  return (
    <Grid item className="form-field" xs={12} md={12} style={{ padding: 12, maxWidth: "unset" }}>
      <StyledInput
        multiline={formEl === "bodyText" && true}
        label="Font preview"
        value={inputValue}
        disabled={true}
        fontFamily={fontFamily}
        fontSize={baseFontSizeRem}
        textStyle={textStyle}
        fontWeight={fontWeight}
        letterSpacing={letterSpacing}
        lineSpacing={lineSpacing}
        sx={{ width: "100%" }}
      />
    </Grid>
  );
}

export default PreviewField;
