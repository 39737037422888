import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDialog = styled(Dialog)(({ theme, width, height }) => ({
  zIndex: 99999999,
  "& .MuiDialog-paper": {
    minWidth: width ? "none" : "50%",
    maxWidth: width ? "none" : "60%",
    width: width,
    height: height || null,
    maxHeight: "95%",
    overflow: "hidden",
  },
  "& .MuiBackdrop-root.css-19pvkkn-MuiBackdrop-root-MuiDialog-backdrop": {
    background: "rgba(0, 0, 0, 0.5)",
  },
  ".dialog-header-wrapp": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "28px 24px",
    h3: {
      margin: 0,
    },
  },
}));
