import { TextField } from "@material-ui/core";
import { SubdirectoryArrowRightOutlined } from "@mui/icons-material";
import { Popper, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RevisioRevert = styled(SubdirectoryArrowRightOutlined)(({ theme }) => ({
  color: theme.palette.error.main,
  transform: "rotate(180deg)",
  cursor: "pointer",
}));

export const SearchTextField = styled(TextField)(({ theme }) => ({
  ".MuiInput-underline:after": {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

export const ActionRowWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: "none",
}));

export const StyledPaginationWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.grey[100],
  padding: "0 16px",
  border: "none",
}));

export const TableCellMax165 = styled(TableCell)(() => ({
  maxWidth: 165,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const StyledRevisionPopper = styled(Popper)(({ theme }) => ({
  zIndex: 9999,
  boxShadow: "0 0 12px 0 rgb(0,0,0, 0.2)",
  borderRadius: "10px",
  maxWidth: "50%",
  maxHeight: "240px",
  overflowY: "auto",
}));

export const StyledRevisionTitle = styled("div")(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",
  textAlign: "left",
}));
