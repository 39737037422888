import axios from "axios";

import SnackbarUtil from "../Components/Utils/SnackbarUtilConfig";

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwt");
  const portal = localStorage.getItem("portal");
  config.headers.Authorization = token ? `Bearer ${token}` : "";

  config.url = config.url.replace("portal=null", "portal=" + portal);
  if (config.url.includes("/upload/files")) {
    config.url = config.url.replace("url_contains=/null/", `url_contains=/${portal}/`);
    // if (config.method === "get") {
    //   config.url = config.url.concat("&_limit=200");
    // }
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("portal");
        SnackbarUtil.error("Your Token Has Expired. Login Again!");
        setTimeout(() => {
          const prefix = process.env.REACT_APP_PUBLIC_RELATIVE_PATH || "";
          window.location.replace(prefix + "/login");
        }, 1000);
      }
      reject(err);
    });
  }
);

export default instance;
