import { Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledImageEditModal = styled("div")(({ theme }) => ({}));

export const StyledSelectedTabHeader = styled(Tabs)(({ theme }) => ({
  ".css-1aggygj-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    fontWeight: 500,
    fontSize: "18px",
  },
  color: theme.palette.grey[500],
  fontSize: "18px",
  marginBottom: "20px",
  ".mui-tabs": {
    ".MuiTabs-fixed": {
      ".MuiTabs-indicator": {
        backgroundColor: theme.palette.primary.main,
      },
      ".MuiTabs-flexContainer": {
        ".Mui-selected": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

export const StyledImageEditModalBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const StyledImageEditContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
}));

export const StyledImageContainer = styled("div")(({ theme }) => ({
  height: "100px",
  width: "100px",
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "contain",
  },
}));

export const StyledImageDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
}));

export const StyledLabelInput = styled("div", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  width: "calc((100% / 3) - 24px)",
  ".edit-input": {
    width: "100%",
  },
  ".MuiFormLabel-root.Mui-focused": {
    color: exists === "true" ? `${theme.palette.error.main} !important` : `${theme.palette.primary.main} !important`,
  },
  ".Mui-focused": {
    fieldset: {
      borderColor:
        exists === "true" ? `${theme.palette.error.main} !important` : `${theme.palette.primary.main} !important`,
    },
  },
  ".MuiFormLabel-root": {
    color: exists === "true" ? `${theme.palette.error.main} !important` : `${theme.palette.grey[500]} !important`,
  },
  ".MuiFormControl-root": {
    ".MuiInputBase-root": {
      fieldset: {
        borderColor:
          exists === "true" ? `${theme.palette.error.main} !important` : `${theme.palette.grey[500]} !important`,
      },
    },
    ".Mui-focused": {
      fieldset: {
        borderColor:
          exists === "true" ? `${theme.palette.error.main} !important` : `${theme.palette.primary.main} !important`,
      },
    },
  },
  fieldset: {
    color: theme.palette.common.black,
  },
  ".MuiInputBase-input": {
    padding: "1rem",
  },
}));

export const StyledLabelTrashIconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  ".delete-icon": {
    color: theme.palette.error.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
  ".image-details-label": {
    color: theme.palette.common.black,
  },
  h4: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

export const StyledDetailsContentContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  ".label-value-container": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    ".details-content-label": {
      color: theme.palette.grey[500],
    },
    ".details-content-value": {
      color: theme.palette.common.black,
    },
  },
}));

export const StyledInputLabelContainerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const StyledInputLabelContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  h4: {
    color: theme.palette.common.black,
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

export const StyledErrorLabel = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  color: theme.palette.error.main,
}));

export const StyledImageRelatedPages = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  h4: {
    color: theme.palette.common.black,
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

export const StyledImageRelatedPagesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  ".single-image-related-pages-container": {
    a: {
      color: `${theme.palette.grey[500]}`,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
  },
  ".photo-related-paragraph": {
    color: theme.palette.common.black,
    marginBottom: "5px",
    fontSize: "16px",
    fontWeight: "600",
  },
}));
