import { TabPanel } from "@mui/lab";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "6px 0px",
  ".modal-input-wrapp": {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
    width: "100%",
    ".modal-textfield": {
      width: "calc(100% / 3)",
      ".MuiInputBase-root.MuiOutlinedInput-root": {
        height: 56,
      },
      input: {
        padding: 8,
      },
    },
    ".MuiInputBase-root.MuiOutlinedInput-root.select": {
      height: 56,
      ".MuiSelect-select": {
        paddingLeft: 18,
      },
      input: {
        padding: 8,
      },
    },
  },
}));

export const ButtonWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  gap: "0 1em",
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "relative",
  width: "100%",
  "&.select": {
    fieldset: {
      borderWidth: 1,
      legend: {
        width: "unset",
      },
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused": {
      fieldset: {
        borderColor: theme.palette.primary.main,
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    ".MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
}));

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiFormLabel-root": {
    backgroundColor: "#fff",
    padding: "0 6px",
    "&.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    color: theme.palette.text.primary,
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.common.white,
      width: "100%",
      color: theme.palette.text.primary,
      justifyContent: "center",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const DragDropWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
}));

export const DragDropSelect = styled("div")(({ theme }) => ({
  flex: "calc(100% / 4)",
  width: "calc(100% / 4)",
  ".dropzone": {
    margin: "12px 0 0",
  },
  ".drop-zone-img-wrapp": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1rem",
    ".img-wrapper": {
      width: "5rem",
      height: "5rem",
      borderRadius: 10,
      background: theme.palette.grey[200],
      padding: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&.drop-zone-wrapper": {
        width: 180,
        height: 140,
        border: `1px dashed ${theme.palette.primary.main}`,
        cursor: "pointer",
        flexDirection: "column",
        textAlign: "center",
        span: {
          color: theme.palette.grey.main,
          margin: 0,
          fontSize: 16,
          fontWeight: 400,
        },
        p: {
          color: theme.palette.primary.main,
          margin: 0,
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
    button: {
      "&[disabled]": {
        cursor: "not-allowed",
      },
    },
  },
}));

export const StyledOverrideTabBox = styled(TabPanel)(({ theme }) => ({
  padding: "16px 0 0",
  ".field-wrapper": {
    marginBottom: 16,
    h1: {
      fontSize: 18,
      fontWeight: 400,
      marginBottom: 16,
    },
    ".upload-library-wrapper": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: 24,
      ".drop-zone": {
        flex: "unset",
        width: "unset",
        ".dropzone": {
          margin: 0,
        },
      },
    },
  },
}));

export const StyledLogoLibrary = styled("div")(({ theme, loading}) => ({
  width: "100%",
  ".img-container-wrapp": {
    display: "grid",
    placeItems: "center",
    gridTemplateColumns: loading ? "repeat(1, 1fr)" : "repeat(5, 1fr)",
    gap: 16,
    margin: "0 0 16px",
    p: {
      whiteSpace: "nowrap",
    },
    ".album-img": {
      width: 140,
      height: 140,
      position: "relative",
      borderRadius: 10,
      background: theme.palette.grey[200],
      img: {
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: 10,
        height: "100%",
        objectFit: "contain",
        zIndex: 1,
      },
      ".checkbox-wrapp": {
        position: "absolute",
        zIndex: 2,
        top: 10,
        right: 10,
        width: 42,
        height: 42,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        background: theme.palette.common.white,
      },
    },
  },
}));

export const StyledIconWrapperBox = styled(Box)(({ theme }) => ({
  width: "100%",
  ".field-wrapper": {
    marginBottom: 16,
    h1: {
      fontSize: 18,
      fontWeight: 400,
      marginBottom: 16,
    },
    ".upload-library-wrapper": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: 24,
      ".drop-zone": {
        flex: "unset",
        width: "unset",
        ".dropzone": {
          margin: 0,
        },
      },
    },
  },
}));
