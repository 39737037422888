import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/material/styles";

export const StyledRelatedPhotosContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  ".refresh-header-container": {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    h2: {
      fontSize: "18px",
      fontWeight: "600",
      color: theme.palette.primary.main,
    },
  },
  ".photo-related-paragraph": {
    color: theme.palette.common.black,
  },
}));

export const StyledRefreshIcon = styled(RefreshIcon)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  width: "25px",
  height: "25px",
}));

export const StyledImageRelatedPagesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  ".single-image-related-pages-container": {
    a: {
      color: `${theme.palette.grey[500]}`,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
  },
  ".photo-related-paragraph": {
    color: theme.palette.common.black,
    marginBottom: "5px",
    fontSize: "16px",
    fontWeight: "600",
  },
}));

export const StyledOverallContainer = styled("div")(({ theme }) => ({
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
