export const validate = (element, formdata = []) => {
  let error = [true, ""];

  if (element.validation && element.validation.email) {
    const valid = /\S+@\S+\.\S+/.test(element.value);
    const message = `${!valid ? "Email is not valid!" : ""}`;
    error = !valid ? [valid, message] : error;
  }

  if (element.validation && element.validation.file) {
    const valid = /^.*\.(jpg|jpeg|png|gif)$/.test(element.value);
    const message = `${!valid ? "Invalid custom file feedback!" : ""}`;
    error = !valid ? [valid, message] : error;
  }

  if (element.validation && element.validation.confirmPass) {
    const find = Object.values(formdata).find((el) => el.validation.confirm && el.validation.confirm);
    const valid = element.value.trim() === find.value;
    const message = `${!valid ? "Passwords do not match!" : ""}`;
    error = !valid ? [valid, message] : error;
  }

  if (element.validation && element.validation.required) {
    const valid = element?.value?.trim() !== "";
    const message = `${!valid ? "This field is required!" : ""}`;
    error = !valid ? [valid, message] : error;
  }

  return error;
};

export const update = (element, formdata, formName) => {
  const newFormdata = {
    ...formdata,
  };
  const newElement = {
    ...newFormdata[element.id],
  };

  newElement.value = element.event?.target?.value;

  if (element.blur) {
    const validData = validate(newElement, formdata);
    newElement.valid = validData[0];
    newElement.validationMessage = validData[1];
  }

  newElement.touched = element.blur;
  newFormdata[element.id] = newElement;

  return newFormdata;
};

export const generateData = (formdata, formName, folder, imgExt) => {
  const dataToSubmit = {};

  for (const key in formdata) {
    dataToSubmit[key] = formdata[key].value;
    if (formName === "imageUpdate") {
      if (key === "name") {
        dataToSubmit[key] = folder + formdata[key].value + imgExt;
      }
    }
    if (formdata[key].value === null || formdata[key].value === "") {
      delete dataToSubmit[key];
    }
  }

  return dataToSubmit;
};

export const isFormValid = (formdata, formName) => {
  let formIsValid = true;

  for (const key in formdata) {
    formIsValid = formdata[key].valid && formIsValid;
  }

  return formIsValid;
};

export const resetFields = (formdata, formName) => {
  const newFormdata = { ...formdata };

  for (const key in newFormdata) {
    newFormdata[key].value = "";
    newFormdata[key].valid = false;
    newFormdata[key].touched = false;
    newFormdata[key].validationMessage = "";
  }

  return newFormdata;
};

export const populateFields = (formdata, fields, subObject) => {
  if (subObject) {
    return {
      ...formdata,
      [subObject]: populateFields(formdata[subObject], fields),
    };
  }

  const newFormdata = { ...formdata };

  for (const key in newFormdata) {
    newFormdata[key].value = fields[key];
    if (newFormdata[key].options) {
      newFormdata[key].options = fields[key];
      delete newFormdata[key].value;
    }
    newFormdata[key].valid = true;
    newFormdata[key].touched = true;
    newFormdata[key].validationMessage = "";
  }

  return newFormdata;
};
