import React, { useEffect, useState } from "react";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "../../../custom_hooks/useWindowSize";
import breakpoints from "../../../hoc/theme/breakpoints";
import { handleThemeSwitch } from "../../../Redux/slices/layoutSlice";
import { openModal } from "../../../Redux/slices/modalSlice";
import { logoutUser, refreshUser } from "../../../Redux/slices/userSlice";
import CustomButton from "../../CustomButton";
import { StyledSettings } from "../StyledSidebar";

export default function SidebarSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const collapseMenu = useSelector((state) => state.layout.collapseMenu);

  const user = useSelector((state) => state.user.user);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    !user && dispatch(refreshUser());
  }, []);

  const role = user && user.role && user.role.name.charAt(0).toUpperCase() + user.role.name.slice(1);
  const portal = user && user.portal.charAt(0).toUpperCase() + user.portal.slice(1);
  const username = user?.username.split("@")[0];
  const tabletSize = breakpoints?.values.tablet;
  const themeNames = ["cherry", "blueberry", "kiwi", "pineapple"];

  const signOut = () => {
    dispatch(logoutUser());
    navigate("/", { replace: true });
  };

  const handleTheme = (key) => {
    dispatch(handleThemeSwitch(key));
  };
  const createThemeBoxes = () => {
    return themeNames.map((el, idx) => {
      return <div className={`theme-box ${el}`} key={idx} onClick={() => handleTheme(el)}></div>;
    });
  };

  const openResetModal = () => {
    dispatch(openModal({ modal: "reset_pass_modal", cb: null, pageType: null }));
  };

  return (
    <StyledSettings open={open} collapsemenu={collapseMenu.toString()}>
      <div className="sidebar-settings" onClick={() => setOpen(!open)}>
        <AccountCircleOutlinedIcon className="user-settings" />
        <span className="username">{username}</span>
        {width && width > tabletSize && collapseMenu ? null : <ArrowBackIosNewOutlinedIcon className="arrow" />}
      </div>
      <div className={"settings-options"}>
        <div className="role-portal">
          <span className="span">
            Role: <span>{role}</span>
          </span>
          <span className="span">
            Portal: <span>{portal}</span>
          </span>
        </div>
        <div className="theme-switch">
          <span>Themes</span>
          <div className="themes">{createThemeBoxes()}</div>
        </div>
        <div className="settings-btn">
          <CustomButton styleType="light" variant="primary" iconPosition="right" onClick={openResetModal}>
            Reset password
          </CustomButton>
          <CustomButton styleType="outlined" variant="primary" iconPosition="right" onClick={signOut}>
            Log out
          </CustomButton>
        </div>
      </div>
    </StyledSettings>
  );
}
