import { useSnackbar } from "notistack";

let useSnackRef;
export const SnackbarUtilConfig = () => {
  useSnackRef = useSnackbar();
  return null;
};

const toast = (msg, variant = "default") => {
  return useSnackRef.enqueueSnackbar(msg, { variant, autoHideDuration: 1500 });
};

export default {
  success(msg) {
    toast(msg, "success");
  },
  warning(msg) {
    toast(msg, "warning");
  },
  info(msg) {
    toast(msg, "info");
  },
  error(msg) {
    toast(msg, "error");
  },
};
