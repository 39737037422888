import React from "react";

import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const publicRoutes = ["/administration/login", "/administration/forgot-password", "/administration/reset-password"];

export default function ProtectedRoute({ children }) {
  const user = useSelector((state) => state.user.user);
  const isLogged = localStorage.getItem("jwt") || user;
  const location = useLocation();

  if (!isLogged && !publicRoutes.some((r) => r === location.pathname)) {
    return <Navigate to="/administration/login" replace />;
  }

  return children;
}
