import React from "react";

import { TextField } from "@mui/material";

import { handleKeyTransform } from "../../../Utils/TemplateHelpers/modalHelpers";
import {
  StyledErrorLabel,
  StyledInputLabelContainer,
  StyledInputLabelContainerWrapper,
  StyledLabelInput,
} from "../styledImageEdit";

export default function Edit({ handleBlur, handleChange, formdata }) {
  const showError = (formdata) => {
    let errorMessage = null;

    if (formdata.validation && !formdata.valid) {
      errorMessage = <StyledErrorLabel>{formdata.validationMessage}</StyledErrorLabel>;
    }

    return errorMessage;
  };

  return (
    <StyledInputLabelContainer>
      <h4>Image Content</h4>
      <div className="styled-form">
        <StyledInputLabelContainerWrapper>
          {Object.entries(formdata).map(([key, val], idx) => {
            return (
              <StyledLabelInput key={idx} exists={!val.valid.toString()}>
                <TextField
                  className="edit-input"
                  id="img_name"
                  label={`Image ${handleKeyTransform(key)}`}
                  {...val.config}
                  value={val.value}
                  onBlur={(event) => handleBlur({ event, id: key, blur: true })}
                  onChange={(event) => handleChange({ event, id: key })}
                />
                {showError(val)}
              </StyledLabelInput>
            );
          })}
        </StyledInputLabelContainerWrapper>
      </div>
    </StyledInputLabelContainer>
  );
}
