import React, { useState } from "react";

import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { resetPassword } from "../../../Redux/slices/userSlice";
import DialogContainer from "../../Dialog/Index";
import { generateData, isFormValid } from "../../Utils/Form/formActions";
import FormGroupData from "./components/FormGroupData";
import { formdataInitialState, submitForm } from "./helpers";
import { FormWrap, StyledFormGroupContainer } from "./styledResetPassword";

export default function ResetPassword({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const userState = useSelector((state) => state.user);

  const [formError, setFormError] = useState(false);
  const [formdata, setFormdata] = useState(formdataInitialState);

  const submitForm = (event) => {
    event.preventDefault();

    let dataToSubmit = generateData(formdata, "reset-password");
    const formIsVaid = isFormValid(formdata, "reset-password");

    dataToSubmit = {
      data: { password: dataToSubmit.confirmPassword },
      userID: userState.user.id,
    };

    if (formIsVaid) {
      dispatch(resetPassword(dataToSubmit))
        .then((res) => {
          onClose();
          enqueueSnackbar("You have successfully updated password!", { variant: "success" });
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar("There was an error with the password update, try again!", { variant: "error" });
        });
    }
  };

  return (
    <DialogContainer
      isOpen={isOpen}
      label="Reset Password"
      actionLabel="Submit"
      onAction={(event) => submitForm(event)}
      onClose={onClose}
      width="40%"
      height="55%"
    >
      <FormWrap onSubmit={(event) => submitForm(event)}>
        <StyledFormGroupContainer>
          {Object.entries(formdata).map(([key, value], idx) => {
            return (
              <FormGroupData
                key={idx}
                formdataKey={key}
                formdataValue={value}
                formdata={formdata}
                setFormdata={setFormdata}
                setFormError={setFormError}
              />
            );
          })}
        </StyledFormGroupContainer>
      </FormWrap>
    </DialogContainer>
  );
}
