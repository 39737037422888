import { styled } from "@mui/system";

export const StyledLoadingScreen = styled("div")(({ theme }) => ({
  position: "fixed",
  overflow: "hidden",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "#F5F9FF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999999999,
}));

export const StyledLoader = styled("div")(({ theme }) => ({
  position: "relative",
  width: 44,
  height: 44,
  display: "inline-block",
  margin: "0 16px",
  "&::before": {
    position: "absolute",
    content: '""',
    width: 6,
    height: 6,
    borderRadius: "50%",
    display: "block",
    background: theme.palette.primary.dark,
    top: 37,
    left: 19,
    transform: "translate(-18px, -18px)",
    animation: "dotRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite",
    "@keyframes dotRect": {
      "25%": {
        transform: "translate(0, 0)",
      },
      "50%": {
        transform: "translate(18px, -18px)",
      },
      "75%": {
        transform: "translate(0, -36px)",
      },
      "100%": {
        transform: "translate(-18px, -18px)",
      },
    },
  },
  svg: {
    display: "block",
    width: "100%",
    height: "100%",
    "rect, polygon, circle": {
      fill: "none",
      stroke: theme.palette.primary.darker,
      strokeWidth: 10,
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    polygon: {
      strokeDasharray: "145 76 145 76",
      strokeDashoffset: 0,
      animation: "pathTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite",
      "@keyframes pathTriangle": {
        "33%": {
          strokeDashoffset: 74,
        },
        "66%": {
          strokeDashoffset: 147,
        },
        "100%": {
          strokeDashoffset: 221,
        },
      },
    },
    rect: {
      strokeDasharray: "192 64 192 64",
      strokeDashoffset: 0,
      animation: "pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite",
      "@keyframes pathRect": {
        "25%": {
          strokeDashoffset: 64,
        },
        "50%": {
          strokeDashoffset: 128,
        },
        "75%": {
          strokeDashoffset: 192,
        },
        "100%": {
          strokeDashoffset: 256,
        },
      },
    },
    circle: {
      strokeDasharray: "150 50 150 50",
      strokeDashoffset: 0,
      animation: "pathCircle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite",
      "@keyframes pathCircle": {
        "25%": {
          strokeDashoffset: 125,
        },
        "50%": {
          strokeDashoffset: 175,
        },
        "75%": {
          strokeDashoffset: 225,
        },
        "100%": {
          strokeDashoffset: 275,
        },
      },
    },
  },
  "&.triangle": {
    width: 48,
    "&::before": {
      left: 21,
      transform: "translate(-10px, -18px)",
      animation: "dotTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite",
      "@keyframes dotTriangle": {
        "33%": {
          transform: "translate(0, 0)",
        },
        "66%": {
          transform: "translate(10px, -18px)",
        },
        "100%": {
          transform: "translate(-10px, -18px)",
        },
      },
    },
  },
}));
