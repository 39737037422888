const breakpoints = {
  values: {
    phone: 576,
    tabletSmall: 720,
    tablet: 1024,
    laptop: 1600,
    laptopSmall: 1366,
    desktop: 2000, // -- by default
    desktopMedium: 4096, // -- 4k
    desktopLarge: 7690, // -- 8k
  },
};
export default breakpoints;
