import React from "react";

import { Chip, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import useFontsDropzone from "../../../../custom_hooks/useFontsDropzone";
import { StyledDragZoneContainer } from "../StyledFontsUpload";

export default function Upload({ type }) {
  const formPageType = useSelector((state) => state?.layout?.formPageType);
  const isLoading = useSelector((state) => state?.settings?.loading);
  const pagePath = formPageType?.page?.split(".");

  const { getRootProps, getInputProps, uploadedFiles, isUploaded } = useFontsDropzone(pagePath[0], type);

  const renderUploadedContainer = () => {
    return (
      <>
        <p className="drag-paragraph">Uploaded files:</p>
        <div className="uploaded-files-container">
          {uploadedFiles?.map((el, idx) => (
            <Chip key={idx} label={el.name} color="primary" sx={{ mr: "10px", mb: "10px" }} />
          ))}
        </div>
      </>
    );
  };

  const renderNonUploadedContainer = () => {
    return (
      <>
        <p className="drag-paragraph">Drop your files here, allowed file types:</p>
        <p className="drag-paragraph">
          {[".ttf", ".otf", ".woff", ".woff2"]?.map((el, idx) => (
            <Chip key={idx} label={el} color="primary" sx={{ mr: "10px" }} />
          ))}
        </p>
        <p className="drag-paragraph">or</p>
        <div className="input-container">
          <p className="input-paragraph">Select Files</p>
          <input {...getInputProps()} />
        </div>
      </>
    );
  };

  return (
    <StyledDragZoneContainer>
      <div className="drag-input-container" {...getRootProps()}>
        {isLoading ? <CircularProgress /> : isUploaded ? renderUploadedContainer() : renderNonUploadedContainer()}
      </div>
    </StyledDragZoneContainer>
  );
}
