import dayjs from "dayjs";

export const formateDate = (date) => date && dayjs(date).format("DD MMMM YYYY");

export const transformText = (text) => {
  const htmlCode = text;
  const tempEl = document.createElement("div");
  tempEl.innerHTML = htmlCode;
  const textContent = tempEl.textContent.trim();
  return textContent;
};
