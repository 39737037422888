import { useState } from "react";

import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { addNewImage } from "../Redux/slices/mediaSlice";
import { uploadCustomFont } from "../Redux/slices/settingsSlice";

const useFontsDropzone = (uploadPath, fontType) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isUploaded, setIsUploaded] = useState(false);
  const [activeDropzone, setActiveDropzone] = useState("");
  const portal = useSelector((state) => state?.settings.portalSettings?.portal) || localStorage.getItem("portal");
  const previewData = useSelector((state) => state.modal?.modal?.previewData);

  const imageMaxSize = 2097152;
  const acceptedFileTypes = "font/woff, font/ttf, font/otf, font/woff2";
  const fallbackAcceptedFileTypes = ["woff", "ttf", "otf", "woff2"];
  const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
    return item.trim();
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const verifyFiles = (file) => {
    const { type: currentFileType, size: currentFileSize, name, path } = file;
    const isAllowedFormat = fallbackAcceptedFileTypes.some(
      (item) => name.toLowerCase().endsWith(item) || path.toLowerCase().endsWith(item)
    );
    if (currentFileSize > imageMaxSize) {
      enqueueSnackbar("File is larger than 2MB, choose smaller file size!", { variant: "error" });
      return false;
    }
    if (!isAllowedFormat || (Boolean(currentFileType) && !acceptedFileTypesArray.includes(currentFileType))) {
      enqueueSnackbar(`Not supported format for file: ${name}`, { variant: "error" });
      return false;
    }
    return true;
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 100,
    multiple: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const data = new FormData();
        acceptedFiles.forEach((file) => {
          const isVerified = verifyFiles(file);
          if (isVerified) {
            setUploadedFiles((prevState) => [...prevState, file]);
            data.append("files", file);
          }
        });
        data.append("type", fontType);
        data.append("portal", portal);
        dispatch(
          uploadCustomFont({
            font: data,
            type: fontType,
            isStyleV2: previewData?.isStyleV2,
            activePalette: previewData?.activeSlide,
          })
        ).then(() => {
          setIsUploaded(true);
        });
      }
    },
  });

  return { getRootProps, getInputProps, setActiveDropzone, uploadedFiles, isUploaded, activeDropzone };
};

export default useFontsDropzone;
