import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { useWindowSize } from "../custom_hooks/useWindowSize";
import { toggleSidebar } from "../Redux/slices/layoutSlice";
import AuthLayout from "./components/AuthLayout";

export default function Layout() {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const collapseMenu = useSelector((state) => state.layout.collapseMenu);
  const user = useSelector((state) => state.user.user);
  const isLogged = localStorage.getItem("jwt") || user;

  const mobileOutClickHandler = () => {
    if (width < 992 && collapseMenu) dispatch(toggleSidebar());
  };

  const authLayoutHandler = () => {
    if (isLogged)
      return <AuthLayout mobileOutClickHandler={mobileOutClickHandler} width={width} collapseMenu={collapseMenu} />;
    return <Outlet />;
  };

  return <>{authLayoutHandler()}</>;
}
