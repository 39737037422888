import React, { useEffect, useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getPortalSettings } from "../../../Redux/slices/settingsSlice";
import DialogContainer from "../../Dialog/Index";
import GeneralInfo from "./components/GeneralInfo";
import Multilingual from "./components/Multilingual";
import { StyledTab } from "./styledPreviewModal";

export default function PreviewModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const portalSettings = useSelector((state) => state.settings?.portalSettings);
  const modalState = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const tabsArray = [
    {
      title: "General",
      component: <GeneralInfo />,
    },
    {
      title: "multilingual",
      component: <Multilingual />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabsArray[0].title.toLowerCase());

  const handleEditAction = () => {
    onClose();
    navigate(
      `/administration/${modalState.modal.pageFrom}/edit/${
        modalState.previewData.type === "revisions" ? modalState.previewData.data[0].postID : modalState.previewData.id
      }`,
      { replace: true }
    );
  };

  useEffect(() => {
    !portalSettings && dispatch(getPortalSettings());
  }, []);

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  return (
    <DialogContainer
      label="Preview"
      isOpen={isOpen}
      onClose={onClose}
      actionLabel="Edit"
      onAction={handleEditAction}
      width="800px"
    >
      <Stack spacing={4}>
        <TabContext value={activeTab}>
          <Box>
            <TabList onChange={handleTabChange} aria-label="preview modal tabs">
              {tabsArray.map(({ title }, idx) => {
                return <StyledTab key={idx} value={title?.toLowerCase()} label={title} />;
              })}
            </TabList>
          </Box>
          {tabsArray.map(({ title, component }, idx) => {
            return (
              <TabPanel key={idx} value={title?.toLowerCase()} sx={{ px: 0, pt: 0 }}>
                {component}
              </TabPanel>
            );
          })}
        </TabContext>
      </Stack>
    </DialogContainer>
  );
}
