import React, { useState } from "react";

import { CircularProgress } from "@material-ui/core";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import { StyledCollapseButton, StyledCustomButton } from "./StyledCustomButton";

export default function CustomButton({ children, isLoading, disabled, ...otherProps }) {
  // props that accepts, can add more if needed

  // type
  // based on type the appearance of button is going to be different, there are 3 types, oulined, light and filled
  // outlined - without background color just border, the color of border and text will depend of the variant property
  // filled - just background color that also depends on variant property , color of the text is going to be white regardless of the chosen variant
  // light - background color is going to be light color from chosen variant and text is going to be main color of chosen variant

  // variant
  // variant is going to define the button text,background and border color, variants are color names from palette(primary, success, info,...)

  // icon position
  // if we have icon we can chosee on which side we want it to appear

  // Example how looks imported button
  // <CustomButton styleType="filled" variant="primary" iconPosition="right" onClick={(event) => submitForm(event)}>
  //   {icon if needed}
  //   {button title}
  // </CustomButton>;

  // Also you can make button as dropdown
  // You have to make and object like in example below in component where you want to use button and pass it as a prop to button component
  // const menuButton=[
  //   {
  //     slug :"Publish",
  //     cb: () => console.log("Publish")
  //   },
  //   {
  //     slug :"Save as Draft",
  //     cb: () => console.log("Save as Draft")
  //   }
  // ];
  // <CustomButton styleType="filled" variant="primary" iconPosition="right" items={menuButton}>Submit</CustomButton>
  const [isOpen, setIsOpen] = useState(false);
  const renderList = () => {
    return otherProps?.items?.map((el, idx) => {
      return (
        <li key={idx} className={el.slug} onClick={el.cb}>
          {el.slug}
        </li>
      );
    });
  };
  return (
    <>
      {otherProps.items ? (
        <StyledCollapseButton {...otherProps} onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} isLoading={isLoading}>
          <div className="accordion-title">
            <div>{children}</div>
            {isLoading ? (
              <CircularProgress className="btn-progress" size={25} />
            ) : (
              <div className={`toggle ${!isOpen ? "active" : ""}`}>
                <ArrowBackIosNewOutlinedIcon />
              </div>
            )}
          </div>
          <div className={`accordion-content ${!isOpen ? "active" : ""}`}>
            <div className="acc-wrapp">{renderList()}</div>
          </div>
        </StyledCollapseButton>
      ) : (
        <StyledCustomButton
          {...otherProps}
          {...(isLoading && { disabled: true })}
          isOpen={isOpen}
          isLoading={isLoading}
          disabled={disabled}
        >
          {isLoading ? (
            <>
              <CircularProgress className="btn-progress" size={25} />
              {children}
            </>
          ) : (
            <>{children}</>
          )}
        </StyledCustomButton>
      )}
    </>
  );
}
