import axios from "axios";

import instance from "../api_config";

const getAllPhotos = () => {
  return instance.get("/upload/files");
};

const getAllAlbumPhotos = (activeGallery) => {
  const portal = localStorage.getItem("portal");
  let getAlbumURL = `/upload/files?url_contains=/${portal}/${activeGallery}`;
  if (activeGallery === "game_providers" || activeGallery === "game_tags") {
    getAlbumURL = `/upload/files?url_contains=/${portal}/${activeGallery}&url_contains=/${portal}/${activeGallery.replace(
      /_/g,
      "-"
    )}`;
  }
  return instance.get(getAlbumURL);
};

const postNewImage = (image) => {
  return instance.post("/upload", image);
};

const editPhotoByID = (id) => {
  return instance.get(`/upload/files/${id}`);
};

const relatedPhotoByID = (id) => {
  return instance.get(`/services/imagesExtended/${id}`);
};

const addNewImage = (image) => {
  const jwt = localStorage.getItem("jwt");
  return axios({
    method: "post",
    baseURL: process.env.REACT_APP_API_URL,
    url: "/services/uploadToFolder",
    data: image,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const updatePhotoByID = (image, id) => {
  const jwt = localStorage.getItem("jwt");
  return axios({
    method: "post",
    baseURL: process.env.REACT_APP_API_URL,
    url: `/upload?id=${id}`,
    data: image,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const deletePhotoByID = (id) => {
  return instance.delete(`/upload/files/${id}`);
};

const mediaService = {
  getAllPhotos,
  postNewImage,
  getAllAlbumPhotos,
  relatedPhotoByID,
  editPhotoByID,
  addNewImage,
  updatePhotoByID,
  deletePhotoByID,
};
export default mediaService;
