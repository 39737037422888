import path from "path";

import React, { useEffect, useState, useCallback } from "react";

import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { Checkbox, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import useMediumPagination from "../../../../custom_hooks/useMediumPagination";
import { useWindowSize } from "../../../../custom_hooks/useWindowSize";
import MediumPagination from "../../../Pagination/MediumPagination";
import {
  StyledCheckboxContainer,
  StyledGridPaginationContainer,
  StyledImage,
  StyledImageGridContainer,
  StyledJsonWrap,
  StyledLibraryContainer,
  StyledLibraryGridContent,
  StyledLibraryHeader,
  StyledSearchContainer,
  StyledSearchIcon,
  StyledTextField,
  StyledVideo,
} from "../StyledImageUpload";

export default function Library({ imageToSubmit, setImageToSubmit, isChatOracle }) {
  const album = useSelector((state) => state.media.album);
  const loading = useSelector((state) => state.media.loading);
  const imgFormElement = useSelector((state) => state.media.element);
  const searchBy = ["name"];
  const [focused, setFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { width } = useWindowSize();
  const itemsPerPage = width >= 1870 ? 18 : 12;

  const {
    curentPage,
    setCurentPage,
    sliceArgFirst,
    sliceArgSecond,
    handleGoToPageOnEnter,
    handleKeyDown,
    goToPageInput,
  } = useMediumPagination(itemsPerPage, Math.round(album.length / itemsPerPage));

  let dataToShow;
  if (searchValue && !imgFormElement?.hasVideo) {
    dataToShow = album
      ?.filter((el) => el.ext !== ".mp4" && el.ext !== ".webm" && el.ext !== ".json")
      .filter((photo) => {
        let newPhoto = {};
        for (const key of searchBy) {
          newPhoto = photo[key].toLowerCase().includes(searchValue && searchValue.toLowerCase());
        }
        return newPhoto;
      });
  } else dataToShow = album;

  const handleCheck = (imageToSubmit, event, setImageToSubmit, url) => {
    const ifImgExist = imageToSubmit.imageId === parseInt(event.target.value);
    setImageToSubmit({
      imageId: ifImgExist ? null : parseInt(event.target.value),
      imageUrl: ifImgExist ? "" : url,
      imageCheck: !ifImgExist,
    });
  };

  const renderGridContent = () => {
    return dataToShow?.slice(sliceArgFirst, sliceArgSecond).map((val, idx) => {
      const fileName = path.basename(val?.name);
      return (
        <div key={idx} title={fileName}>
          <StyledLibraryGridContent>
            <StyledCheckboxContainer>
              <div className="styled-checkbox-outline">
                <Checkbox
                  id={val.id}
                  value={val.id}
                  onChange={(event) => handleCheck(imageToSubmit, event, setImageToSubmit, val?.url)}
                  checked={imageToSubmit.imageId === parseInt(val.id)}
                />
              </div>
            </StyledCheckboxContainer>
            <div>
              {[".json"].includes(val?.ext) ? (
                <StyledJsonWrap>
                  <FilePresentOutlinedIcon />
                  <div className="file-name">
                    <p>{fileName?.length <= 12 ? fileName : fileName?.substring(0, 12) + "..."}</p>
                  </div>
                </StyledJsonWrap>
              ) : [".webm", ".mp4"].includes(val?.ext) ? (
                <StyledVideo autoPlay muted>
                  <source src={val?.url} type={`video/${val?.ext?.split(".").join("")}`} />
                </StyledVideo>
              ) : (
                <div className="image-wrapp">
                  <StyledImage src={val.url} alt="" />
                  <div className="img-name">
                    <p>{fileName?.length <= 12 ? fileName : fileName?.substring(0, 12) + "..."}</p>
                  </div>
                </div>
              )}
            </div>
          </StyledLibraryGridContent>
        </div>
      );
    });
  };

  const eventCallback = useCallback(() => {
    function handleSelectedImageCheck(e) {
      const imageUrl = e.detail.url;
      const newImageUrl = imageUrl.replace(/"|'/g, "");
      const target = { target: { value: e.detail.id } };
      return handleCheck(imageToSubmit, target, setImageToSubmit, newImageUrl);
    }

    window.addEventListener("file_uploaded", (e) => {
      handleSelectedImageCheck(e);
    });
    return () => window.removeEventListener("file_uploaded", handleSelectedImageCheck);
  }, [album?.length, imageToSubmit]);

  useEffect(() => {
    eventCallback();
  }, [eventCallback]);

  return (
    <StyledLibraryContainer>
      <StyledLibraryHeader>{isChatOracle ? "Choose" : "Choose Image"}</StyledLibraryHeader>
      {!isChatOracle && (
        <StyledSearchContainer>
          <StyledSearchIcon className="search-icon" exists={focused.toString()} ishovered={isHovered.toString()} />
          <StyledTextField
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            id="search"
            label="Search Image"
            type="search"
            variant="standard"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            autoComplete="off"
          />
        </StyledSearchContainer>
      )}
      {loading ? (
        <CircularProgress style={{ margin: "0 auto" }} />
      ) : (
        <StyledGridPaginationContainer>
          <StyledImageGridContainer>
            {dataToShow?.length ? (
              renderGridContent()
            ) : (
              <p>There are no uploaded {isChatOracle ? "items." : "images."} </p>
            )}
          </StyledImageGridContainer>
          {Boolean(dataToShow?.length) && (
            <MediumPagination
              data={album}
              inputValue={goToPageInput.inputValue}
              isInputValid={goToPageInput.isValid}
              itemsPerPage={itemsPerPage}
              curentPage={curentPage}
              setCurentPage={setCurentPage}
              handleGoToPageOnEnter={handleGoToPageOnEnter}
              handleKeyDown={handleKeyDown}
            />
          )}
        </StyledGridPaginationContainer>
      )}
    </StyledLibraryContainer>
  );
}
