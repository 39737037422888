import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import formSlice from "./slices/form/slice";
import layoutSlice from "./slices/layoutSlice";
import mediaSlice from "./slices/mediaSlice";
import modalSlice from "./slices/modalSlice";
import revisionsSlice from "./slices/revisionsSlice";
import settingsSlice from "./slices/settingsSlice";
import translationSlice from "./slices/translationSlice";
import userSlice from "./slices/userSlice";

export default configureStore({
  reducer: {
    user: userSlice,
    layout: layoutSlice,
    settings: settingsSlice,
    modal: modalSlice,
    form: formSlice,
    revisions: revisionsSlice,
    media: mediaSlice,
    translations: translationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});