import React from "react";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../../assets/images/logo-small.png";
import { toggleSidebar } from "../../../Redux/slices/layoutSlice";
import { StyledSidebarHeader } from "../StyledSidebar";
import SidebarSettings from "./SidebarSettings";

export default function SidebarHeader({ width }) {
  const dispatch = useDispatch();
  const collapseMenu = useSelector((state) => state.layout.collapseMenu);
  const portalSettings = useSelector((state) => state.settings.portalSettings);

  const screenSize = width < 1024 || width > 1024 ? collapseMenu : !collapseMenu;

  const handleSidebarToggle = () => dispatch(toggleSidebar());

  return (
    <StyledSidebarHeader
      collapsemenu={collapseMenu.toString()}
      className={collapseMenu ? "collapse-sidebar-header" : ""}
    >
      <div className="header-info">
        <a href="#!" className="header-content">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          {/* <span className="header-title">iGP CMS</span> */}
        </a>
        <a
          href="#!"
          className={`sidebar-mobile ${screenSize ? (collapseMenu ? "on" : "") : ""}`}
          id="mobile-collapse"
          onClick={handleSidebarToggle}
        >
          <span className="material-symbols-outlined">
            <KeyboardDoubleArrowRightIcon />
          </span>
        </a>
      </div>

      <div className="sidebar-portal">
        <img src={portalSettings?.general?.logo?.url} alt=""></img>
      </div>
      <SidebarSettings />
    </StyledSidebarHeader>
  );
}
