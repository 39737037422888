import React from "react";

import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@mui/icons-material";

const SearchField = ({ handleChange }) => {
  return (
    <FormControl sx={{ width: "100%" }} className="search-field">
      <TextField
        style={{ minWidth: "30%" }}
        id="outlined-search"
        placeholder="Search"
        type="search"
        variant="standard"
        onChange={(e) => handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default SearchField;
