import { update } from "../../Utils/Form/formActions";

export const formdataInitialState = {
  newPassword: {
    element: "input",
    value: "",
    showPassword: false,
    config: {
      name: "new_password_input",
      // type: 'password',
      label: "New Password",
      placeholder: "Enter New Password",
    },
    validation: {
      required: true,
      confirm: true,
    },
    valid: true,
    touched: true,
    validationMessage: "",
  },
  confirmPassword: {
    element: "input",
    value: "",
    showPassword: false,
    config: {
      name: "confirm_password_input",
      // type: 'password',
      label: "Confirm Password",
      placeholder: "Enter Confirm Password",
    },
    validation: {
      required: true,
      confirmPass: true,
    },
    valid: true,
    touched: true,
    validationMessage: "",
  },
};

export const handleShowPassword = (pass, formdata, setFormdata) => {
  setFormdata({
    ...formdata,
    [pass]: {
      ...formdata[pass],
      showPassword: !formdata[pass].showPassword,
    },
  });
};

export const updateForm = (element, formdata, setFormdata, setFormError) => {
  const newFormdata = update(element, formdata, "reset-password");
  setFormError(false);
  setFormdata(newFormdata);
};
