import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SidebarCollapse from "./SidebarCollapse";
import SidebarItem from "./SidebarItem";
import navigation from "../../../menu-items";
import { StyledSidebarContent } from "../StyledSidebar";

export default function SidebarContent() {
  const collapseMenu = useSelector((state) => state.layout.collapseMenu);
  const location = useLocation();
  const userRole = useSelector((state) => state.user.user?.role?.name);

  const [isActive, setIsActive] = useState(
    navigation.items[0].children?.find((el) => location?.pathname?.includes(el.id))?.id
  );
  const [position, setPosition] = useState();
  const itemsLength = navigation.items[0].children.length;

  const toggle = (key) => {
    const findItemPos = navigation.items[0].children.find((el) => el.id === key);
    setIsActive(key === isActive ? null : key);
    if (findItemPos.idx > 14) {
      const elPositionFromBottom = itemsLength - (findItemPos.idx === 17 ? 15 : findItemPos.idx);
      const bottomVal = elPositionFromBottom * 50;
      setPosition(bottomVal);
    }
    if (isActive) {
      setPosition(0);
    }
  };

  const sidebarItems = navigation.items[0].children.map((item, idx) => {
    item.idx = idx;
    if (item?.disableForRoles?.includes(userRole)) return null;

    if (item.children) {
      return (
        <SidebarCollapse
          key={item.id}
          item={item}
          isActive={isActive}
          setIsActive={setIsActive}
          toggle={(el) => toggle(el)}
          collapseMenu={collapseMenu}
        />
      );
    } else {
      return <SidebarItem key={item.id} item={item} collapseMenu={collapseMenu} setIsActive={setIsActive} />;
    }
  });
  return (
    <StyledSidebarContent
      collapsemenu={collapseMenu.toString()}
      drpOpened={isActive}
      className={collapseMenu ? "collapse-sidebar-content" : ""}
      position={position}
    >
      {sidebarItems}
    </StyledSidebarContent>
  );
}
