export const globalStyles = (theme) => {
  return {
    html: {
      lineHeight: "normal",
    },
    "*": {
      "&:focus": {
        outline: "none",
      },
    },
    body: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: "14px",
      color: theme?.palette?.grey?.main,
      fontWeight: "400",
      position: "relative",
      overflowX: "hidden",
      ...{ ...theme.palette.portalPalette },
      "& > [aria-hidden='true'] header": {
        paddingRight: "32px",
      },
      "& > [aria-hidden='true'] .floating-button": {
        marginRight: "8px",
      },
    },
    a: {
      "&:hover": {
        outline: "none",
        textDecoration: "none",
      },
    },
    "::-webkit-scrollbar": {
      width: "8px",
      borderRadius: "10px",
    },
    "::-webkit-scrollbar-track": {
      background: theme?.grey?.["200"],
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb": {
      background: theme?.palette?.primary?.main,
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: theme?.palette?.primary?.dark,
      borderRadius: "12px",
    },
    ".tox .tox-collection--list .tox-collection__group .tox-collection__item--active:not(.tox-collection__item--state-disabled)":
      {
        background: `${theme?.palette?.primary?.light} !important`,
        color: `${theme?.palette?.primary?.main} !important`,
      },
    ".tox .tox-collection__item-label": {
      padding: "10px 0",
    },
    ".tox .tox-menu.tox-collection.tox-collection--list": {
      borderRadius: 10,
    },
    ".tox.tox-fullscreen": {
      zIndex: "999999999 !important",
    },
    ".tox.tox-silver-sink.tox-tinymce-aux": {
      zIndex: "999999999 !important",
      overflow: "unset !important",
    },
    ".tox.tox-tinymce": {
      overflow: "unset !important",
    },
    ".tox.tox-tinymce:fullscreen .tox.tox-silver-sink.tox-tinymce-aux div[role='listbox']": {
      position: "fixed",
    },
    "h1, h2, h3, h4, h5, h6": {
      color: theme?.grey?.main,
      fontWeight: 400,
    },
    ".text-danger": {
      color: theme.palette.error.main,
    },
    ".MuiPopover-root.MuiMenu-root.MuiModal-root[role='presentation']": {
      zIndex: 99999999,
    },
    h1: {
      fontSize: "48px",
    },

    h2: {
      fontSize: "44px",
    },

    h3: {
      fontSize: "26px",
    },

    h4: {
      fontSize: "20px",
    },

    h5: {
      fontSize: "18px",
    },

    h6: {
      fontSize: "14px",
    },

    p: {
      fontSize: "14px",
    },

    strong: {
      fontWeight: 400,
    },
    ".swal2-title": {
      fontSize: "26px",
      lineHeight: "32px",
      fontWeight: 400,
      color: theme?.grey?.main,
    },
    ".swal2-content": {
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: 400,
      color: theme?.grey?.main,
    },
    ".swal2-actions": { flexDirection: "row-reverse" },
    ".swal2-styled.swal2-confirm": {
      backgroundColor: `${theme?.palette?.primary?.main} !important`,
      borderRadius: "10px",
      "&:focus": {
        boxShadow: "none",
      },
    },
    ".swal2-icon.swal2-info": {
      borderColor: theme?.palette?.primary?.light,
      color: `${theme?.palette?.primary?.main} !important`,
    },
    ".swal2-styled.swal2-cancel": {
      backgroundColor: `${theme?.palette?.primary?.light} !important`,
      color: `${theme?.palette?.primary?.main} !important`,
      borderRadius: "10px",
      "&:focus": {
        boxShadow: "none",
      },
    },
    ".swal2-actions:not(.swal2-loading) .swal2-styled:hover": { backgroundImage: "none" },
    ".swal2-container": {
      zIndex: 99999,
    },
    ".swal2-modal": { borderRadius: "28px" },
    ".css-6hp17o-MuiList-root-MuiMenu-list": {
      display: "flex",
      flexDirection: "column",
      padding: 0,
    },
    ".cm-editor:focus, .cm-editor:focus-visible, .ͼ1.cm-focused": {
      outline: "none !important",
    },
  };
};
