import React from "react";

import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import useDropzoneOverride from "../../../../custom_hooks/useDropzoneOverride";
import { StyledDragZoneContainer } from "../StyledImageUpload";

export default function Upload() {
  const {
    layout: { formPageType },
    media: { element },
  } = useSelector((state) => state);
  const pagePath = formPageType?.page?.split(".");

  const { getRootProps, getInputProps, isLoading } = useDropzoneOverride(pagePath[0]);

  return (
    <StyledDragZoneContainer>
      <div className="drag-input-container" {...getRootProps()}>
        {!isLoading ? (
          <>
            <p className="drag-paragraph">Drop your files here</p>
            <p className="drag-paragraph">or</p>
            <div className="input-container">
              <p className="input-paragraph">Select Files</p>
              <input
                {...getInputProps()}
                accept={`image/*, application/json, ${element?.hasVideo && "video/mp4, video/webm"}`}
              />
            </div>
          </>
        ) : (
          <CircularProgress style={{margin:"0 auto"}}/>
        )}
      </div>
    </StyledDragZoneContainer>
  );
}
