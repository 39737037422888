import React, { useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, Button } from "@mui/material";
import { useSelector } from "react-redux";

import V1 from "./components/V1";
import V2 from "./components/V2";
import V3 from "./components/V3";
import V4 from "./components/V4";
import { StyledGamethumbnails } from "./StyledLobbyPreview";
import NoImage from "../../../assets/images/noImage.png";
import DialogContainer from "../../Dialog/Index";
import { sortObjectsByIds } from "../../Utils/helpers";

export default function LobbyPreviewModal({ isOpen, onClose }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const lobbyVersion = useSelector((state) => state?.settings?.portalSettings?.layout?.lobby?.template);
  const portalLanguages = useSelector((state) => state?.settings?.portalSettings?.general?.languages);
  const modalOptions = useSelector((state) => state?.modal?.modal?.previewData);
  const categories = useSelector((state) => state?.form?.pageData?.categories);
  const providers = useSelector((state) => state?.form?.pageData?.providers);
  const activeCategory = useSelector((state) => state?.form?.orderCategory);
  const allGames = useSelector((state) => state?.form?.pageData?.all);

  const findPortalLanguage = (lang) => portalLanguages?.find((el) => el?.code === lang);

  const findCategoryTitle = (slug) => {
    if (slug === "all") return "All";
    const findCategoryBySlug = categories?.find((el) => el?.slug === slug);
    return findCategoryBySlug?.title || findCategoryBySlug?.multilingual?.[0]?.title || "";
  };

  const findProviderTitle = (slug) => {
    return providers?.find((el) => el?.slug === slug)?.multilingual?.[0]?.name || slug || "";
  };

  const previewDescription = {
    defaultOrderDesktop: `This is the order that will render on lobby page by default for the desktop devices. This order of ${findCategoryTitle(
      activeCategory
    )} games if set will always be displayed even if order for specific desktop language is not set.`,
    defaultOrderMobile: `This is the order that will render on lobby page by default for the mobile/tablet devices. This order of ${findCategoryTitle(
      activeCategory
    )} games if set will always be displayed even if order for specific mobile/tablet language is not set.`,
    desktop: `This is the order that will render on lobby page for the desktop devices, for ${findCategoryTitle(
      activeCategory
    )} games, if users portal language is set to be ${
      findPortalLanguage(modalOptions?.activeLanguage)?.name
    }. If this order is not set, it will fallback to the default desktop order.`,
    mobile: `This is the order that will render on lobby page for the mobile/tablet devices, for ${findCategoryTitle(
      activeCategory
    )} games, if users portal language is set to be ${
      findPortalLanguage(modalOptions?.activeLanguage)?.name
    }. If this order is not set, it will fallback to the default mobile order.`,
  };

  const handleTooltipOpen = () => setTooltipOpen(!tooltipOpen);

  const headerTooltipLabel = (
    <>
      Game order (lobby page)
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipOpen}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={previewDescription[modalOptions?.orderName] || ""}
      >
        <Button onClick={handleTooltipOpen} sx={{ marginLeft: 1 }}>
          <InfoIcon />
        </Button>
      </Tooltip>
    </>
  );

  const renderGameThumbailsOrder = () => {
    const gamesToRender = allGames?.filter((el) => modalOptions?.selected?.includes(el?.id)) || [];

    return sortObjectsByIds(modalOptions?.selected, gamesToRender).map((game, index) => {
      return (
        <StyledGamethumbnails key={index}>
          <img src={game?.desktopThumbnail?.url || game?.mobileThumbnail?.url || NoImage} alt={game?.name} />
          <div className="game-info">
            <p className="game-name">{game?.name}</p>
            <p className="game-provider">{findProviderTitle(game?.provider)}</p>
          </div>
        </StyledGamethumbnails>
      );
    });
  };

  const lobbyVersionHeadConf = {
    1: <V1 title={findCategoryTitle(activeCategory)} games={renderGameThumbailsOrder()} />,
    2: <V2 findCategoryTitle={(slug) => findCategoryTitle(slug)} games={renderGameThumbailsOrder()} />,
    3: <V3 findCategoryTitle={(slug) => findCategoryTitle(slug)} games={renderGameThumbailsOrder()} />,
    4: <V4 title={findCategoryTitle(activeCategory)} games={renderGameThumbailsOrder()} />,
  };

  return (
    <DialogContainer
      isOpen={isOpen}
      label={headerTooltipLabel}
      actionLabel="Close preview"
      onAction={onClose}
      onClose={onClose}
      height={"95%"}
      width="1600px"
      showActionButtons={false}
      headerActionButton={true}
    >
      {lobbyVersionHeadConf[lobbyVersion] || "There is no available lobby preview."}
    </DialogContainer>
  );
}
