import React from "react";

import { GeneralWrap } from "../components/StyledComponents";
import { formateDate } from "../util/helpers";

const General = ({ data }) => {
  const generalItems = {
    slug: {
      label: "Slug:",
      value: data?.slug || data?.data?.postContent?.slug || data?.postContent?.slug || data?.postContent?.desktopSlug,
    },
    created_at: {
      label: "Created at:",
      value:
        formateDate(data?.postContent?.created_at) ||
        formateDate(data?.created_at) ||
        formateDate(data?.data?.created_at),
    },
    updated_at: {
      label: "Updated at:",
      value: formateDate(data?.updated_at) || formateDate(data?.data?.updated_at),
    },
    publishDate: {
      label: "Published at:",
      value:
        formateDate(data?.publishing?.publishDate) ||
        formateDate(data?.postContent?.publishing?.publishDate) ||
        formateDate(data?.data?.postContent?.publishing?.publishDate),
    },
    updated_by: {
      label: "Updated by:",
      value:
        data?.postContent?.publishing?.updatedByClient?.username ||
        data?.data?.postContent?.publishing?.updatedByClient?.username,
    },
    geoblock: {
      label: "Geoblock:",
      value: data?.postContent?.geoFilter?.blocked,
    },
  };

  return (
    <GeneralWrap>
      {Object.entries(generalItems).map(([key, val]) => {
        if (!val.value) return null;
        return (
          <div className="general-items" key={key}>
            <div className="general-item-key">{val.label}</div>
            <div className="general-item-value">{val.value}</div>
          </div>
        );
      })}
    </GeneralWrap>
  );
};

export default General;
