import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import revisionsService from "../services/revisionsService";

export const getAllRevisions = createAsyncThunk("revision/all", async (page) => {
  const { post, postID, signal } = page;
  const res = await revisionsService.getAllRevisions(post, postID, signal);
  if (page === "games") {
    return res?.data?.map((el) => {
      return {
        ...el,
        postContent: {
          ...el.postContent,
          desktopDivider: "",
          mobileDivider: "",
          additionalOptionsDivider: "",
        },
      };
    });
  }
  return res?.data?.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });
});

const revisionsSlice = createSlice({
  name: "revisions",
  initialState: {
    revisions: [],
    loading: false,
    error: false,
    activePageRevision: null,
  },
  reducers: {
    handleActiveRevision: (state, action) => {
      return { ...state, activePageRevision: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRevisions.pending, (state, action) => {
      return {
        ...state,
        loading: true,
        revisions: [],
      };
    });
    builder.addCase(getAllRevisions.fulfilled, (state, action) => {
      const newRevisions = action.payload;
      const allRevisions = [...state.revisions, ...newRevisions];
      return {
        ...state,
        loading: false,
        revisions: allRevisions,
      };
    });
    builder.addCase(getAllRevisions.rejected, (state, action) => {
      return {
        ...state,
        loading: true,
        error: true,
      };
    });
  },
});
export const { handleActiveRevision } = revisionsSlice.actions;
export default revisionsSlice.reducer;
