import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledJsonWrap = styled("div")(({ theme }) => ({
  width: 150,
  height: 178,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 10,
  cursor: "pointer",
  backgroundColor: theme.palette.primary.lighter,
  position: "relative",
  svg: {
    background: theme.palette.primary.light,
    padding: 10,
    width: "3rem",
    height: "3rem",
    borderRadius: 10,
    path: {
      fill: theme.palette.primary.main,
    },
  },
  ".file-name": {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "0 0 10px 10px",
    textAlign: "center",
    padding: "5px 0",
    position: "absolute",
    bottom: 0,
    width: "100%",
    p: {
      margin: 0,
      color: theme.palette.primary.main,
      fontSize: "12px",
    },
  },
}));

export const StyledUploadLibraryContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  ".styled-midsection": {
    height: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.black,
  },
}));

export const StyledLibraryGridContent = styled("div")(({ theme }) => ({
  position: "relative",
  ".image-wrapp": {
    width: 150,
    ".img-name": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "0 0 10px 10px",
      textAlign: "center",
      padding: "5px 0",
      p: {
        margin: 0,
        color: theme.palette.primary.main,
        fontSize: "12px",
      },
    },
  },
}));

export const StyledLibraryContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const StyledLibraryHeader = styled("h4")(({ theme }) => ({
  color: theme.palette.common.black,
}));

export const StyledGridPaginationContainer = styled("h4")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const StyledCheckboxContainer = styled("div")(({ theme }) => ({
  top: "8px",
  width: "fit-content",
  margin: 0,
  position: "absolute",
  right: "8px",
  ".styled-checkbox-outline": {
    background: "white",
    borderRadius: "50%",
    span: {
      svg: {
        width: "24px",
        height: "24px",
      },
    },
  },
}));

export const StyledSearchContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  width: "100%",
  position: "relative",
}));

export const StyledSearchIcon = styled(SearchIcon, {
  shouldForwardProp: (prop) => prop !== "exists" || prop !== "ishovered",
})(({ theme, exists, ishovered }) => ({
  position: "absolute",
  bottom: "5px",
  left: "5px",
  color:
    exists === "true"
      ? theme.palette.primary.main
      : ishovered === "true"
      ? theme.palette.grey.main
      : theme.palette.grey.lighter,
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  width: "100% !important",
  label: {
    marginLeft: "35px",
  },
  ".MuiInputBase-input": {
    paddingLeft: "35px",
  },
  ".MuiInput-underline:after": {
    borderColor: theme.palette.primary.main,
  },
  ".MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
}));

export const StyledImageGridContainer = styled("div")(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  gridTemplateColumns: "repeat(6, 1fr)",
  gap: "24px",
  p: {
    whiteSpace: "nowrap",
  },
}));

export const StyledVideo = styled("video")(({ theme }) => ({
  width: "150px",
  height: "150px",
  objectFit: "scale-down",
  borderRadius: "10px",
  cursor: "pointer",
}));

export const StyledImage = styled("img")(({ theme }) => ({
  width: "150px",
  height: "150px",
  objectFit: "scale-down",
  borderRadius: "10px 10px 0 0",
  cursor: "pointer",
  backgroundColor: theme.palette.primary.lighter,
}));

export const StyledDragZoneContainer = styled("div")(({ theme }) => ({
  background: theme.palette.primary.lighter,
  height: "100px",
  width: "100%",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "10px",
  borderStyle: "dotted",

  ".drag-input-container": {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    gap: "10px",
    cursor: "auto",
    ".drag-paragraph": {
      margin: 0,
      color: theme.palette.common.black,
      cursor: "auto",
    },
    ".input-container": {
      ".input-paragraph": {
        margin: 0,
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontWeight: 600,
      },
    },
  },
}));
