import axios from "axios";
import dayjs from "dayjs";

import instance from "../api_config";

const getAll = () => {
  const portal = localStorage.getItem("portal");
  return instance.get(`/settings?portal=${portal}&_limit=-1&created_at_lte=${dayjs().format("YYYY-MM-DDTHH:mm:ss")}`);
};

const getFonts = () => {
  return axios.get(`${process.env.PUBLIC_URL}/fonts.json`);
};

const getIcons = () => {
  return axios.get(`${process.env.PUBLIC_URL}/icons.json`);
};

const getStylesV2 = () => {
  const portal = localStorage.getItem("portal");
  return instance.get(`/styles?portal=${portal}&_limit=-1&created_at_lte=${dayjs().format("YYYY-MM-DDTHH:mm:ss")}`);
};

const uploadCustomFont = (font) => {
  const jwt = localStorage.getItem("jwt");
  return axios({
    method: "post",
    baseURL: process.env.REACT_APP_API_URL,
    url: "/services/fonts/upload",
    data: font,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const settingsService = {
  getAll,
  getFonts,
  getStylesV2,
  getIcons,
  uploadCustomFont,
};

export default settingsService;
