import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import Logo from "../../../../assets/images/logo-small.png";
import { StyledLobby } from "../StyledLobbyPreview";

export default function V1({ title, games }) {
  const lobbyOptions = useSelector((state) => state?.settings?.portalSettings?.layout?.lobby?.options);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: lobbyOptions?.autoPlay || false,
    arrows: lobbyOptions?.sliderNavigation !== "arrows",
  };

  return (
    <StyledLobby>
      {lobbyOptions?.showSlider ? (
        <Slider {...settings}>
          {Array.from(Array(3).keys()).map((_, index) => (
            <div className="lobby-slider" key={index}>
              <img src={Logo} alt="" />
            </div>
          ))}
        </Slider>
      ) : null}
      <div className="filters">
        <div className="filt-select">
          Choose category <ArrowDropDownIcon />
        </div>
        <div className="filt-select">
          Choose provider
          <ArrowDropDownIcon />
        </div>
      </div>
      <div className="game-preview">
        <h1>{title.toLowerCase() === "all" ? "All Games" : title}</h1>
        <div className="game-wrapper">{games}</div>
      </div>
    </StyledLobby>
  );
}
