import React from "react";

import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { useWindowSize } from "../../../custom_hooks/useWindowSize";
import { toggleSidebar } from "../../../Redux/slices/layoutSlice";
import { StyledSidebarItem, StyledTooltip } from "../StyledSidebar";

export default function SidebarItem({ item, collapseMenu, setIsActive, styledProp }) {
  const relativePath = process.env.REACT_APP_PUBLIC_RELATIVE_PATH || "";
  const url = relativePath + item.url;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const redirectTo = (url) => {
    navigate(url);
    if (width < 1024) {
      dispatch(toggleSidebar());
    }
  };

  const setToNull = () => {
    setIsActive(null);
  };

  return (
    <StyledSidebarItem
      collapsemenu={collapseMenu.toString()}
      className={`nav-menu-item-${item.id}`}
      onClick={() => setToNull()}
    >
      <StyledTooltip
        className="item-tooltip"
        title={item.title}
        placement="right"
        collapsemenu={collapseMenu.toString()}
      >
        <NavLink to={url} className={"nav-link"} end onClick={() => redirectTo(url)}>
          <span className="item-icon">{item.icon}</span>
          <span className="item-title">{item.title}</span>
        </NavLink>
      </StyledTooltip>
    </StyledSidebarItem>
  );
}
