import React, { Suspense } from "react";

import GlobalStyles from "@mui/material/GlobalStyles";

//
import ScrollToTop from "./Components/ScrollToTop";
import { globalStyles } from "./globalStyles";
import ThemeProvider from "./hoc/theme/theme";
import LoadingScreen from "./Pages/LoadingScreen";
import Router from "./routes";

export default function App() {
  return (
    <>
      <ThemeProvider>
        <GlobalStyles styles={(theme) => globalStyles(theme)} />
        <ScrollToTop>
          <Suspense fallback={<LoadingScreen />}>
            <Router />
          </Suspense>
        </ScrollToTop>
      </ThemeProvider>
    </>
  );
}
