import React from "react";

import AddToHomeScreenOutlinedIcon from "@mui/icons-material/AddToHomeScreenOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import PodcastsOutlinedIcon from "@mui/icons-material/PodcastsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import ViewCompactOutlinedIcon from "@mui/icons-material/ViewCompactOutlined";

const navigation = {
  items: [
    {
      id: "navigation",
      title: "",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          icon: <HomeOutlinedIcon />,
          url: "/dashboard",
        },
        {
          id: "pages",
          title: "Pages",
          type: "item",
          icon: <FileCopyOutlinedIcon />,
          url: "/pages/all",
        },
        {
          id: "articles",
          title: "Articles",
          type: "collapse",
          icon: <DescriptionOutlinedIcon />,
          children: [
            {
              id: "all-articles",
              title: "All Articles",
              type: "item",
              url: "/articles/all",
            },
            {
              id: "article-categories",
              title: "All Categories",
              type: "item",
              url: "/article-categories/all",
            },
          ],
        },
        {
          id: "promotions",
          title: "Promotions",
          type: "collapse",
          icon: <StarsOutlinedIcon />,
          children: [
            {
              id: "all-promotions",
              title: "All Promotions",
              type: "item",
              url: "/promotions/all",
            },
            {
              id: "promotions-categories",
              title: "All Categories",
              type: "item",
              url: "/promotion-categories/all",
            },
            {
              id: "promotion-orders",
              title: "Promotion Order",
              type: "item",
              url: "/order/promotion-orders",
            },
          ],
        },
        {
          id: "faqs",
          title: "FAQ",
          type: "collapse",
          icon: <HelpOutlineOutlinedIcon />,
          children: [
            {
              id: "all-faq",
              title: "List Q&A",
              type: "item",
              url: "/faqs/all",
            },
            {
              id: "faq-categories",
              title: "All Categories",
              type: "item",
              url: "/faq-categories/all",
            },
            {
              id: "faq-orders",
              title: "FAQ Order",
              type: "item",
              url: "/order/faq-orders",
            },
          ],
        },
        {
          id: "landing-pages",
          title: "Landing-pages",
          type: "item",
          icon: <ImportantDevicesOutlinedIcon />,
          url: "/landing-pages/all",
        },
        {
          id: "affiliate-pages",
          title: "Affiliates",
          type: "item",
          icon: <ExtensionOutlinedIcon />,
          url: "/affiliates",
        },
        {
          id: "sliders",
          title: "Sliders",
          type: "item",
          icon: <InsertPhotoOutlinedIcon />,
          url: "/sliders/all",
        },
        {
          id: "menus",
          title: "Menus",
          type: "item",
          icon: <MenuOutlinedIcon />,
          url: "/menus/all",
        },
        {
          id: "footer",
          title: "Footer",
          type: "item",
          icon: <ViewCompactOutlinedIcon />,
          url: "/footer",
          disableForRoles: ["clientAdmin"],
        },
        {
          id: "payment-methods",
          title: "Payment Methods",
          type: "collapse",
          icon: <CreditCardOutlinedIcon />,
          disableForRoles: ["clientAdmin"],
          children: [
            {
              id: "all-payments",
              title: "All Payment Methods",
              type: "item",
              url: "/payment-methods",
            },
            // {
            //   id: 'all-payments-alt',
            //   title: 'All Payment Methods Alt',
            //   type: 'item',
            //   url: '/payment-methods-alt'
            // },
            {
              id: "payment-methods-orders",
              title: "Order Payment Methods",
              type: "item",
              url: "/order/payment-methods-orders",
            },
          ],
        },
        {
          id: "games",
          title: "Games",
          type: "collapse",
          icon: <CasinoOutlinedIcon />,
          children: [
            {
              id: "all-games",
              title: "All Games",
              type: "item",
              url: "/games/all",
            },
            {
              id: "game-orders",
              title: "Order Games",
              type: "item",
              url: "/order/game-orders",
            },
            {
              id: "game-categories",
              title: "Extra Categories",
              type: "item",
              url: "/game-categories/all",
            },
            {
              id: "game-providers",
              title: "Game Providers",
              type: "item",
              url: "/game-providers/all",
            },
            {
              id: "game-tags",
              title: "Game Tags",
              type: "item",
              url: "/game-tags/all",
            },
          ],
        },
        {
          id: "broadcasts",
          title: "Broadcasts",
          type: "collapse",
          icon: <PodcastsOutlinedIcon />,
          children: [
            {
              id: "all-broadcasts",
              title: "All broadcasts",
              type: "item",
              url: "/broadcasts/all",
            },
            {
              id: "broadcast-categories",
              title: "All Categories",
              type: "item",
              url: "/broadcast-categories/all",
            },
          ],
        },
        {
          id: "media",
          title: "Media",
          type: "item",
          icon: <PermMediaOutlinedIcon />,
          url: "/media",
        },
        {
          id: "additional-infos",
          title: "Additional Infos",
          type: "item",
          icon: <AddToHomeScreenOutlinedIcon />,
          url: "/additional-infos/all",
        },
        {
          id: "bonus-infos",
          title: "Bonus Info",
          type: "item",
          icon: <LoupeOutlinedIcon />,
          url: "/bonus-infos/all",
        },
        {
          id: "translations",
          title: "Translations",
          type: "item",
          icon: <TranslateOutlinedIcon />,
          url: "/translations",
        },
        {
          id: "settings",
          title: "Settings",
          type: "collapse",
          icon: <SettingsOutlinedIcon />,
          children: [
            {
              id: "general",
              title: "General",
              type: "item",
              url: "/settings/general",
            },
            {
              id: "layout",
              title: "Layout",
              type: "item",
              url: "/settings/layout",
            },
            {
              id: "style",
              title: "Style",
              type: "item",
              url: "/settings/style",
            },
            {
              id: "style",
              title: "Styles V2",
              type: "item",
              url: "/settings/styles",
              disableForNonAdmin: true,
            },
            {
              id: "routes",
              title: "Routes",
              type: "item",
              url: "/settings/routes",
            },
            {
              id: "customCSS",
              title: "Custom CSS",
              type: "item",
              url: "/settings/customCSS",
            },
            {
              id: "customJS",
              title: "Custom JS",
              type: "item",
              url: "/settings/customJS",
            },
            {
              id: "features",
              title: "Features",
              type: "item",
              url: "/settings/features",
            },
          ],
        },
      ],
    },
  ],
};

export default navigation;
