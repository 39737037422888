import { styled } from "@mui/system";

export const StyledLayout = styled("div")(({ sidebarOpen, isDeffPage, isSettingsLayout }) => ({
  padding: `${isDeffPage ? 24 : 70}px ${!sidebarOpen ? 40 : 24}px 0`,
  position: "relative",
  margin: "0 auto",
  display: "block",
  clear: "both",
  float: "unset",
  right: 0,
  marginLeft: !sidebarOpen ? 88 : 264,
  minHeight: "100vh",
  transition: "all 0.3s ease-in-out",
  "&.not-found": {
    marginLeft: 0,
    paddingTop: 50,
  },
  maxHeight: isSettingsLayout ? "100vh" : "unset",
  overflow: isSettingsLayout ? "hidden" : "unset",
}));
