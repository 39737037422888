import React, { lazy, Suspense } from "react";

import { Navigate, useRoutes } from "react-router-dom";

import Layout from "./hoc";
import ProtectedRoute from "./hoc/components/ProtectedRoute";
// 404 page
const NotFound = lazy(() => import("./Pages/NotFound"));
// loading screen
const LoadingScreen = lazy(() => import("./Pages/LoadingScreen"));
// login
const Login = lazy(() => import("./Pages/Authentication/LoginPage"));
// reset password
const ForgotPasswordPage = lazy(() => import("./Pages/Authentication/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./Pages/Authentication/ResetPasswordPage"));
// dashboard
const Dashboard = lazy(() => import("./Pages/Dashboard"));
// pages routes
const NewPage = lazy(() => import("./Pages/Pages/New"));
const EditPages = lazy(() => import("./Pages/Pages/Edit"));
// article routes
const NewArticle = lazy(() => import("./Pages/Articles/New"));
const EditArticle = lazy(() => import("./Pages/Articles/Edit"));

// article categories routes
const NewArticleCategory = lazy(() => import("./Pages/ArticleCategories/New"));
const EditArticleCategory = lazy(() => import("./Pages/ArticleCategories/Edit"));
// promotion routes
const NewPromotion = lazy(() => import("./Pages/Promotions/New"));
const EditPromotion = lazy(() => import("./Pages/Promotions/Edit"));

const NewPromotionCategory = lazy(() => import("./Pages/PromotionCategories/New"));
const EditPromotionCategory = lazy(() => import("./Pages/PromotionCategories/Edit"));
// faq routes
const NewFAQ = lazy(() => import("./Pages/FAQ/New"));
const EditFaq = lazy(() => import("./Pages/FAQ/Edit"));

const NewFaqCategory = lazy(() => import("./Pages/FaqCategory/New"));
const EditFaqCategory = lazy(() => import("./Pages/FaqCategory/Edit"));

// const FAQCategory = lazy(() => import("./Pages/FAQ/Category"));
// landing page routes
const EditLandingPage = lazy(() => import("./Pages/LandingPages/Edit"));
const NewLandingPage = lazy(() => import("./Pages/LandingPages/New"));
// affiliate
const Affiliates = lazy(() => import("./Pages/Affiliates"));
// slider routes
const NewSlider = lazy(() => import("./Pages/Slides/New"));
const EditSlide = lazy(() => import("./Pages/Slides/Edit"));
// menu routes
const NewMenu = lazy(() => import("./Pages/Menus/New"));
const EditMenu = lazy(() => import("./Pages/Menus/Edit"));
// footer
const Footer = lazy(() => import("./Pages/Footer"));
// payment method routes
const PaymentMethods = lazy(() => import("./Pages/PaymentMethods"));
// game routes
const EditGame = lazy(() => import("./Pages/Games/Edit"));

const NewGameCategory = lazy(() => import("./Pages/GameCategories/New"));
const EditGameCategory = lazy(() => import("./Pages/GameCategories/Edit"));

const NewGameProvider = lazy(() => import("./Pages/GameProviders/New"));
const EditGameProvider = lazy(() => import("./Pages/GameProviders/Edit"));

const NewGameTags = lazy(() => import("./Pages/GameTags/New"));
const EditGameTags = lazy(() => import("./Pages/GameTags/Edit"));
// media
const Media = lazy(() => import("./Pages/Media"));
// broadcast routes
const NewBroadcast = lazy(() => import("./Pages/Broadcasts/New"));
const EditBroadcast = lazy(() => import("./Pages/Broadcasts/Edit"));
const NewBroadcastCategory = lazy(() => import("./Pages/BroadcastCategory/New"));
const EditBroadcastCategory = lazy(() => import("./Pages/BroadcastCategory/Edit"));
// translations
const Translations = lazy(() => import("./Pages/Translations"));
// additional info routes
const NewAditionalInfos = lazy(() => import("./Pages/AditionalInfo/New"));
const EditAditionalInfos = lazy(() => import("./Pages/AditionalInfo/Edit"));
// bonus info routes
const NewBonusInfos = lazy(() => import("./Pages/BonusInfo/New"));
const EditBonusInfos = lazy(() => import("./Pages/BonusInfo/Edit"));
// settings routes
const GeneralSettings = lazy(() => import("./Pages/Settings/General"));
const LayoutSettings = lazy(() => import("./Pages/Settings/Layout/index"));
const StyleSettings = lazy(() => import("./Pages/Settings/Style"));
const RoutesSettings = lazy(() => import("./Pages/Settings/Routes"));
const CustomCSSSettings = lazy(() => import("./Pages/Settings/CustomCSS"));
const CustomJSSettings = lazy(() => import("./Pages/Settings/CustomJS"));
const FeaturesSettings = lazy(() => import("./Pages/Settings/Features"));
const StyleV2Settings = lazy(() => import("./Pages/Settings/StyleV2"));
// Dynamic Tables
const TablePage = lazy(() => import("./Pages/DynamicPages/Table"));
// Dynamic Order Pages
const DynamicOrder = lazy(() => import("./Pages/DynamicPages/Order"));

export default function Router() {
  const isLogged = localStorage.getItem("jwt");

  return useRoutes([
    {
      path: "/",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <Layout />
        </Suspense>
      ),
      children: [
        {
          path: "/",
          element: <Navigate to={`/administration/${isLogged ? "dashboard" : "login"}`} />,
        },
        { path: "404", element: <NotFound /> },
      ],
    },
    {
      path: "/administration",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        </Suspense>
      ),
      children: [
        {
          path: "/administration",
          element: <Navigate to={`/administration/${isLogged ? "dashboard" : "login"}`} />,
        },
        { path: "login", element: <Login /> },
        { path: "forgot-password", element: <ForgotPasswordPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "dashboard", element: <Dashboard /> },

        { path: "pages/new", element: <NewPage /> },
        { path: "pages/edit/:id", element: <EditPages /> },

        { path: "articles/new", element: <NewArticle /> },
        { path: "articles/edit/:id", element: <EditArticle /> },

        { path: "article-categories/new", element: <NewArticleCategory /> },
        { path: "article-categories/edit/:id", element: <EditArticleCategory /> },

        { path: "promotions/new", element: <NewPromotion /> },
        { path: "promotions/edit/:id", element: <EditPromotion /> },

        { path: "promotion-categories/new", element: <NewPromotionCategory /> },
        {
          path: "promotion-categories/edit/:id",
          element: <EditPromotionCategory />,
        },

        { path: "faqs/new", element: <NewFAQ /> },
        { path: "faqs/edit/:id", element: <EditFaq /> },
        { path: "faq-categories/new", element: <NewFaqCategory /> },
        { path: "faq-categories/edit/:id", element: <EditFaqCategory /> },

        { path: "affiliates", element: <Affiliates /> },

        { path: "landing-pages/new", element: <NewLandingPage /> },
        { path: "landing-pages/edit/:id", element: <EditLandingPage /> },

        { path: "sliders/new", element: <NewSlider /> },
        { path: "sliders/edit/:id", element: <EditSlide /> },

        { path: "menus/new", element: <NewMenu /> },
        { path: "menus/edit/:id", element: <EditMenu /> },

        { path: "footer", element: <Footer /> },

        { path: "payment-methods", element: <PaymentMethods /> },

        { path: "games/edit/:id", element: <EditGame /> },

        { path: "game-tags/new", element: <NewGameTags /> },
        { path: "game-tags/edit/:id", element: <EditGameTags /> },

        { path: "game-categories/new", element: <NewGameCategory /> },
        { path: "game-categories/edit/:id", element: <EditGameCategory /> },

        { path: "game-providers/new", element: <NewGameProvider /> },
        { path: "game-providers/edit/:id", element: <EditGameProvider /> },

        { path: "broadcasts/new", element: <NewBroadcast /> },
        { path: "broadcasts/edit/:id", element: <EditBroadcast /> },
        { path: "broadcast-categories/new", element: <NewBroadcastCategory /> },
        {
          path: "broadcast-categories/edit/:id",
          element: <EditBroadcastCategory />,
        },

        { path: "additional-infos/new", element: <NewAditionalInfos /> },
        { path: "additional-infos/edit/:id", element: <EditAditionalInfos /> },

        { path: "bonus-infos/new", element: <NewBonusInfos /> },
        { path: "bonus-infos/edit/:id", element: <EditBonusInfos /> },

        { path: "media", element: <Media /> },

        { path: "translations", element: <Translations /> },

        { path: "settings/general", element: <GeneralSettings /> },
        { path: "settings/layout", element: <LayoutSettings /> },
        { path: "settings/style", element: <StyleSettings /> },
        { path: "settings/styles", element: <StyleV2Settings /> },
        { path: "settings/routes", element: <RoutesSettings /> },
        { path: "settings/customCSS", element: <CustomCSSSettings /> },
        { path: "settings/customJS", element: <CustomJSSettings /> },
        { path: "settings/features", element: <FeaturesSettings /> },
        // Dynamic Routes
        { path: "order/:orderPath", element: <DynamicOrder /> },
        { path: ":path/all", element: <TablePage /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
