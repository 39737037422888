import React, { useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

import EditorField from "../../../Utils/Form/FormFields/Editor";
import { transformCamelCaseToReadable } from "../../../Utils/helpers";

const DescriptorTabs = ({ changeDescriptor, descriptor }) => {
  const [activeTab, setActiveTab] = useState("deposit");

  const tabs = [
    {
      key: "deposit",
      label: "Deposit descriptors",
      fields: ["depositDescriptorTop", "depositDescriptorBottom"],
    },
    {
      key: "withdraw",
      label: "Withdraw descriptors",
      fields: ["withdrawDescriptorTop", "withdrawDescriptorBottom"],
    },
  ];

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, tab) => setActiveTab(tab)}>
            {tabs?.map((tab, tab_idx) => (
              <Tab label={tab.label} value={tab.key} key={tab_idx} sx={{ fontSize: 18 }} />
            ))}
          </TabList>
        </Box>
        {tabs?.map((tab, tab2_idx) =>
          tab?.key !== activeTab ? null : (
            <TabPanel value={tab.key} key={tab2_idx}>
              {tab?.fields?.map((field, field_idx) => (
                <div className="field-wrapper" key={field_idx}>
                  <EditorField
                    formdata={{
                      showCode: false,
                      label: transformCamelCaseToReadable(field),
                      height: 300,
                      element: field,
                      value: descriptor?.[field] || "",
                    }}
                    id={`descriptor-${field_idx}`}
                    elemPos={field_idx}
                    handleBlur={() => {}}
                    handleChange={changeDescriptor}
                    disabled={false}
                    error={false}
                    showError={() => {}}
                    isDescriptorEditor={true}
                  />
                </div>
              ))}
            </TabPanel>
          )
        )}
      </TabContext>
    </Box>
  );
};

export default DescriptorTabs;
