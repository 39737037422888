import React from "react";

import { Checkbox, Tooltip } from "@mui/material";

import NoImage from "../../../../assets/images/noImage.png";

const GameThumbnail = ({ game, isChecked, handleCheck, key }) => {
  return (
    <div className="game-wrap" key={`game-wrap-${key}`} onClick={() => handleCheck(!isChecked)}>
      <div className="checkbox-outlined">
        <Checkbox
          id={game.id}
          value={game.id}
          onChange={(event) => handleCheck(event.target.checked)}
          checked={isChecked}
        />
      </div>
      <img src={game?.desktopThumbnail?.url || game?.mobileThumbnail?.url || NoImage} alt="" />
      <div className="game-name">
        <Tooltip title={game?.name} placement="top" key={key}>
          <p>{game?.name}</p>
        </Tooltip>
      </div>
    </div>
  );
};

export default GameThumbnail;
