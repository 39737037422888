import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import GameThumbnail from "./components/GameThumbnail";
import SearchField from "./components/Search";
import SelectDropDown from "./components/Select";
import { StyledContainer } from "./styled";
import { useFilter } from "../../../custom_hooks/useFilter";
import useMediumPagination from "../../../custom_hooks/useMediumPagination";
import { handleGetPageData, updateGameById } from "../../../Redux/slices/form/actions";
import CenteredCircularProgress from "../../CenteredCircularProgress";
import CustomButton from "../../CustomButton";
import DialogContainer from "../../Dialog/Index";
import MediumPagination from "../../Pagination/MediumPagination";
import ProgressBar from "../../ProgressBar";
import { Skeleton } from "@mui/material";

export default function CategoryBulkAdd({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const modalData = useSelector((state) => state?.modal?.modal.previewData);
  const allGames = useSelector((state) => state?.form?.pageData?.games)?.filter((el) =>
    el?.extraCategories ? el?.extraCategories?.split(",")?.every((el) => el !== modalData?.slug) : true
  );
  const games = allGames?.map((el) => ({
    ...el,
    device: el?.desktopEnabled && el?.mobileEnabled ? "Web, Mobile" : el?.desktopEnabled ? "Web" : "Mobile",
  }));
  const { filteredData, handleChange } = useFilter(games, "games");
  const [selectedGames, setSelectedGames] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updated, setUpdated] = useState(0);

  const allPageCategories = useSelector((state) => state?.form?.pageData?.all);
  const singlePageCategories = useSelector((state) => state?.form?.pageData?.categories);
  const providers = useSelector((state) => state?.form?.pageData?.providers);
  const device = useSelector((state) => state?.form?.pageData?.device);

  const providersFromGames = providers?.filter((el) => games?.some((game) => game?.provider === el?.slug));
  const categoriesFromGames = (allPageCategories || singlePageCategories)?.filter((category) =>
    games?.some((game) => {
      const gameCategories = [
        game?.category,
        ...(game?.extraCategories ? game.extraCategories.split(",") : []),
        game?.type,
        game?.subCategory,
      ];
      return category?.slug !== "all" && gameCategories.includes(category?.slug);
    })
  );

  const selectFields = [
    { name: "Categories", options: categoriesFromGames },
    { name: "Providers", options: providersFromGames },
    { name: "Device", options: device },
  ];

  const {
    curentPage,
    setCurentPage,
    sliceArgFirst,
    sliceArgSecond,
    handleGoToPageOnEnter,
    handleKeyDown,
    goToPageInput,
  } = useMediumPagination(12, Math.round(filteredData?.length / 12));

  const addCategory = (game) => {
    if (!game?.extraCategories) {
      return { ...game, extraCategories: modalData?.slug };
    }
    const extraCategoriesArray = Boolean(game?.extraCategories) && game?.extraCategories?.split(",");
    extraCategoriesArray.push(modalData?.slug);
    const updatedExtraCategories = extraCategoriesArray.join(",");
    return { ...game, extraCategories: updatedExtraCategories };
  };

  const handleCheck = (checked, game) => {
    const updatedGame = addCategory(game);
    setSelectedGames((prevObjects) =>
      checked ? [...prevObjects, updatedGame] : prevObjects.filter((obj) => obj.id !== updatedGame.id)
    );
  };

  const submitGames = async () => {
    const gamesToUpdate = [...selectedGames];
    let updatedCount = 0;
    for (const game of gamesToUpdate) {
      try {
        setIsUpdating(true);

        await dispatch(
          updateGameById({ gameID: game?.id, dataToSubmit: { extraCategories: game?.extraCategories }, toGames: true })
        );

        updatedCount++;
        setUpdated(updatedCount);
      } catch (error) {
        console.error("Error updating game:", error);
        enqueueSnackbar(`Error while updating game ${game?.name}, please try again.`, { variant: "error" });
      }
    }
    setTimeout(() => {
      setIsUpdating(false);
      setSelectedGames([]);
      onClose();
      setUpdated(0);
      enqueueSnackbar("You have successfully updated games with new categories!", { variant: "success" });
    }, 2000);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    dispatch(handleGetPageData({ pageType: "games", pageID: false, objPath: "games", retain: true }, signal));
    dispatch(handleGetPageData({ pageType: "st-device", pageID: false, objPath: "device", retain: true }, signal));
    dispatch(
      handleGetPageData({ pageType: "game-categories", pageID: false, objPath: "categories", retain: true }, signal)
    );
    dispatch(
      handleGetPageData({ pageType: "game-providers", pageID: false, objPath: "providers", retain: true }, signal)
    );
    return () => {
      abortController.abort();
    };
  }, []);

  const renderContent = () => {
    return filteredData?.slice(sliceArgFirst, sliceArgSecond)?.map((el, idx) => {
      return (
        <GameThumbnail
          key={idx}
          game={el}
          handleCheck={(val) => handleCheck(val, el)}
          isChecked={selectedGames?.some((obj) => obj.id === el.id)}
        />
      );
    });
  };
  return (
    <DialogContainer isOpen={isOpen} onClose={onClose} height={"758px"} width="1080px" showActionButtons={false}>
      <StyledContainer progress={(updated / selectedGames?.length) * 100}>
        <div className="modal-header">
          <div>Add games for &quot;{modalData?.title}&quot; category</div>
          <CloseIcon className="close-icon" onClick={onClose} />
        </div>
        {isUpdating ? (
          <div className="progress-bar">
            <ProgressBar value={(updated / selectedGames?.length) * 100} width={"50%"} />
            <div className="updated-games">
              Updated {updated} of {selectedGames?.length} games.
            </div>
          </div>
        ) : (
          <>
            <div className="filters-container">
              {selectFields?.map((item, idx) => {
                if (!item?.options?.length) return <Skeleton variant="rounded" height={56} width={240} key={idx} />;
                return (
                  <SelectDropDown
                    options={item?.options}
                    name={item?.name}
                    key={idx}
                    handleChange={(val, key) => handleChange(val, "select", key)}
                  />
                );
              })}
              <SearchField handleChange={(e) => handleChange(e.target.value, "search")} />
            </div>
            <div className="games-wrap">
              {!games?.length ? (
                <CenteredCircularProgress className="circular-progress" />
              ) : filteredData?.length ? (
                <>
                  <div className="all-games">{renderContent()}</div>
                  <MediumPagination
                    data={filteredData}
                    inputValue={goToPageInput.inputValue}
                    isInputValid={goToPageInput.isValid}
                    itemsPerPage={12}
                    curentPage={curentPage}
                    setCurentPage={setCurentPage}
                    handleGoToPageOnEnter={handleGoToPageOnEnter}
                    handleKeyDown={handleKeyDown}
                  />
                </>
              ) : (
                <div className="no-games">No games found for current filters.</div>
              )}
            </div>
            <div className="buttons">
              <CustomButton styleType="empty" variant="primary" onClick={() => onClose()}>
                Cancel
              </CustomButton>
              <CustomButton styleType="filled" variant="primary" onClick={() => submitGames()}>
                Apply
              </CustomButton>
            </div>
          </>
        )}
      </StyledContainer>
    </DialogContainer>
  );
}
