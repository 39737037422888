import { styled } from "@mui/material/styles";

export const StyledLobby = styled("div")(({ theme }) => ({
  overflow: "hidden",
  ".lobby-slider": {
    height: 200,
    width: "100%",
    background: "#F7F7F8",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    img: {
      height: 75,
    },
  },
  ".filters": {
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 24,
    ".filt-select": {
      padding: 16,
      height: 48,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 10,
      background: "#F7F7F8",
      color: "#848484",
      cursor: "not-allowed",
      minWidth: 200,
    },
  },
  ".game-preview": {
    h1: {
      margin: "0 0 24px",
      fontSize: 24,
      fontWeight: "bold",
    },
  },
  ".game-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexFlow: "row wrap",
    gap: 24,
  },
  ".fiter-wrapp": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    gap: 24,
  },
  ".categories-wrapp": {
    span: {
      marginRight: 40,
      cursor: "not-allowed",
      "&.active": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const StyledGamethumbnails = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  width: "calc(100% / 6 - 20px)",
  img: {
    width: "100%",
    height: "calc(100% - 24px)",
    maxHeight: 180,
    borderRadius: "10px 10px 0 0",
    objectFit: "cover",
  },
  ".game-info": {
    width: "100%",
    background: "#F7F7F8",
    borderRadius: "0 0 10px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "8px 0",
    p: {
      margin: 0,
      "&.game-name": {
        fontSize: 14,
        fontWeight: "bold",
        color: "#848484",
      },
      "&.game-provider": {
        fontSize: 12,
        fontWeight: "400",
        color: "#848484",
      },
    },
  },
}));

export const StyledLobbyV3 = styled("div")(({ theme }) => ({
  overflow: "hidden",
  ".categories-wrapp": {
    span: {
      marginRight: 40,
      cursor: "not-allowed",
      "&.active": {
        color: theme.palette.primary.main,
      },
    },
  },
  ".filters": {
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
    ".filt-select": {
      padding: 16,
      height: 48,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 10,
      background: "#F7F7F8",
      color: "#848484",
      cursor: "not-allowed",
      minWidth: 200,
      "&.search": {
        width: "100%",
      },
    },
    ".action-filters": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 24,
      ".filt-select": {
        width: 48,
        minWidth: "unset",
        justifyContent: "center",
      },
    },
  },
  ".game-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexFlow: "row wrap",
    gap: 24,
  },
}));
