import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material";

export const StyledDetailsComponent = styled("div")(({ theme }) => ({
  padding: "36px 30px 16px 10px",
  display: "flex",
  alignItems: "flex-start",
  position: "relative",
  gap: 50,
  h2: {
    fontSize: 24,
    fontWeight: "600",
    color: theme.palette.grey.main,
    lineHeight: "24px",
  },
  p: {
    fontSize: 14,
    fontWeight: "400",
    color: theme.palette.grey.main,
  },
  ".close-icon": {
    position: "absolute",
    top: 17,
    right: 0,
    cursor: "pointer",
  },
  ".left-container": {
    width: "75%",
    minHeight: "500px",
    ".component-slider-item": {
      border: ` 2px solid ${theme.palette.grey[300]}`,
      position: "relative",
      borderRadius: 8,
      height: 550,
      display: "flex !important",
      alignItems: "center",
      justifyContent: "center",
      img: {
        height: "100%",
      },
    },
    ".cms-options": {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: 10,
      h2: {
        marginBottom: 12,
      },
      ".options-switch": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        ".switch-div": {
          display: "flex",
          alignItems: "center",
          marginRight: 8,
        },
      },
      ".loading-all-options": {
        width: "100%",
        height: 175,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".all-options": {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gridAutoRows: "min-content",
        height: 175,
        overflowY: "scroll",
        columnGap: 100,
        "::-webkit-scrollbar-track": {
          background: "#f5f5f5",
          borderRadius: "12px",
        },
        ".option": {
          display: "flex",
          height: "fit-content",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 15,
          ".option-name": {
            listStyleType: "circle",
            fontSize: 14,
            fontWeight: "700",
            color: theme.palette.grey.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:before": {
              /* add the new bullet point */ display: "inline-block",
              content: '""',
              borderRadius: "100%",
              height: "3px",
              width: "3px",
              marginRight: "0.5rem",
              backgroundColor: theme.palette.grey.main,
            },
          },
          ".option-desc": {
            marginLeft: 14,
          },
        },
      },
    },
  },
  ".right-container": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    ".right-first": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      paddingBottom: 30,
      marginBottom: 30,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      h2: {
        marginBottom: 12,
      },
      p: {
        height: 210,
        overflowY: "scroll",
      },
    },
    ".right-second": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      paddingBottom: 30,
      marginBottom: 30,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      h2: {
        marginBottom: 15,
      },
      ".links": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        a: {
          padding: 18,
          border: ` 1px solid ${theme.palette.grey[300]}`,
          borderRadius: 8,
          svg: {
            path: {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
    ".right-third": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      h2: {
        marginBottom: 24,
      },
      ".features": {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        height: 230,
        overflowY: "scroll",
        columnGap: 35,
        ".feature": {
          display: "flex",
          alignItems: "flex-start",
          marginBottom: 25,
          svg: {
            height: 24,
            width: 24,
            marginRight: 8,
          },
          ".true": {
            svg: {
              path: {
                fill: theme.palette.primary.main,
              },
            },
          },
          ".false": {
            svg: {
              path: {
                stroke: theme.palette.primary.main,
              },
            },
          },
          p: {
            width: "75%",
          },
        },
      },
    },
  },
}));

export const StyledNextArrow = styled("div")(({ theme }) => ({
  right: "-16px !important",
  width: "32px !important",
  height: "32px !important",
  borderRadius: "50%",
  boxShadow: `0px 0px 4px ${alpha(theme.palette.primary.main, 0.25)}`,
  backgroundColor: `${theme.palette.common.white} !important`,
  display: "flex !important",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9,
  svg: {
    path: {
      fill: theme.palette.primary.main,
    },
  },
  "&:before": {
    display: "none",
  },
  "&.slick-disabled": {
    boxShadow: `0px 0px 4px ${alpha(theme.palette.grey.main, 0.25)}`,
    cursor: "not-allowed",
    svg: {
      path: {
        fill: theme.palette.grey.main,
      },
    },
  },
}));

export const StyledPrevArrow = styled("div")(({ theme }) => ({
  left: "-16px !important",
  width: "32px !important",
  height: "32px !important",
  borderRadius: "50%",
  boxShadow: `0px 0px 4px ${alpha(theme.palette.primary.main, 0.25)}`,
  backgroundColor: `${theme.palette.common.white} !important`,
  display: "flex !important",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9,
  svg: {
    path: {
      fill: theme.palette.primary.main,
    },
  },
  "&:before": {
    display: "none",
  },
  "&.slick-disabled": {
    boxShadow: `0px 0px 4px ${alpha(theme.palette.grey.main, 0.25)}`,
    cursor: "not-allowed",
    svg: {
      path: {
        fill: theme.palette.grey.main,
      },
    },
  },
}));
