import React, { useState } from "react";

import { Box, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import Upload from "./Upload";

const steps = [
  {
    key: "main",
    label: "Upload Main Font",
  },
  {
    key: "text",
    label: "Upload Text Font",
  },
];

export default function FontsStepper() {
  const mainCustomFont = useSelector((state) => state?.settings?.customFonts?.main);
  const customV2Fonts = useSelector((state) => state?.settings?.customV2Fonts);
  const isStyleV2 = useSelector((state) => state.modal?.modal?.previewData?.isStyleV2);
  const activeSlide = useSelector((state) => state.modal?.modal?.previewData?.activeSlide);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const isDisabled = isStyleV2 ? !customV2Fonts[activeSlide].main : !mainCustomFont;

  return (
    <Box>
      <Typography align="center">
        When using custom fonts in the casino, it is necessary to upload both main and text fonts. However, you are not
        limited to disable one of these fields after closing the modal.
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.key}>
            <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>
              {step.label}
            </StepLabel>
            <StepContent>
              <Upload type={step.key} />
              {index === steps.length - 1 ? null : (
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }} disabled={isDisabled}>
                      Continue
                    </Button>
                  </div>
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
