import React, { useEffect, useRef, useState } from "react";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useSelector } from "react-redux";

//
import PreviewField from "./PreviewField";
import PreviewSwitch from "./PreviewSwitch";
//
import { StyledAccordion } from "./StyledAccordion";

export default function CustomAccordion({ title, customClass, previewElement, formEl, defOpen, children, setLoad, formKey }) {
  const typography = useSelector((state) => state.form?.formData);
  const [isOpen, setIsOpen] = useState(defOpen);
  const [height, setHeight] = useState(0);
  const [changeFont, setChangeFont] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  const openAccordion = () => {
    setIsOpen(!isOpen);
    if (Boolean(formEl) && !previewElement) {
      setTimeout(() => {
        setLoad();
      }, 500);
    }
  };

  return (
    <StyledAccordion
      className={`${Boolean(customClass) && customClass} ${previewElement || isOpen ? "active" : ""}`}
      accHeight={height}
      previewElement={previewElement}
      formKey={formKey}
    >
      <div className="accordion-title" onClick={() => openAccordion()}>
        {title}
        <div className={`toggle ${previewElement || isOpen ? "active" : ""}`}>
          {previewElement ? (
            <PreviewSwitch
              formEl={formEl}
              changeFont={changeFont}
              setChangeFont={setChangeFont}
              typography={typography}
            />
          ) : (
            <ArrowBackIosNewOutlinedIcon />
          )}
        </div>
      </div>
      <div className={`accordion-content ${previewElement || isOpen ? "active" : ""}`}>
        <div className="acc-wrapp" ref={ref}>
          {previewElement ? <PreviewField formEl={formEl} typography={typography} changeFont={changeFont} /> : null}
          {children}
        </div>
      </div>
    </StyledAccordion>
  );
}
