import React from "react";

//
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

//
import ComponentOptions from "./components/ComponentOptions";
import ComponentPictures from "./components/ComponentPictures";
import Svg from "./components/Svg";
import { StyledDetailsComponent } from "./StyledDetailsComponent";
import DialogContainer from "../../Dialog/Index";
import { transformCamelCaseToReadable } from "../../Utils/helpers";

function ComponentDetailsModal({ isOpen, onClose }) {
  const modalState = useSelector((state) => state?.modal?.modal?.previewData);
  const details = modalState?.details;
  const options = modalState?.options;
  return (
    <DialogContainer isOpen={isOpen} onClose={onClose} width="1600px" showActionButtons={false}>
      <StyledDetailsComponent>
        <CloseIcon className="close-icon" onClick={onClose} />
        <div className="left-container">
          <div>
            <ComponentPictures
              picsNumber={details?.numberOfPics}
              component={details?.component}
              version={details?.key}
            />
            <ComponentOptions options={options} />
          </div>
        </div>
        <div className="right-container">
          <div className="right-first">
            <h2>{details?.name}</h2>
            <p>{details?.description}</p>
          </div>
          <div className="right-second">
            <h2>Documentation</h2>
            <div className="links">
              {Object.entries(details?.documentation)?.map(([key, val]) => {
                return (
                  <a href={val} target="_blank" key={key} rel="noreferrer">
                    <Svg type={key} />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="right-third">
            <h2>Features</h2>
            <div className="features">
              {details?.hasOwnProperty("features") &&
                Object.entries(details?.features)?.map(([key, val]) => {
                  return (
                    <div className="feature" key={key}>
                      <Svg type={val} className={val.toString()} />
                      <p>{transformCamelCaseToReadable(key)}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </StyledDetailsComponent>
    </DialogContainer>
  );
}

export default ComponentDetailsModal;
