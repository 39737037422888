import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clearPortalSettings } from "./settingsSlice";
import userService from "../services/userServices";

export const loginUser = createAsyncThunk("user/login", async (dataToSubmit) => {
  const res = await userService.login(dataToSubmit);
  return res?.data;
});

export const refreshUser = createAsyncThunk("user/refresh", async () => {
  const res = await userService.refresh();
  return res?.data;
});

export const logoutUser = createAsyncThunk("user/logout", async (_, { dispatch }) => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("portal");
  dispatch(clearPortalSettings());
  return null;
});

export const resetPassword = createAsyncThunk("user/resetPassword", async (dataToSubmit) => {
  const res = await userService.resetPassword(dataToSubmit);
  return res?.data;
});

export const forgotPassword = createAsyncThunk("user/forgotPassword", async (data, id) => {
  const res = await userService.forgotPassword(data.identifier);
  return res?.data;
});

export const resetPasswordByCode = createAsyncThunk("user/resetPasswordByCode", async (data, id) => {
  const res = await userService.resetPasswordByCode(data.code, data.password, data.confirmPassword);
  return res?.data;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    userPending: false,
    resetStatus: null,
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      return {
        ...state,
        userPending: true,
      };
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { jwt, user } = action.payload;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("portal", user?.portal);
      return {
        ...state,
        user: user,
        userPending: false,
      };
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      return {
        ...state,
        userPending: false,
      };
    });
    builder.addCase(refreshUser.pending, (state, action) => {
      return {
        ...state,
        userPending: true,
      };
    });
    builder.addCase(refreshUser.fulfilled, (state, action) => {
      return {
        ...state,
        user: action.payload,
        userPending: false,
      };
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      return {
        ...state,
        user: null,
      };
    });
    builder.addCase(forgotPassword.pending, (state) => {
      return {
        ...state,
        resetStatus: "loading",
      };
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      return {
        ...state,
        resetStatus: "success",
      };
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      return {
        ...state,
        resetStatus: null,
      };
    });
    builder.addCase(resetPasswordByCode.pending, (state) => {
      return {
        ...state,
        resetStatus: "loading",
      };
    });
    builder.addCase(resetPasswordByCode.fulfilled, (state) => {
      return {
        ...state,
        resetStatus: "success",
      };
    });
    builder.addCase(resetPasswordByCode.rejected, (state) => {
      return {
        ...state,
        resetStatus: null,
      };
    });
  },
});

const { reducer } = userSlice;
export default reducer;
