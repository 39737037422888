import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { getAllRevisions } from "../../../Redux/slices/revisionsSlice";
import { addTranslation, getAllTranslations, updateTranslation } from "../../../Redux/slices/translationSlice";
import DialogContainer from "../../Dialog/Index";
import { ModalContainer } from "../LogoOverride/Styled";
import ActionHeader from "./components/ActionHeader";
import RevisionTable from "./components/RevisionTable";

export default function TranslationsRevisions({ isOpen, onClose }) {
  const { revisions } = useSelector((state) => state.revisions);
  const [revisionsToDisplay, setRevisionsToDisplay] = useState([]);
  const [sortedRevisions, setSortedRevisions] = useState([]);
  const [filterCheckbox, setFilterCheckbox] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Added so translation update action can be fired after revision
  const event = new Event("updateTranslations");

  useEffect(() => {
    dispatch(getAllRevisions({ post: "translations" }));
  }, []);

  useEffect(() => {
    if (revisions?.length) {
      const sortedRevisions = [...revisions]
        ?.filter((el) => el?.postType === "translations")
        ?.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        })
        ?.slice(0, 100);
      setRevisionsToDisplay(sortedRevisions);
      setSortedRevisions(sortedRevisions);
    }
  }, [revisions]);

  useEffect(() => {
    setRevisionsToDisplay(
      !filterCheckbox
        ? sortedRevisions
        : revisionsToDisplay?.filter((revision) => revision?.postContent?.status === "deleted")
    );
  }, [filterCheckbox]);

  const handleUpdateTranslation = (revisionData) => {
    dispatch(updateTranslation({ id: revisionData.id, data: revisionData }))
      .then((res) => {
        dispatch(getAllRevisions({ post: "translations" }));
        window.dispatchEvent(event);
        enqueueSnackbar(`Translation successfully reverted.`, { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(`${err?.response?.data?.error}, please contact support.`, { variant: "error" });
      });
  };

  const handleAddTranslation = (revisionData) => {
    dispatch(addTranslation(revisionData))
      .then((res) => {
        enqueueSnackbar(`Translation successfully reverted.`, { variant: "success" });
        window.dispatchEvent(event);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`There was an error, please contact support.`, { variant: "error" });
      });
  };

  const handleRevertChanges = (revisionData) => {
    if (!revisionData) return;

    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Are you sure?",
      text: "Please confirm translation revision revert",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        const allTranslations = await dispatch(getAllTranslations());
        if (allTranslations.payload.find((translation) => translation.key === revisionData.key)) {
          handleUpdateTranslation(revisionData);
        } else handleAddTranslation(revisionData);
      }
    });
  };

  const handleOnSearchBoxChange = (e) => {
    const filteredRevisions = revisionsToDisplay.filter((revision) =>
      revision.postContent?.key?.includes(e.target.value)
    );
    setRevisionsToDisplay(e.target.value ? filteredRevisions : sortedRevisions);
  };

  return (
    <DialogContainer
      isOpen={isOpen}
      label={
        <div style={{ gap: "28px 0", display: "flex", flexDirection: "column" }}>
          <h3>Revisions</h3>
          <ActionHeader
            isCheck={filterCheckbox}
            onChangeCheckbox={() => setFilterCheckbox(!filterCheckbox)}
            onChangeSearch={(e) => handleOnSearchBoxChange(e)}
          />
        </div>
      }
      onClose={onClose}
      width="838px"
    >
      <ModalContainer>
        {revisionsToDisplay.length === 0 ? (
          <CircularProgress sx={{ margin: "auto" }} />
        ) : (
          <RevisionTable revisionsData={revisionsToDisplay} onRevert={handleRevertChanges} />
        )}
      </ModalContainer>
    </DialogContainer>
  );
}
