import React, { useState } from "react";

import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import {
  Fade,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import useCustomPagination from "../../../../custom_hooks/useCustomPagination";
import CustomPagination from "../../../Pagination";
import {
  RevisioRevert,
  StyledRevisionPopper,
  StyledRevisionTitle,
  StyledRow,
  TableCellMax165,
} from "./StyledRevisions";

const RevisionTable = ({ revisionsData, onRevert }) => {
  const TableHeadMap = ["Updated key", "Old key", "Updated at", "Updated by", ""];

  const {
    page,
    rowsPerPage,
    goToPageInput,
    paginationPageOptions,
    sliceArgFirst,
    sliceArgSecond,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangePagination,
    handleKeyDown,
    handleGoToPageOnEnter,
    handleGoToPageOnClick,
  } = useCustomPagination(revisionsData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showMultilingual, setShowMultilingual] = useState(false);

  const handleOpenPoper = (e, revID) => {
    setAnchorEl(e.currentTarget);
    setShowMultilingual(showMultilingual === revID ? false : revID);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledRow>
            {TableHeadMap.map((td, idx) => (
              <TableCell key={idx}>{td}</TableCell>
            ))}
          </StyledRow>
        </TableHead>
        <TableBody>
          {revisionsData?.slice(sliceArgFirst, sliceArgSecond).map((revision) => (
            <TableRow key={revision?.id} sx={{ "& td, & th": { border: 0 } }}>
              <TableCellMax165 title={revision?.postContent.key} component="th" scope="row">
                {revision?.postContent?.status === "deleted" ? (
                  <TurnLeftIcon sx={{ color: "#f44236" }} />
                ) : (
                  revision?.postContent?.key
                )}
              </TableCellMax165>

              <TableCell>
                <StyledRevisionPopper
                  open={showMultilingual === revision?.id}
                  anchorEl={anchorEl}
                  placement="top"
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        {Object.entries(revision?.postContent?.multilingual).map(([key, value], idx) => {
                          const lastKey = Object.keys(revision?.postContent?.multilingual)?.length - 1 === idx;
                          const paddingValue = lastKey ? "8px 16px 16px" : idx === 0 ? "16px 16px 8px" : "8px 16px 8px";
                          return (
                            <Typography
                              sx={{ padding: paddingValue }}
                              key={`${key}-${idx}`}
                            >{`${key}: ${value}`}</Typography>
                          );
                        })}
                      </Paper>
                    </Fade>
                  )}
                </StyledRevisionPopper>
                <StyledRevisionTitle
                  onClick={(e) => handleOpenPoper(e, revision?.id)}
                  title={Object.values(revision?.postContent?.multilingual)[0]}
                >
                  {Object.values(revision?.postContent?.multilingual)[0]?.length >= 25
                    ? Object.values(revision?.postContent?.multilingual)[0].substring(0, 25) + "..."
                    : Object.values(revision?.postContent?.multilingual)[0]}
                </StyledRevisionTitle>
              </TableCell>
              <TableCellMax165>{dayjs(revision?.updated_at).format("DD MMM YYYY HH:mm")}</TableCellMax165>
              <TableCellMax165 title={revision?.postContent?.updatedByClient}>
                {revision?.postContent?.updatedByClient ? revision.postContent?.updatedByClient : "No data"}
              </TableCellMax165>
              <TableCell title="revert" onClick={onRevert.bind(null, revision?.postContent)}>
                <RevisioRevert />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomPagination
        type="full"
        data={revisionsData}
        page={page}
        rowsPerPage={rowsPerPage}
        goToPageInput={goToPageInput}
        paginationPageOptions={paginationPageOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePagination={handleChangePagination}
        handleKeyDown={handleKeyDown}
        handleGoToPageOnEnter={handleGoToPageOnEnter}
        handleGoToPageOnClick={handleGoToPageOnClick}
      />
    </TableContainer>
  );
};

export default RevisionTable;
