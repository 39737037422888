import React, { useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { dataPerPage, orderPages } from "./orderHelpers";
import {
  GalleryWrapp,
  StyledOrderContainer,
  StyledSearchContainer,
  StyledSearchIcon,
  StyledSelectAll,
  StyledTextField,
} from "./StyledOrderModal";
import NoImage from "../../../assets/images/noImage.png";
import useMediumPagination from "../../../custom_hooks/useMediumPagination";
import DialogContainer from "../../Dialog/Index";
import MediumPagination from "../../Pagination/MediumPagination";

export default function OrderModal({ isOpen, onClose, cb }) {
  const page = useSelector((state) => state?.layout?.formPageType?.page);
  const reducerPath = useSelector((state) => state?.modal?.modal?.pageFrom?.reducerPath);
  const pageData = useSelector((state) => state?.form?.pageData);
  const orderCategory = useSelector((state) => state?.form?.orderCategory);
  const [selectedData, setSelectedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [allSelected, setAllSelected] = useState(false);

  const dataFiltered = () => {
    // Here we map it and check if allPageData instance with mapped value is same as forcheck value and return new array
    // returned array is all elements that have category which is filtered by
    let filteredData = orderPages[page]?.map((category) => {
      return dataPerPage(pageData, category, page, orderCategory);
    });
    if (orderCategory === "all" && page !== "payment-methods-orders") {
      filteredData = pageData?.all?.filter((el) =>
        page === "game-orders"
          ? Boolean(el.available)
          : el?.publishing?.status === "published" &&
            (el?.publishing?.expireDate
              ? !dayjs().isAfter(dayjs(el?.publishing?.expireDate).format("YYYY-MM-DD"))
              : true)
      );
    }
    return filteredData?.flat() ?? [];
  };

  const data = dataFiltered();

  const {
    curentPage,
    setCurentPage,
    sliceArgFirst,
    sliceArgSecond,
    handleGoToPageOnEnter,
    handleKeyDown,
    goToPageInput,
  } = useMediumPagination(10, Math.round(data?.length / 10));

  const handleSelectData = (item) => {
    const isSelected = selectedData?.includes(item);
    const filteredData = isSelected ? selectedData?.filter((el) => el !== item) : [...selectedData, item];
    setSelectedData(filteredData);
  };

  const getCardImage = (el) => {
    if (page !== "payment-methods-orders" && page !== "game-orders") {
      return el?.multilingual[0]?.imageThumbnail?.url || el?.multilingual[0]?.imageFeatured?.url;
    } else {
      return el?.desktopThumbnail?.url || el?.mobileThumbnail?.url || el?.logo?.url;
    }
  };

  const renderDataItemBox = (el, idx) => {
    const isSelected = selectedData?.includes(el?.[reducerPath?.orderBy]);

    return (
      <div className="item" key={`order-item-${idx}`} onClick={() => handleSelectData(el[reducerPath?.orderBy])}>
        <div className="item-wrapp">
          <img src={getCardImage(el) || NoImage} alt="" />
          <p title={el?.[reducerPath?.searchBy[0]]}>{el?.[reducerPath?.searchBy[0]]?.slice(0, 12) + "..."}</p>
          <div className={`check ${isSelected ? "active" : ""}`}>
            <span>{!isSelected ? null : <DoneIcon />}</span>
          </div>
        </div>
      </div>
    );
  };

  const submitHandler = () => {
    cb(selectedData);
    onClose();
  };

  const renderContent = () => {
    let dataToShow = data;

    if (searchValue) {
      dataToShow = data?.filter((obj) => {
        let newObj = "";
        for (const key of reducerPath?.searchBy) {
          if (obj[key]?.toLowerCase()?.includes(searchValue?.toLowerCase())) newObj = obj;
        }
        return newObj;
      });
    }

    return dataToShow.slice(sliceArgFirst, sliceArgSecond).map((el, idx) => {
      return renderDataItemBox(el, idx);
    });
  };

  const handleAllSelect = () => {
    const isSelected = !allSelected;
    setAllSelected(isSelected);
    setSelectedData(isSelected ? data?.map((el) => el[reducerPath?.orderBy]) : []);
  };

  return (
    <DialogContainer
      isOpen={isOpen}
      label={`Search ${reducerPath?.title}`}
      actionLabel="Choose"
      onAction={() => submitHandler()}
      onClose={onClose}
      height={"95%"}
    >
      <StyledOrderContainer>
        <StyledSearchContainer>
          <StyledSearchIcon className="search-icon" />
          <StyledTextField
            id={`search-${reducerPath?.title?.toLowerCase()}`}
            label={`Search ${reducerPath?.title}`}
            type="search"
            variant="standard"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </StyledSearchContainer>
        {orderCategory !== "all" && (
          <StyledSelectAll>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={allSelected}
                    checked={!!allSelected}
                    defaultChecked={false}
                    onChange={handleAllSelect}
                  />
                }
                label="Select All"
              />
            </FormGroup>
          </StyledSelectAll>
        )}
        <GalleryWrapp>
          {data?.length ? renderContent() : <div className="no-result">There is no data for selected category</div>}
          <MediumPagination
            data={data}
            inputValue={goToPageInput.inputValue}
            isInputValid={goToPageInput.isValid}
            itemsPerPage={10}
            curentPage={curentPage}
            setCurentPage={setCurentPage}
            handleGoToPageOnEnter={handleGoToPageOnEnter}
            handleKeyDown={handleKeyDown}
          />
        </GalleryWrapp>
      </StyledOrderContainer>
    </DialogContainer>
  );
}
