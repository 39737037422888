import React from "react";

import { Grid } from "@material-ui/core";
import { Chip, CircularProgress, Divider } from "@mui/material";
import { useSelector } from "react-redux";

import CustomAccordion from "../../../CustomAccordion";
import PreviewFieldItem from "./PreviewFieldItem";

export default function Multilingual() {
  const {
    modal: { previewData },
    settings: { portalSettings },
  } = useSelector((state) => state);
  const fieldsToIgnore = ["id", "portal", "status", "allowed", "language"];

  const generateFieldLabel = (itemKey) => {
    // converts first letter to uppercase
    const uppresCaseKey = itemKey?.charAt(0).toUpperCase() + itemKey?.slice(1);
    // adds space before each capital letter
    return uppresCaseKey?.replace(/([A-Z])/g, " $1").trim();
  };

  const generateTextItemField = (data, arrayLength) => {
    return Object.entries(data)?.map(([key, val], idx) => {
      if (fieldsToIgnore.includes(key)) return null;
      // generating a default value for the field if it is empty (null/undefined/false/'')
      const itemValue = !val ? `There is no value defined` : val;
      // checks if next elemnt in data obj is an array or SEO, if yes
      const checkIfIsArrayOrSEO =
        (Array.isArray(Object.values(data)[idx + 1]) && Object.values(data)[idx + 1]?.length > 0) ||
        Object.keys(data)[idx + 1] === "seo";
      // checks if element is array and is not empty, or if it is SEO object
      if ((Array.isArray(val) && val?.length > 0) || key === "seo") {
        return (
          <Grid item xs={12} key={idx}>
            <Divider sx={{ margin: "8px 0 24px" }}>
              <Chip label={generateFieldLabel(key)} />
            </Divider>
            <Grid container>{generateTextItemField(itemValue, Object.keys(val).length)}</Grid>
            {/* removes bottom divider if next element is and array element or SEO */}
            {!checkIfIsArrayOrSEO && <Divider sx={{ margin: "8px 0 24px" }} />}
          </Grid>
        );
        // if accrosed property is an object, then we need to go deeper, and loop through it again
      } else if (val === Object(val) && key !== "geoFilter" && !val.hasOwnProperty("previewUrl")) {
        return generateTextItemField(itemValue, Object.keys(val).length);
      }

      return (
        <>
          <PreviewFieldItem itemKey={key} itemLabel={generateFieldLabel(key)} itemValue={itemValue} />
          {/* adding dashed divider after every last propery from array of objects */}
          {arrayLength && idx === arrayLength - 1 && <Grid item xs={12} className="preview-last-item-divider"></Grid>}
        </>
      );
    });
  };

  const generateLanguageAccordion = () => {
    const multilingualData = previewData?.multilingual
      ? previewData?.multilingual
      : previewData?.data[0]?.postContent?.multilingual;
    return multilingualData?.map((item, idx) => {
      const languageData = portalSettings?.general?.languages?.find((lang) => lang.code === item.language);
      const accordionTile = (
        <>
          <img src={languageData?.flag?.url} alt="" />
          {languageData?.name}
        </>
      );

      return (
        <CustomAccordion title={accordionTile} defOpen={false} customClass="preview-modal-acc" key={`multiling-${idx}`}>
          <Grid container>{generateTextItemField(item, false)}</Grid>
        </CustomAccordion>
      );
    });
  };

  return <Grid container>{!previewData ? <CircularProgress /> : generateLanguageAccordion()}</Grid>;
}
