import React from "react";

import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CircularProgressWrap = styled("div")(({ isNonPage }) => ({
  width: "100%",
  height: isNonPage ? "unset" : "calc(100vh - 70px)",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexWrap: "wrap",
}));

const CenteredCircularProgress = (isNonPage) => {
  return (
    <CircularProgressWrap isNonPage={isNonPage}>
      <CircularProgress />
    </CircularProgressWrap>
  );
};

export default CenteredCircularProgress;
