import React from "react";

import { FormControl } from "@mui/material";

import { StyledLabel, StyledMenuItem, StyledSelect } from "../styled";

const SelectLanguage = ({ formdata, handleChange, isDisabled }) => {
  return (
    <div className="modal-input-wrapp">
      <FormControl sx={{ width: "calc(100% / 2)" }}>
        <StyledLabel>{formdata?.language?.config?.label}</StyledLabel>
        <StyledSelect className="select" onChange={handleChange} value={formdata?.language.value} disabled={isDisabled}>
          {formdata?.language?.config?.options?.map((option, key) => {
            return (
              <StyledMenuItem className="option" key={key} name={option.label} value={option.value}>
                {option.label}
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default SelectLanguage;
