import React from "react";

import { Box, LinearProgress, Typography } from "@mui/material";

const ProgressBar = ({ value, width }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: width || "100%" }} className="progress-bar-mui">
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
