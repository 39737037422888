import React, { useState } from "react";

import { Switch } from "@mui/material";

import CenteredCircularProgress from "../../../CenteredCircularProgress";
import { transformCamelCaseToReadable } from "../../../Utils/helpers";

function ComponentOptions({ options }) {
  const allOptions = createOptionObj(Object.entries(options));
  const simpleOptions = processOptions(Object.entries(allOptions), (option) => option?.hasOwnProperty("simpleOption"));
  const advancedOptions = processOptions(
    Object.entries(allOptions),
    (option) => !option?.hasOwnProperty("simpleOption")
  );

  const [switchState, setSwitchState] = useState({
    loading: false,
    checked: false,
    disabled: false,
    options: simpleOptions,
  });

  const simplyfyOptions = () => {
    setSwitchState((prevState) => ({
      ...prevState,
      loading: true,
      checked: !prevState.checked,
      disabled: true,
    }));

    setTimeout(() => {
      setSwitchState((prevState) => ({
        ...prevState,
        loading: false,
        disabled: false,
        options: prevState.checked ? advancedOptions : simpleOptions,
      }));
    }, 500);
  };

  function processOptions(componentOptions, filterCondition) {
    if (!Array.isArray(componentOptions)) return;

    return componentOptions.reduce((acc, [key, val]) => {
      if (filterCondition(val[4])) {
        acc[key] = val;
      }
      return acc;
    }, {});
  }

  function createOptionObj(componentOptions) {
    return (
      Array.isArray(componentOptions) &&
      componentOptions?.reduce((acc, [key, val]) => {
        if (val[4]?.hasOwnProperty("firstElement")) {
          const ifResult = createOptionObj(Object?.entries(val[4]?.firstElement));
          acc = { ...acc, ...ifResult };
        } else {
          acc[key] = val;
        }
        return acc;
      }, {})
    );
  }

  return (
    <div className="cms-options">
      <div className="options-switch">
        <h2>Options</h2>
        <div className="switch-div">
          <p>Simple</p>
          <Switch
            checked={switchState?.checked}
            disabled={switchState?.disabled}
            onChange={() => simplyfyOptions()}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p>Advanced</p>
        </div>
      </div>
      <div className={`${switchState?.loading ? "loading-all-options" : "all-options"} `}>
        {switchState?.loading ? (
          <CenteredCircularProgress />
        ) : (
          Object.entries(switchState?.options)?.map(([key, val]) => {
            const componentDescription = val[4]?.hasOwnProperty("description") && val[4]?.description;

            return (
              <div className="option" key={key}>
                <div className="option-name">{transformCamelCaseToReadable(key)}</div>
                <p className="option-desc">{componentDescription}</p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default ComponentOptions;
