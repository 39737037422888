import React from "react";

import { Grid } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import CustomAccordion from "../../../CustomAccordion";
import PreviewFieldItem from "./PreviewFieldItem";

export default function GeneralInfo() {
  const {
    modal: { previewData },
  } = useSelector((state) => state);

  const fieldsToIgnore = [
    "id",
    "multilingual",
    "portal",
    "status",
    "allowed",
    "created_at",
    "updated_at",
    "created_by",
    "updated_by",
  ];
  const accordionFields = ["dynamicFields", "dynamicFiles"];

  const generateFieldLabel = (itemKey) => {
    // converts first letter to uppercase
    const uppresCaseKey = itemKey?.charAt(0).toUpperCase() + itemKey?.slice(1);
    // adds space before each capital letter
    return uppresCaseKey?.replace(/([A-Z])/g, " $1").trim();
  };

  const generateTextItemField = (data, isPublishing) => {
    return Object.entries(data)?.map(([key, val], idx) => {
      if (fieldsToIgnore.includes(key) && !isPublishing) return null;
      // generating a default value for the field if it is empty (null/undefined/false/'')
      const itemValue = !val ? `Not defined` : val;
      // if accrosed property is an array, then we need to loop through it and generate a new TextItem for each item inside accordion element
      if (accordionFields.includes(key) && val?.length) {
        return (
          <CustomAccordion key={idx} title={generateFieldLabel(key)} defOpen={false} customClass="preview-modal-acc">
            <Grid container>{generateTextItemField(itemValue, false)}</Grid>
          </CustomAccordion>
        );
        // if accrosed property is an object, then we need to go deeper, and loop through it again
      } else if (val === Object(val) && key !== "geoFilter" && !val.hasOwnProperty("previewUrl")) {
        // this is important just for UX, because if we have a maintenance object, just start and end labels are not intuitive,
        // so we need to transform it to have a maintenance prefix
        const isMaintanaceObj =
          key === "maintenance" ? { maintenanceStart: itemValue?.start, maintenanceEnd: itemValue?.end } : itemValue;
        return generateTextItemField(isMaintanaceObj, key === "publishing");
      }
      return <PreviewFieldItem itemKey={key} key={idx} itemLabel={generateFieldLabel(key)} itemValue={itemValue} />;
    });
  };

  return (
    <Grid container spacing={2}>
      {!previewData ? <CircularProgress /> : generateTextItemField(previewData, false)}
    </Grid>
  );
}
