import React, { useState, useRef } from "react";

import { FormGroup, FormLabel } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";

import { StyledEditor, StyledSwitch, StyledCodeEditor } from "./StyledEditor";
import { addNewImage } from "../../../../../Redux/slices/mediaSlice";

// Menu list for Component button in Editor
const RenderComponentMenu = (editor) => {
  const componentItems = [{ value: "BudgetCalculator", label: "Budget Calculator" }];
  return componentItems.map((item) => {
    return {
      type: "menuitem",
      text: item.label,
      onAction: () => {
        editor.insertContent(`<component>${item.value}</component>`);
      },
    };
  });
};

const CustomEditor = (props) => {
  const { value, handleBlur, isInvalid, disabled, height, isDescriptorEditor } = props;
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const toggleFullscreen = (editor) => {
    // there is going that many elements as many as the number of editors on the page
    const aux = document.getElementsByClassName("tox-tinymce-aux");
    const container = document.getElementById(editorRef.current.id + "_ifr").closest(".tox-tinymce");

    if (container) {
      if (isDocumentFullscreen()) {
        exitFullscreen();
        // looping through the array of aux elements and appending them to the body
        // since when not in fullscreen body needs to be parent to aux element
        for (const item of Array.from(aux)) {
          document.body.appendChild(item);
        }
      } else {
        container.requestFullscreen();
        // looping through the array of aux elements and appending them to the editor container
        // since when in fullscreen editor container needs to be parent to aux element
        // in order for the dropdowns,modals and similar elements to be visible
        for (const item of aux) {
          container.appendChild(item);
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      if (isDocumentFullscreen()) {
        exitFullscreen();
      }
    }
  };

  const exitFullscreen = () => {
    if (isDocumentFullscreen()) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const isDocumentFullscreen = () => {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  };

  return (
    <div
      className={`editor ${disabled ? "input-disabled" : ""}`}
      style={{
        border: isInvalid ? "1px solid red" : "none",
        borderRadius: isInvalid ? "10px" : "none",
        zIndex: 0,
      }}
    >
      <Editor
        value={value}
        apiKey={process.env.REACT_APP_TINY_EDITOR_API}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.ui.registry.addButton("toggleFullscreen", {
            text: "Fullscreen",
            onAction: () => toggleFullscreen(editor),
            icon: "fullscreen",
          });
          document.addEventListener("keydown", handleKeyDown);
          editorRef.current.on("remove", () => {
            document.removeEventListener("keydown", handleKeyDown);
          });

          // additing aux elements to the editor container
          // only if editor is used as a payment descriptor editor which is rendering in a modal
          if (isDescriptorEditor) {
            const aux = document.getElementsByClassName("tox-tinymce-aux");
            const container = document.getElementById(editor.id + "_ifr").closest(".tox-tinymce");
            for (const item of aux) {
              container.appendChild(item);
            }
          }
        }}
        init={{
          height,
          menubar: true,
          selector: "textarea",
          resize: false,
          // automatic_uploads: false,
          block_unsupported_drop: false,
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars image imagetools link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion fullscreen",
          toolbar1: "customComponentBtn  formatpainter | undo redo code | toggleFullscreen",
          toolbar2: `blocks fontsize | bold italic strikethrough underline subscript superscript | forecolor backcolor | alignleft aligncenter alignright | bullist numlist outdent indent | ${
            isDescriptorEditor ? "" : "table | link | image | blockQuote"
          }`,
          imagetools_toolbar: "imageoptions",
          contextmenu: "link, linkchecker , image, permanentpen, table, and spellchecker",
          relative_urls: 0,
          toolbar_mode: "sliding",
          images_file_types: "jpg,png,svg,webp",
          images_upload_handler: function (blobInfo) {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append("files", blobInfo.blob());
              formData.append("folder", `/${localStorage.getItem("portal")}/editor/`);

              dispatch(addNewImage(formData))
                .then((res) => {
                  const file = res?.payload[0];
                  resolve(file.url);
                })
                .catch((err) => {
                  console.error(err);
                  reject("There has been an error, please contact support.");
                });
            });
          },
          // Custom button definitions
          setup: (editor) => {
            editor.ui.registry.addMenuButton("customComponentBtn", {
              text: "Component",
              fetch: (callback) => {
                callback(RenderComponentMenu(editor));
              },
            });
          },
          // Custom elements definitions
          extended_valid_elements: "component",
          custom_elements: "component",
          // Custom CSS for tinyMCE
          content_style: `
              component { 
                color: white;
                font-weight: bold;
                background: #6225E6;
                padding: 10px;
                margin: 40px 0;
                box-shadow: 4px 4px 0 black;
              }
            `,
        }}
        data={value}
        onEditorChange={(e, editor) => {
          if (console.log.name === "disabledLog") return;
          const data = editor.getContent();
          props.onChange(data);
        }}
        onBlur={(event, editor) => {
          if (console.log.name === "disabledLog") return;
          handleBlur();
        }}
      />
    </div>
  );
};

export default function EditorField({
  formdata,
  id,
  handleBlur,
  handleChange,
  disabled,
  error,
  showError,
  elemPos,
  isDescriptorEditor = false,
}) {
  const [showCode, setShowCode] = useState(!!formdata.showCode);
  const [value, setValue] = useState(formdata?.value);

  const showEditorBtnClasses = ["show-editor-btn"];
  const showCodeBtnClasses = ["show-code-btn"];
  showCode && showCodeBtnClasses.push("show-code-btn--active");
  !showCode && showEditorBtnClasses.push("show-editor-btn--active");

  const onInputChange = (e) => {
    const editorValue = showCode ? e?.target?.value || "" : e;
    handleChange({ value: editorValue }, formdata?.element, formdata?.name);
  };

  return (
    <StyledEditor>
      <FormLabel>
        {formdata.label + (formdata.validation && formdata.validation.includes("required") ? "*" : "")}
        {formdata?.showCode ? (
          <StyledSwitch>
            <div
              className={showCodeBtnClasses.join(" ")}
              onClick={() => {
                setShowCode(true);
              }}
            >
              <span> Code View</span>
            </div>
            <div
              className={showEditorBtnClasses.join(" ")}
              onClick={() => {
                setShowCode(false);
              }}
            >
              <span>Editor View</span>
            </div>
          </StyledSwitch>
        ) : null}
      </FormLabel>
      {!showCode ? (
        <CustomEditor
          isInvalid={!!error}
          className={error ? "is-invalid" : ""}
          id={id}
          value={formdata?.value}
          onChange={(e) => onInputChange(e)}
          handleBlur={handleBlur}
          disabled={disabled || formdata.disabled}
          height={formdata?.height || 500}
          editorKey={elemPos ? `${id}-${elemPos}` : id}
          isDescriptorEditor={isDescriptorEditor}
        />
      ) : (
        <FormGroup className={error ? "is-invalid" : ""}>
          <StyledCodeEditor
            id={id}
            as="textarea"
            className={error ? "is-invalid" : ""}
            {...formdata}
            rows="1"
            value={formdata?.value}
            onBlur={handleBlur}
            onChange={(e) => onInputChange(e)}
            disabled={(disabled && disabled) || formdata.disabled}
            style={{ zIndex: 0 }}
          />
        </FormGroup>
      )}
      <p className="error-wrap">{showError()}</p>
    </StyledEditor>
  );
}
