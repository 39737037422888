import React from "react";

import {
  ErrorWrap,
  GoToPageWrap,
  PaginationTextField,
  StyledPagination,
  StyledPaginationWrap,
  SyledArrowForwardIosIcon,
} from "../StyledPagination";

// Type of pagination:
// medium -> if type if false, medium type is default
// basic -> basic pagination (the number of pages only)

// styleType prop determinants style of pagination according to design

const MediumPagination = ({
  data,
  type,
  styleType,
  hideNextButton,
  hidePrevButton,
  backgroundColor,
  itemsPerPage,
  curentPage,
  setCurentPage,
  handleKeyDown,
  handleGoToPageOnEnter,
  isInputValid,
  inputValue,
}) => {
  const handleGoToPage = (input) => (!input ? setCurentPage(1) : setCurentPage(input));

  return (
    <StyledPaginationWrap type={type} backgroundColor={backgroundColor} padding="0">
      {type === "basic" ? null : <div></div>}
      <StyledPagination
        styletype={styleType}
        hideNextButton={hideNextButton === false ? hideNextButton : true}
        hidePrevButton={hidePrevButton === false ? hideNextButton : true}
        page={parseInt(curentPage)}
        onChange={(e, value) => setCurentPage(value)}
        count={Math.ceil(data?.length / itemsPerPage)}
      />
      {type === "basic" ? null : (
        <GoToPageWrap>
          <p style={{ margin: 0 }}>Go to page:</p>
          <div style={{ position: "relative" }}>
            {!isInputValid && <ErrorWrap>Invalid</ErrorWrap>}
            <PaginationTextField
              onChange={handleGoToPageOnEnter}
              onKeyDown={handleKeyDown}
              label=""
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
            />
          </div>
          <SyledArrowForwardIosIcon onClick={() => handleGoToPage(inputValue)} />
        </GoToPageWrap>
      )}
    </StyledPaginationWrap>
  );
};

export default MediumPagination;
