import dayjs from "dayjs";
import { fromJS, isKeyed } from "immutable";

import breakpoints from "../../hoc/theme/breakpoints";

export function fromJSOrdered(jsData) {
  return fromJS(jsData, function (key, value, path) {
    return isKeyed(value) ? value.toOrderedMap() : value.toList();
  });
}

export function deepClone(jsData) {
  return fromJSOrdered(jsData).toJS();
}

export function deepObjectCompare(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepObjectCompare(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}
/**
 * Checks if all properties of an object are null.
 * @param {Object} obj - The object to check.
 * @returns {boolean} - True if all properties are null, false otherwise.
 */
export function arePropertiesNull(obj) {
  if (!obj) {
    return true;
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null) {
        return false;
      }
    }
  }
  return true;
}
export function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export const mapActiveTabs = (data, page, allLangs) => {
  const activeTabs = [];

  if (page && page === "provider") {
    allLangs.map((item) => {
      return data.hasOwnProperty(item.code) ? activeTabs.push({ name: item.code }) : [];
    });
  } else if (page && page === "payment-methods-orders") {
    /*eslint-disable */
    data &&
      data.countryOrders?.map((el) => {
        return activeTabs.push({ name: el.country });
      });
    /* eslint-enable */
  } else if (page && page === "payment-methods") {
    /* eslint-enable */
    data &&
      data.multicurrency.map((el) => {
        return activeTabs.push({ name: el.currency });
      });
    /* eslint-enable */
  } else {
    /*eslint-disable */
    data &&
      data.multilingual?.map((item) => {
        return activeTabs.push({ name: item["language"].toLowerCase() });
      });
    /* eslint-enable */
  }

  return activeTabs;
};

export const mapLanguageTabs = (activeTabs, langTabs, isPaymentMethod) => {
  const alreadyActive = activeTabs?.map((lang) => lang.name);
  let tempLangs = langTabs && langTabs?.slice(0);

  if (isPaymentMethod)
    tempLangs = tempLangs?.map((el) => {
      return { name: el?.label };
    });

  const filteredAllLangs = tempLangs?.filter((el) => {
    return !alreadyActive?.includes(el.name);
  });

  return filteredAllLangs;
};

export const getAccordionTitle = (form, defaultTitle) => {
  const titleValidFields = ["slug", "key", "title", "heading", "name", "link", "paletteName"];
  const validField = titleValidFields?.find((field) => form?.[field] && form?.[field]?.value);
  if (validField) {
    return form?.[validField]?.value;
  }
  return defaultTitle;
};

export const groupBy = (keys) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map((key) => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const orderDefaultButtons = (btnArr) => {
  const orderedBtnsObj = [[], []];
  const defaultButtonSlugs = ["primary-button", "secondary-button", "tertiary-button", "icon-button"];

  // Add buttons to the new array in the specified order
  btnArr.forEach((val) => {
    const arrIdx = defaultButtonSlugs.includes(val.slug) ? 0 : 1;
    if (arrIdx === 0) {
      // adding object to the first array ordered by defaultButtonSlugs array
      const findIndex = defaultButtonSlugs?.indexOf(val.slug);
      orderedBtnsObj[arrIdx].splice(findIndex, 0, val);
    } else {
      // adding custom buttons to the second array
      orderedBtnsObj[arrIdx].push(val);
    }
  });

  // returns 2D array
  return orderedBtnsObj;
};

function orderColors(colorObj, slugOrder) {
  const orderedColorsObj = [];
  // Add colors to the new array in the specified order
  slugOrder.map((slug, idx) => {
    return (
      colorObj &&
      Object.entries(colorObj)?.forEach(([key, val]) => {
        if (val.value === slug) {
          orderedColorsObj[idx] = val;
        }
      })
    );
  });
  return orderedColorsObj;
}

export const generateColorsArray = (colorsArr, isOrdered) => {
  // creating array of colors to be used in color select,
  // and filtering array from color value is null
  // slugs for ordering colors
  const colorSlugsV2 = [
    "primaryKeyColor",
    "primary",
    "primaryOn",
    "primaryContainer",
    "primaryOnContainer",
    "secondaryKeyColor",
    "secondary",
    "secondaryOn",
    "secondaryContainer",
    "secondaryOnContainer",
    "tertiaryKeyColor",
    "tertiary",
    "tertiaryOn",
    "tertiaryContainer",
    "tertiaryOnContainer",
    "neutralKeyColorOne",
    "neutralKeyColorTwo",
    "surface",
    "surfaceDim",
    "surfaceLight",
    "surfaceOn",
    "surfaceVariantOn",
    "outline",
    "outlineVariant",
    "surfaceColorOne",
    "surfaceColorTwo",
    "surfaceColorThree",
    "surfaceColorFour",
    "surfaceColorFive",
    "errorKeyColor",
    "error",
    "errorOn",
    "errorContainer",
    "errorOnContainer",
    "customColorOneKey",
    "customColorOne",
    "customColorOneOn",
    "customColorOneContainer",
    "customColorOneOnContainer",
    "customColorTwoKey",
    "customColorTwo",
    "customColorTwoOn",
    "customColorTwoContainer",
    "customColorTwoOnContainer",
    "customColorThreeKey",
    "customColorThree",
    "customColorThreeOn",
    "customColorThreeContainer",
    "customColorThreeOnContainer",
  ];
  const portalColors =
    colorsArr &&
    Object.entries(colorsArr)
      .map(([key, val]) => {
        let colorObj = null;
        if (key !== "id") {
          // using key to create a color label. Adding space between capital letters,
          // and making a first letter of label uppercase
          const createColorLabel = key.replace(/([A-Z])/g, " $1").trim();
          colorObj = {
            label: createColorLabel.charAt(0).toUpperCase() + createColorLabel.slice(1),
            value: key,
            color: val,
          };
        }
        return colorObj;
      })
      .filter((el) => el && el.color !== null);

  const orderedColors = isOrdered ? orderColors(portalColors, colorSlugsV2) : portalColors;

  return orderedColors;
};

export const filterQueuedAndExpired = (tableData, value) => {
  if (value === "scheduled") {
    return tableData?.filter((el) => dayjs(el.publishDate).diff(dayjs()) > 0);
  } else if (value === "expired") {
    return tableData?.filter((el) => dayjs(el.expireDate).diff(dayjs()) < 0);
  } else {
    return tableData?.filter((el) =>
      el?.created
        ? el
        : ((!el.publishDate && !el.expireDate) || dayjs(el.publishDate).diff(dayjs()) <= 0) &&
          (el.expireDate ? dayjs(el.expireDate).diff(dayjs()) >= 0 : 1)
    );
  }
};

// used for tooltips in ColorPaletteField and SwitchInput (for now)
export const desktopSize = breakpoints?.values.desktop;
export const isLengthLongerThanNumber = (label, number) => label.length > number;

export const mapGameCategories = (data, forSelect) => {
  const gameCategoriesData = data?.form?.pageData?.categories || {};

  const extraCategories = Object.values(gameCategoriesData) || [];

  // without label
  const filteredCategories = [...extraCategories]
    // removing tags as a gameCategory from list
    .filter((el) => el.type !== "tags")
    // with label
    // const filteredCategories = [{label: "Type", categories: [...metaTypes]}, {label: "Category", categories: [...metaCategories]}]
    .map((category) => {
      if (category.multilingual) {
        if (forSelect) {
          return { id: category?.slug, slug: category?.multilingual[0]?.title };
        }
        return { label: category?.multilingual[0]?.title, value: category?.slug, key: category?.slug };
      }
      if (forSelect) {
        return { id: category?.slug, slug: category?.title };
      }
      return { label: category?.title, value: category?.slug, key: category?.slug };
    });

  return filteredCategories;
};

export const transformCamelCaseToReadable = (inputString) => {
  // Use a regular expression to split the string at capital letters
  const words = inputString.split(/(?=[A-Z])/);

  // Capitalize the first letter of the first word and join the rest with a space
  const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  const restOfWords = words
    .map((el) => el.toLowerCase())
    .slice(1)
    .join(" ");

  return firstWord + " " + restOfWords;
};

/**
 * Generates an array of Drag and Drop values from a configuration object.
 *
 * @param {Object} config - The configuration object.
 * @returns {Array} - The array of Drag and Drop values.
 */
export const generateArrayOfDnDValues = (config) => {
  if (!config) return [];

  const result = [];
  const processObject = (obj, prefix = "") => {
    for (const [key, value] of Object.entries(obj)) {
      if (value && typeof value === "object") {
        if (value.element === "game-dnd-order") {
          result.push({
            value: value.value ? value.value + "-" + value?.name : "",
            label: value?.label,
            key: value?.name,
            lang: prefix || "",
          });
        } else {
          processObject(value, prefix + key);
        }
      }
    }
  };

  processObject(config);
  // Remove duplicates and empty values
  const filtered = result?.filter((el, idx, arr) => {
    return el?.value && arr?.findIndex((item) => item?.value === el?.value) === idx;
  });

  return filtered;
};

/**
 * @description Sort array of of objects based on ordered array of ids that is sent as idsArray.
 * @param {Array} idsArray - array of ids to sort objects array.
 * @param {Array} objectsArray - array of objects that needs to be sorted based on ids array each object must containt property id
 * @returns {Array} - sorted array of objects
 */

export const sortObjectsByIds = (idsArray, objectsArray) => {
  objectsArray.sort((a, b) => {
    const indexOfA = idsArray.indexOf(a.id);
    const indexOfB = idsArray.indexOf(b.id);

    if (indexOfA > indexOfB) {
      return 1;
    } else if (indexOfA < indexOfB) {
      return -1;
    } else {
      return 0;
    }
  });

  return objectsArray;
};

export const valuesArr = (obj) => {
  return Boolean(obj) && Object?.values(obj)?.filter(Boolean);
};

/**
 * @description - Compares values from an item object against a set of filter criteria to determine if the object
 * matches all the filter values. It processes the item object to handle string values that contain
 * commas by splitting them into multiple elements. The function then checks if every value from the
 * filter object is included in the processed values of the item object.
 *
 * @param {Object} itemObject - The object containing values to be compared against filter criteria.
 * @param {Object} filters - An object where each value is a filter criterion to be matched.
 * @returns {boolean} - Returns `true` if all filter criteria are found within the item object's
 *                      processed values, otherwise returns `false`.
 */
export function objectIncludeFilterValues(itemObject, filters) {
  const valuesToCheck = stringValuesArray(itemObject);
  const filterValues = Object.values(filters)?.filter(Boolean);
  const objectContainFilter = filterValues?.every((filter) => valuesToCheck?.includes(filter));
  return Boolean(objectContainFilter);
}

/**
 * @description - Processes an object's properties to return an array of values. If a property
 * value is a string containing commas, it splits the string into an array of
 * trimmed strings, one for each comma-separated segment. Non-string or string
 * values without commas are included as individual array elements.
 *
 * @param {Object} obj - The object whose properties are to be processed.
 * @returns {Array} - An array containing the split and trimmed strings or the
 *                    original values if they do not contain commas.
 */
export function stringValuesArray(obj) {
  const valuesArray = propertiesToCheck(obj);
  return valuesArray.flatMap((value) => {
    // Only process string values that contain a comma
    if (typeof value === "string" && value.includes(",")) {
      return value.split(",").map((el) => el.trim());
    } else {
      return [value];
    }
  });
}

/**
 * @description -The propertiesToCheck function is designed to extract values from an object based on a predefined list of property names.
 * @param {Object} obj - Object to check
 * @returns {Array} - Array of values
 */
export function propertiesToCheck(obj) {
  const objProperties = ["provider", "device", "extraCategories", "category", "subCategory", "type"];
  const valuesFromProperties = Object.entries(obj)?.reduce(
    (acc, [key, val]) => ({
      ...acc,
      ...(objProperties.includes(key) && val && { [key]: val }),
    }),
    {}
  );
  return valuesArr(valuesFromProperties);
}

/**
 * Removes HTML tags from a given input string.
 * @param {string} input - The input string containing HTML tags.
 * @returns {string} A string with HTML tags removed.
 */
export const removeHtmlTags = (input) => {
  return input?.replace(/<[^>]*>/g, "");
};

export const baseColumnConfig = {
  flex: 1,
  align: "left",
  headerAlign: "left",
};

export const createColumn = (field, headerName) => ({
  ...baseColumnConfig,
  field,
  headerName,
});
