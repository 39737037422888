import { useState } from "react";

import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import { addNewImage } from "../Redux/slices/mediaSlice";

const useDropzoneOverride = (uploadPath) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [activeDropzone, setActiveDropzone] = useState("");

  const imageMaxSize = 2097152;
  const acceptedFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif, image/svg, image/svg+xml, image/webp, application/json";
  const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
    return item.trim();
  });

  const [submit, setSubmit] = useState({
    depositLogo: {
      imageId: null,
      imageUrl: "",
    },
    depositLogoHover: {
      imageId: null,
      imageUrl: "",
    },
    withdrawLogo: {
      imageId: null,
      imageUrl: "",
    },
    withdrawLogoHover: {
      imageId: null,
      imageUrl: "",
    },
    iconOverride: {
      imageId: null,
      imageUrl: "",
    },
    iconOverrideHover: {
      imageId: null,
      imageUrl: "",
    },
  });

  const verifyFiles = (files) => {
    if (files && files.length > 0) {
      const { type: currentFileType, size: currentFileSize } = files[0];
      if (currentFileSize > imageMaxSize) {
        enqueueSnackbar("Image file is larger than 2MB, choose smaller file size!", { variant: "error" });
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        enqueueSnackbar("This file is not allowed. Only images are allowed.", { variant: "error" });
        return false;
      }
      return true;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: 20,
    multiple: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        verifyFiles(rejectedFiles);
      }
      if (acceptedFiles && acceptedFiles.length > 0) {
        const isVerified = verifyFiles(acceptedFiles);
        if (isVerified) {
          const data = new FormData();
          data.append("files", acceptedFiles[0]);
          data.append("folder", `/${localStorage.getItem("portal")}/${uploadPath}/`);
          setIsLoading(true);
          dispatch(addNewImage(data)).then((res) => {
            setIsLoading(false);
            if (res?.meta?.requestStatus === "fulfilled") {
              const payloadData = res?.payload[0];
              const imgName = payloadData?.name?.replace(`/${localStorage.getItem("portal")}/${uploadPath}/`, "");
              const uploadEvent = new CustomEvent("file_uploaded", { detail: { ...payloadData } });
              const isJSON = imgName.includes(".json");
              dispatchEvent(uploadEvent);
              enqueueSnackbar(`You have successfully added ${imgName} ${isJSON ? "file" : "image"}!`, {
                variant: "success",
              });
              setSubmit((prev) => ({
                ...prev,
                [activeDropzone]: {
                  imageId: payloadData?.id,
                  imageUrl: payloadData?.url,
                },
              }));
            } else {
              enqueueSnackbar(`There was an upload error, please try again!`, { variant: "error" });
            }
          });
        }
      }
    },
  });

  return { getRootProps, getInputProps, setActiveDropzone, submit, isLoading, activeDropzone };
};

export default useDropzoneOverride;
