import React from "react";

import { FormControl } from "@mui/material";

import { StyledLabel, StyledMenuItem, StyledSelect } from "../Styled";

const Select = ({ label, handleChange, selectedValue, options }) => {
  return (
    <FormControl sx={{ width: "calc(100% / 3)" }}>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect className="select" onChange={handleChange} value={selectedValue}>
        {options?.map((option, key) => {
          return (
            <StyledMenuItem className="option" key={key} name={option.label} value={option.value}>
              {option.label}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default Select;
