import React from "react";

import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSidebar = styled("div")(({ theme, isFullScreen }) => ({
  width: "264px",
  height: "100%",
  backgroundColor: theme.palette.primary.lighter,
  zIndex: isFullScreen ? 999 : 99999,
  position: "fixed",
  top: 0,
  left: 0,
  transition: "all 0.3s ease-in-out",
  "&.not-found-sidebar": {
    display: "none",
  },

  "&.collapse-sidebar": {
    width: "88px",
    transition: "all 0.3s ease-in-out",
    ".accordion-content": {
      position: "absolute",
      left: "122%",
      width: "200px",
      borderRadius: "10px",
    },
    [theme.breakpoints.down("tablet")]: {
      width: "264px",
      transition: "all 0.3s ease-in-out",
      ".accordion-content": {
        position: "unset",
        width: "100%",
        borderRadius: "unset",
      },
    },
  },
  [theme.breakpoints.down("tablet")]: {
    width: 0,
    transition: "all 0.3s ease-in-out",
  },
}));

export const StyledSidebarHeader = styled("div")(({ theme, collapsemenu }) => ({
  width: "100%",
  height: "180px",
  backgroundColor: theme.palette.primary.light,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  marginRight: 0,
  padding: "0 10px",
  transition: "all 0.3s ease-in-out",
  "&.collapse-sidebar-header": {
    ".header-title": {
      left: "-200% !important",
      opacity: "0 !important",
      transition: "all 0.3s ease-in-out",
    },
    ".sidebar-mobile": {
      display: "none",
    },
  },

  ".header-info": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    ".header-content": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 0 0 10px",
      position: "relative",
      ".logo": {
        borderRadius: "10px",
        width: "100%",
        height: "50px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        img: {
          width: "75%",
        },
      },
      ".header-title": {
        color: theme.palette.primary.main,
        fontSize: "18px",
        fontWeight: 100,
        position: "absolute",
        top: "50%",
        left: "70px",
        transform: "translateY(-50%)",
        opacity: 1,
        whiteSpace: "nowrap",
        transition: "all 0.3s ease-in-out",
      },
    },
    ".sidebar-mobile": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "37px",
      height: "70px",
      padding: "0 10px",
      transition: "all 2.8s ease-in-out",
      transform: "translateY(0)",
      color: theme.palette.primary.main,

      span: {
        transition: "all 0.3s ease-in-out",
        transform: "rotate(180deg)",
      },
      "&.on": {
        span: {
          transform: "translate(-20px, 290%)",
          transition: "all 0.3s ease-in-out",
        },
      },
    },
  },
  ".sidebar-portal": {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    padding: "10px",
    transform: collapsemenu === "true" ? "translateX(-300px)" : "translateX(0)",
    transition: "all 0.3s ease-in-out",

    img: {
      maxWidth: "130px",
      maxHeight: "50px",
      objectFit: "contain",
    },
  },
  [theme.breakpoints.down("tablet")]: {
    width: "100vw",
    height: "70px",
    backgroundColor: theme.palette.primary.light,
    ".sidebar-portal": {
      display: "none",
    },
    "&.collapse-sidebar-header": {
      ".header-title": {
        position: "fixed",
        left: "55px !important",
        opacity: "1 !important",
        transition: "all 0.3s ease-in-out",
      },
    },
    ".header-info": {
      justifyContent: "unset",
      ".header-title": {
        display: "none",
      },
      ".sidebar-mobile": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "37px",
        height: "70px",
        padding: "0 10px",
        transition: "all 2.8s ease-in-out",
        transform: "translateY(0)",

        span: {
          transition: "all 0.3s ease-in-out",
          transform: "rotate(0deg)",
        },
        "&.on": {
          span: {
            transform: "rotate(180deg)",
            transition: "all 0.3s ease-in-out",
          },
        },
      },
    },
  },
}));

export const StyledSidebarContent = styled("div")(({ theme, drpOpened, collapsemenu, position }) => ({
  width: "100%",
  height: "calc(100% - 160px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  listStyleType: "none",
  margin: 0,
  padding: "10px 0 20px 0",
  overflowY: "scroll",
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.lighter,
  "&::-webkit-scrollbar": {
    width: "0.1px",
  },
  ".item-tooltip": {
    color: theme.palette.primary.main,
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.light,
  },

  "&::-webkit-scrollbar-thumb:hover": {
    background: theme.palette.primary.light,
  },

  "&.collapse-sidebar-content": {
    zIndex: "-1",
    position: "relative",
    bottom: `${position}px`,
    overflow: drpOpened ? "unset" : "auto",
    [theme.breakpoints.down("phone")]: {
      overflow: "scroll",
      height: "92vh",
    },

    ".sidebar-collapse": {
      position: "relative",
    },

    ".item-title ": {
      rotate: "y 90deg",
      opacity: "0 !important",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transition: "all 0.1ms ease-in-out",
      },
    },
    [theme.breakpoints.down("tablet")]: {
      overflow: "scroll",
      height: "94vh",
      ".item-title": {
        rotate: "y 360deg",

        opacity: " 1 !important",
        transition: "all 1s ease-in-out",
        "&:hover": {
          transition: "all 0.1ms ease-in-out",
        },
      },
    },
  },
  ".list-icon": {
    fontSize: "1rem",
    padding: "4px 7px",
    marginRight: collapsemenu === "true" ? "" : "7px",
    borderRadius: "4px",
    width: "40px",
    display: "inline-flex",
    alignItems: "center",
    height: "40px",
    textAlign: "center",
  },

  a: {
    padding: "0px 20px",

    color: "#a9b7d0",
    "&:hover,&:active": {
      color: theme.palette.primary.main,
    },
  },

  [theme.breakpoints.down("tablet")]: {
    height: "100%",
    justifyContent: "unset",
    position: "relative",
    top: 0,
  },
}));

export const StyledSidebarCollapse = styled("ul")(({ theme, collapsemenu, isActive }) => ({
  display: "flex",
  flexDirection: "column",
  width: collapsemenu === "true" ? "unset" : "224px",
  listStyleType: "none",
  marginBottom: "8px",

  justifyContent: "center",
  padding: 0,
  transition: "all 0.3s ease-in-out",

  "&:nth-of-type(1)": {
    borderTopWidth: "1px",
  },
  [`&.${isActive}`]: {
    borderRadius: "10px",
    background: theme.palette.primary.light,
    svg: {
      path: {
        color: theme.palette.primary.main,
      },
    },
    ".item-title": {
      color: theme.palette.primary.main,
    },
  },
  ".accordion-title": {
    height: "40px",
    padding: "0px 20px",
    fontSize: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    transition: "all 0.3s ease-in-out",
    color: theme.palette.text.primary,
    ".item-title": {
      position: "absolute",
      top: "50%",
      left: "55px",
      transform: "translateY(-50%)",
      opacity: 1,
      whiteSpace: "nowrap",
      transition: "all 0.3s ease-in-out",
    },

    "&:hover": {
      transition: "none",
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    ".toggle": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      marginLeft: "auto",
      transition: "0.3s ease",
      svg: {
        width: 15,
        transition: "0.3s ease",
        transform: "rotate(180deg)",
      },
      "&.active": {
        svg: {
          transform: "rotate(270deg)",
        },
      },
    },
  },
  ".accordion-content": {
    maxHeight: "1000px",
    transition: "max-height 1s ease-in-out",
    // padding: "0 20px",
    overflow: "hidden",
    "&.top ": {
      top: 0,
    },
    "&.bottom": {
      bottom: 0,
    },
    ".acc-wrapp": {
      padding: collapsemenu === "true" ? " 0 0 16px 0" : "16px 0",
      ul: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        ".nav-link-desc": {
          cursor: "pointer",
          padding: 0,
          marginBottom: "5px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "0 0 10px 10px",
          ">svg": {
            width: 15,
          },
        },
        li: {
          padding: "0 20px",
          a: {
            display: "block",
            color: theme.palette.text.primary,
            padding: "7px 7px 7px 40px",
            position: "relative",
            textDecoration: "none",
            "&:hover, &.active": {
              color: theme.palette.primary.main,
              borderRadius: "10px",
              "&:before": {
                content: '""',
                borderRadius: "50%",
                position: "absolute",
                top: "15px",
                left: "10px",
                width: "8px",
                height: "8px",
                backgroundColor: theme.palette.primary.darker,
              },
            },
          },
        },
      },
    },
    "&.active": {
      maxHeight: "0px",
      transition: "max-height 0.5s cubic-bezier(0, 1, 0, 1)",
    },
  },
  "&.active": {
    borderLeft: collapsemenu === "true" ? "unset" : `2px solid ${theme.palette.primary.dark}`,
    height: "auto",
    borderRadius: "0 10px 10px 0",
    ".accordion-title": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      borderRadius: "10px",
    },
    ".accordion-content": {
      backgroundColor: theme.palette.primary.lighter,
    },
    ".nav-link-desc": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
  [theme.breakpoints.down("tablet")]: {
    width: "224px",
    ".accordion-content": {
      ".acc-wrapp": {
        padding: "16px 0",
      },
    },
  },
}));

export const StyledSidebarItem = styled("li")(({ theme, collapsemenu }) => ({
  width: collapsemenu === "true" ? "70%" : "224px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  ".nav-link": {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: collapsemenu === "true" ? "center" : "unset",
    color: theme.palette.text.primary,
    position: "relative",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transition: "none",
    },
    "&.active": {
      borderRadius: "10px",
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
  ".item-title": {
    position: "absolute",
    top: "50%",
    left: "55px",
    transform: "translateY(-50%)",
    opacity: 1,
    whiteSpace: "nowrap",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transition: "none",
    },
  },
  [theme.breakpoints.down("tablet")]: {
    width: "224px",
    ".nav-link": {
      justifyContent: "space-between",
    },
  },
}));

export const StyledSettings = styled("div")(({ theme, open, collapsemenu }) => ({
  position: "relative",
  color: theme.palette.primary.main,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: collapsemenu === "true" ? "10px 0" : 10,
  ".sidebar-settings": {
    cursor: "pointer",
    width: collapsemenu === "true" ? "30px" : "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: collapsemenu === "true" ? "center" : "space-between",
    transition: "all 0.3s ease-in",
    ".user-settings": {
      position: collapsemenu === "true" ? "absolute" : "unset",
    },
    ".arrow": {
      transition: "all 0.2s ease-in",
      width: 15,
      transform: open ? "rotate(0deg)" : "rotate(180deg)",
    },
    ".item-icon": {
      position: collapsemenu === "true" ? "absolute" : "unset",
    },
    ".username": {
      transition: "all 0.4s ease-in-out",
      transform: collapsemenu === "true" ? "translateX(-300%)" : "translateX(0)",
    },
  },
  ".settings-options": {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #d9e1ff",
    transform: open ? "translateX(0)" : "translateX(-300%)",
    opacity: open ? 1 : 0,
    justifyContent: "space-between",
    zIndex: 999999,
    position: "absolute",
    top: collapsemenu === "true" ? 0 : "38px",
    left: collapsemenu === "true" ? "83px" : "unset",
    borderRadius: "10px",
    width: collapsemenu === "true" ? "224px" : "95%",
    transition: "all 0.3s ease-in-out",
    height: "auto",
    background: "white",
    padding: "24px",
    ".role-portal": {
      flexDirection: "column",
      display: "flex",
      marginBottom: "20px",
      height: "60px",
      alignItems: " flex-start",
      justifyContent: "space-between",
      ".span": {
        color: theme.palette.text.primary,
        span: {
          fontWeight: 600,
        },
      },
    },
    ".theme-switch": {
      display: "flex",
      alignItems: "left",
      justifyContent: "space-between",
      flexDirection: "column",
      minHeight: "70px",
      marginBottom: 20,
      span: {
        color: "#8B8484",
      },
      ".themes": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",

        ".theme-box": {
          width: 35,
          height: 35,
          borderRadius: 8,
        },
        ".kiwi": {
          background: "linear-gradient(180deg, #76baad 0%, #76baad 50%, #e7f6f3 50%, #e7f6f3 100%)",
        },
        ".pineapple": {
          background: "linear-gradient(180deg, #F4EACF 0%, #F4EACF 50%, #F4C242 50%, #F4C242 100%)",
        },
        ".cherry": {
          background: "linear-gradient(180deg, #524266 0%, #524266 50%, #dcd9e0 50%, #dcd9e0 100%)",
        },
        ".blueberry": {
          background: "linear-gradient(180deg, #e6e0fd 0%, #e6e0fd 50%, #8366f6 50%, #8366f6 100%)",
        },
      },
    },
    ".settings-btn": {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "95px",
      button: {
        width: "100%",
        marginBottom: 10,
      },
    },
  },
  [theme.breakpoints.down("tablet")]: {
    background: theme.palette.primary.lighter,
    width: "25vw",
    padding: 10,
    borderRadius: "10px",
    position: "absolute",
    top: "12px",
    right: "10px",
    ".sidebar-settings": {
      width: "100%",
      justifyContent: "space-around",

      "svg:first-of-type": {
        transform: "unset",
        position: "unset",
      },

      svg: {
        transform: open ? "rotate(270deg)" : "rotate(90deg)",
      },
      ".username": {
        transform: "unset",
      },
      ".material-symbols-outlined": {
        position: "unset",
      },
    },
    ".settings-options": {
      background: theme.palette.primary.lighter,
      width: "100%",
      transform: open ? "translateY(0)" : "translateY(-300%)",
      top: "50px",
      left: "unset",
      padding: 21,
    },
  },
  [theme.breakpoints.down("phone")]: {
    width: "50%",
  },
}));

const ToBeStyledTooltip = ({ className, ...props }) => <Tooltip {...props} classes={{ tooltip: className }} />;
export const StyledTooltip = styled(ToBeStyledTooltip)(({ theme, collapsemenu }) => ({
  display: collapsemenu === "true" ? "flex" : "none",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  height: 40,
  padding: "8px 16px",
  fontSize: 14,
  fontWeight: 600,
  marginLeft: "25px !important",
}));
