import React, { useEffect, useState } from "react";

//
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
//
import { useDispatch, useSelector } from "react-redux";

import PaymentOverrideTabs from "./components/Tabs";
import useDropzoneOverride from "../../../../custom_hooks/useDropzoneOverride";
import { getAllAlbumPhotos } from "../../../../Redux/slices/mediaSlice";
import { setFieldData } from "../../../../Redux/slices/modalSlice";
//
//
import DialogContainer from "../../../Dialog/Index";
import { generateData, isFormValid, update } from "../../../Utils/Form/formActions";
//
import Select from "../components/Select";
//
import { ModalContainer } from "../Styled";
//

export default function OverridePayment({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modalState = useSelector((state) => state.modal);
  const countriesState = useSelector((state) => state.form?.staticData["st-countries-list"]);
  const pageType = useSelector((state) => state?.layout?.formPageType?.page);
  const albumPage = useSelector((state) => state?.media?.albumPage);

  const [formdata, setFormdata] = useState({
    country: {
      element: "select",
      value: "",
      config: {
        name: "country_input",
        placeholder: "Country",
        label: "Select Country",
        options: countriesState,
      },
      validation: {
        required: true,
        email: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
    nameDeposit: {
      element: "input",
      value: "",
      config: {
        name: "deposit_name_input",
        type: "text",
        label: "Deposit Name",
        placeholder: "Enter Deposit Name",
      },
      validation: {
        required: false,
        email: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
    nameWithdraw: {
      element: "input",
      value: "",
      config: {
        name: "withdrawal_name_input",
        type: "text",
        label: "Withdrawal Name",
        placeholder: "Enter Withdrawal Name",
      },
      validation: {
        required: false,
        email: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
  });
  const [overrideLogos, setOverrideLogos] = useState({
    depositLogo: {
      imageId: null,
      imageUrl: null,
    },
    depositLogoHover: {
      imageId: null,
      imageUrl: null,
    },
    withdrawLogo: {
      imageId: null,
      imageUrl: null,
    },
    withdrawLogoHover: {
      imageId: null,
      imageUrl: null,
    },
  });

  const { getRootProps, getInputProps, setActiveDropzone, isLoading } = useDropzoneOverride("payment-methods");

  useEffect(() => {
    if (pageType !== albumPage) {
      dispatch(getAllAlbumPhotos({ album: pageType?.split(".")?.[0], page: pageType }));
    }
  }, [pageType, albumPage, dispatch]);

  const updateForm = (element) => {
    const newFormdata = update(element, formdata, "overridePayment");
    setFormdata(newFormdata);
  };

  const handleSubmit = () => {
    const dataToSubmit = generateData(formdata);
    const formIsVaid = isFormValid(formdata);
    const prevOverrideState = modalState?.modal?.pageFrom?.value || null;

    if (formIsVaid && Boolean(formdata.country.value)) {
      const newObj = {
        [dataToSubmit?.country]: {
          depositLogo: overrideLogos.depositLogo,
          depositLogoHover: overrideLogos.depositLogoHover,
          withdrawLogo: overrideLogos.withdrawLogo,
          withdrawLogoHover: overrideLogos.withdrawLogoHover,
          nameDeposit: dataToSubmit.nameDeposit,
          nameWithdraw: dataToSubmit.nameWithdraw,
        },
      };
      if (prevOverrideState) {
        Object.entries(prevOverrideState).map(([key, val]) => {
          return Object.assign(newObj, { ...newObj, [key]: val });
        });
      }
      dispatch(setFieldData({ [modalState?.modal?.pageFrom?.elemName]: newObj }));
      onClose();
    } else {
      enqueueSnackbar("Country field is required!", { variant: "error" });
    }
  };

  const handleSetOverrideLogo = (logoKey, logoObj) => {
    setOverrideLogos((prevState) => ({
      ...prevState,
      [logoKey]: logoObj,
    }));
  };

  return (
    <DialogContainer
      label="Logo Override"
      actionLabel="Submit"
      isLoading={isLoading}
      isOpen={isOpen}
      onAction={handleSubmit}
      onClose={onClose}
    >
      <ModalContainer>
        <div className="modal-input-wrapp">
          {Object.entries(formdata).map(([key, val], idx) => {
            if (key === "country") return null;
            return (
              <TextField
                key={`pm-override-textfield-${key}`}
                variant="outlined"
                className="modal-textfield"
                id={val.config.name}
                label={val.config.label}
                value={val.value}
                onChange={(event) => updateForm({ event, id: key })}
                onBlur={(event) => updateForm({ event, id: key, blur: true })}
              />
            );
          })}
          <Select
            label="Select Country"
            handleChange={(event) => updateForm({ event, id: "country" })}
            selectedValue={formdata.country.value}
            placeholder={formdata.country.config.placeholder}
            options={formdata.country.config.options}
          />
        </div>
        <PaymentOverrideTabs
          handleActiveDropzone={(field) => setActiveDropzone(field)}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isLoading={isLoading}
          overrideLogos={overrideLogos}
          handleSetOverrideLogo={(key, obj) => handleSetOverrideLogo(key, obj)}
        />
      </ModalContainer>
    </DialogContainer>
  );
}
