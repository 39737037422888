import React from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { IconButton, OutlinedInput, InputLabel, InputAdornment, FormHelperText } from "@mui/material";

import { handleShowPassword, updateForm } from "../helpers";
import { StyledFormGroup } from "../styledResetPassword";

export default function FormGroupData({ formdataKey, formdataValue, formdata, setFormdata, setFormError }) {
  const onBlur = (event, formdataKey, formdata, setFormdata, setFormError) => {
    updateForm({ event, id: formdataKey, blur: true }, formdata, setFormdata, setFormError);
  };

  return (
    <StyledFormGroup variant="outlined" isValid={!formdataValue.valid}>
      <InputLabel htmlFor="outlined-adornment-password">{formdataValue.config.label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={formdataValue.showPassword ? "text" : "password"}
        label={formdataValue.config.label}
        error={!formdataValue.valid}
        {...formdataValue.config}
        value={formdataValue.value}
        onBlur={(event) => onBlur(event, formdataKey, formdata, setFormdata, setFormError)}
        onChange={(event) => updateForm({ event, id: formdataKey }, formdata, setFormdata, setFormError)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleShowPassword(formdataKey, formdata, setFormdata)}
              edge="end"
            >
              {!formdataValue.showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
      {Boolean(formdataValue?.validationMessage) && !formdataValue.valid && (
        <FormHelperText>{formdataValue?.validationMessage}</FormHelperText>
      )}
    </StyledFormGroup>
  );
}
