import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { clearAlbumPhotos, getAllAlbumPhotos, setImageId } from "../../../Redux/slices/mediaSlice";
import DialogContainer from "../../Dialog/Index";
import Library from "./components/Library";
import Upload from "./components/Upload";
import { StyledUploadLibraryContainer } from "./StyledImageUpload";

export default function ImageUpload({ isOpen, onClose }) {
  const dispatch = useDispatch();

  const element = useSelector((state) => state.media.element);
  const pageType = useSelector((state) => state?.layout?.formPageType?.page);
  const albumPage = useSelector((state) => state?.media?.albumPage);
  const [imageToSubmit, setImageToSubmit] = useState({
    imageId: null,
    imageUrl: "",
    imageCheck: false,
  });
  const isChatOracle = pageType.includes("chat.oracle") || false;

  useEffect(() => {
    if (pageType !== albumPage) {
      dispatch(getAllAlbumPhotos({ album: pageType?.split(".")?.[0], page: pageType }));
    }
  }, [pageType]);

  const submitImage = () => {
    const image = {
      id: imageToSubmit.imageId,
      url: imageToSubmit.imageUrl,
      element: element.name,
      isNew: true,
    };

    dispatch(setImageId(image));
    onClose();
  };

  return (
    <DialogContainer
      isOpen={isOpen}
      label={isChatOracle ? "Upload" : "Upload Image"}
      actionLabel="Select"
      onAction={() => submitImage()}
      onClose={onClose}
      height={"95%"}
    >
      <StyledUploadLibraryContainer>
        <Upload />
        <p className="styled-midsection">or</p>
        <Library imageToSubmit={imageToSubmit} setImageToSubmit={setImageToSubmit} isChatOracle={isChatOracle} />
      </StyledUploadLibraryContainer>
    </DialogContainer>
  );
}
