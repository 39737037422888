import { Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  width: "100%",
  ".MuiAccordionSummary-content": {
    display: "flex",
    gap: "16px",
    paddingLeft: "24px",
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: "4px",
}));

export const SummaryWrap = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  borderRadius: "8px",
  paddingRight: "12px",
}));

export const ItemWrap = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const ItemLabel = styled("p")(({ theme, isTextEllipsis }) => ({
  color: theme.palette.grey[500],
  fontSize: "14px",
}));

export const ItemValue = styled("p")(({ theme }) => ({
  fontSize: "16px",
}));

export const AccordionSummaryImage = styled("img")(({ theme }) => ({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
}));

export const StyledPopoverWrapp = styled("div")(({ theme }) => ({
  button: {
    padding: 0,
    background: "none",
    color: theme.palette.primary.main,
    boxShadow: "none",
    textAlign: "left",
    "&:focus": {
      outline: "none !important",
    },
    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
  },
}));

export const StyledPreviewPopover = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    maxWidth: "300px",
    maxHeight: "300px",
    left: "50%",
    transform: "translateX(-50%)",
    p: {
      padding: "8px 24px",
      "&:first-of-type": {
        padding: "16px 24px 8px",
      },
      "&:last-child": {
        padding: "8px 24px 16px",
      },
    },
  },
}));
