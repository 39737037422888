import React from "react";

import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import DialogContainer from "../../Dialog/Index";
import Stepper from "./components/Stepper";
import { StyledUploadLibraryContainer } from "./StyledFontsUpload";

export default function FontsUploadModal({ isOpen, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const customFonts = useSelector((state) => state?.settings?.customFonts);

  const handleSubmit = () => {
    Object.entries(customFonts).forEach(([key, value]) => {
      if (value) {
        enqueueSnackbar(`You have successfully set custom ${key} font`, { variant: "success" });
      }
    });
    onClose();
  };

  return (
    <DialogContainer
      isOpen={isOpen}
      label="Upload Custom Font"
      actionLabel="Select"
      onAction={() => handleSubmit()}
      onClose={onClose}
    >
      <StyledUploadLibraryContainer>
        <Stepper />
      </StyledUploadLibraryContainer>
    </DialogContainer>
  );
}
