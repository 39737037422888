import { styled } from "@mui/system";

export const StyledContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: "20px 0 10px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  ".modal-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ".close-icon": {
      cursor: "pointer",
      width: 30,
      height: 30,
    },
    div: {
      fontSize: 22,
      span: {
        fontSize: 22,
        fontWeight: "bold",
      },
    },
  },
  ".filters-container": {
    display: "flex",
    gap: 24,
    alignItems: "flex-end",
    margin: " 24px 0",

    ".form-control": {
      " .MuiInputBase-root": {
        height: 56,
      },
      ".MuiInputBase-input": {
        padding: "6px 32px 6px 14px",
        lineHeight: "1.5",
      },
      ".form-control-input-label": {
        transform: "translate(14px, 16px) scale(1)",
        color: `${theme.palette.common.black} !important`,
        "&.has-value": {
          transform: "translate(9px, -9px) scale(0.75)",
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.common.white,
        },
      },
      fieldset: {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey.main} !important`,
      },
      "&.opened": {
        ".form-control-input-label": {
          transform: "translate(9px, -9px) scale(0.75)",
          color: `${theme.palette.primary.main} !important`,
          backgroundColor: theme.palette.common.white,
          "&.has-value": {
            transform: "translate(9px, -9px) scale(0.75)",
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
          },
        },
        fieldset: {
          borderWidth: `2px !important`,
          borderColor: `${theme.palette.primary.main} !important`,
        },
      },
    },
    ".MuiBox-root": {
      flex: 1,
    },
    ".search-field": {
      flex: 1,
    },
  },
  ".games-wrap": {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: 24,
    ".MuiCircularProgress-root": {
      margin: "150px auto",
    },
    ".all-games": {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "repeat(6, 1fr)",
      rowGap: 48,
      columnGap: 14,
      ".game-wrap": {
        width: 152,

        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
        borderRadius: 10,
        img: {
          width: 152,
          height: 152,
          objectFit: "cover",
          borderRadius: "10px 10px 0 0",
        },
        ".game-name": {
          width: "100%",
          background: theme.palette.primary.light,
          borderRadius: "0 0 10px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        p: {
          fontSize: 14,
          width: "90%",
          padding: "8px 0",
          borderRadius: "0 0 10px 10px",
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        ".checkbox-outlined": {
          position: "absolute",
          background: "white",
          borderRadius: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 40,
          height: 40,
          top: 5,
          right: 5,
        },
      },
    },
    ".no-games": {
      margin: "250px 0",
    },
  },
  ".buttons": {
    display: "flex",
    margin: "10px 10px 10px auto",
    button: {
      gap: 24,
    },
  },

  ".progress-bar": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
