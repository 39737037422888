import React, { useState } from "react";

import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import DescriptorTabs from "./components/DescriptorTabs";
import SelectLanguage from "./components/SelectLanguage";
import { ModalContent } from "./styled";
import { setFieldData } from "../../../Redux/slices/modalSlice";
import DialogContainer from "../../Dialog/Index";
import { generateData, isFormValid, update } from "../../Utils/Form/formActions";

const PaymentDescriptorsModal = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const languages = modalState?.modal?.previewData?.availableLangs;
  const defaultLangauge = languages?.find((lang) => lang?.value === "en");
  const isDisabled = modalState?.modal?.previewData?.isDisabled;
  const [descriptor, setDescriptor] = useState(modalState?.modal?.previewData?.prevData || {});
  const [formdata, setFormdata] = useState({
    language: {
      element: "select",
      value: modalState?.modal?.previewData?.lang ?? defaultLangauge?.value ?? "",
      config: {
        name: "language_input",
        placeholder: "language",
        label: "Select language",
        options: languages,
      },
      validation: {
        required: true,
        email: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
  });

  const updateForm = (element) => {
    const newFormdata = update(element, formdata, "overridePayment");
    setFormdata(newFormdata);
  };
  const handleDesctiptorChange = (e, element, name) => {
    setDescriptor({ ...descriptor, [element]: e.value });
  };

  const handleSubmit = () => {
    const dataToSubmit = generateData(formdata);
    const formIsVaid = isFormValid(formdata);
    const prevOverrideState = modalState?.modal?.pageFrom?.value;

    if (formIsVaid && Boolean(formdata.language.value)) {
      const newObj = {
        ...prevOverrideState,
        [dataToSubmit?.language]: {
          depositDescriptorTop: descriptor.depositDescriptorTop,
          depositDescriptorBottom: descriptor.depositDescriptorBottom,
          withdrawDescriptorTop: descriptor.withdrawDescriptorTop,
          withdrawDescriptorBottom: descriptor.withdrawDescriptorBottom,
        },
      };

      dispatch(setFieldData({ [modalState?.modal?.pageFrom?.elemName]: newObj }));
      onClose();
    } else {
      enqueueSnackbar("Language field is required!", { variant: "error" });
    }
  };
  return (
    <DialogContainer
      label="Payment Descriptors"
      actionLabel="Submit"
      onAction={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      width={1152}
    >
      <ModalContent>
        <SelectLanguage
          formdata={formdata}
          handleChange={(event) => updateForm({ event, id: "language" })}
          isDisabled={isDisabled}
        />
        <DescriptorTabs changeDescriptor={handleDesctiptorChange} descriptor={descriptor} />
      </ModalContent>
    </DialogContainer>
  );
};

export default PaymentDescriptorsModal;
