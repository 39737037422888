import React from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { deletePhotoByID } from "../../../../Redux/slices/mediaSlice";
import { handleKeyTransform } from "../../../Utils/TemplateHelpers/modalHelpers";
import {
  StyledDetailsContentContainer,
  StyledImageDetailsContainer,
  StyledLabelTrashIconContainer,
} from "../styledImageEdit";

export default function Details() {
  const dispatch = useDispatch();
  const photoState = useSelector((state) => state.media);

  const detailsObjectCollection = (key, val) => {
    const obj = {
      width: [`${val}px`, handleKeyTransform(key)],
      height: [`${val}px`, handleKeyTransform(key)],
      ext: [`${val}`, "Extension"],
      created_at: [dayjs(val).format("DD MMM YYYY HH:mm"), "Uploaded"],
    };
    return obj[key] || null;
  };

  return (
    <StyledImageDetailsContainer>
      <StyledLabelTrashIconContainer>
        <h4 className="image-details-label">Image Details</h4>
        <DeleteOutlineIcon
          className="delete-icon"
          onClick={() => dispatch(deletePhotoByID(photoState?.relatedPhotos?.id))}
        />
      </StyledLabelTrashIconContainer>
      <StyledDetailsContentContainer>
        {Object.entries(photoState?.editPhoto).map(([key, value], idx) => {
          const valueContent = detailsObjectCollection(key, value)?.[0];
          const keyContent = detailsObjectCollection(key, value)?.[1];

          return (
            valueContent && (
              <div className="label-value-container" key={idx}>
                <div className="details-content-label">{keyContent}:</div>
                <div className="details-content-value">{valueContent}:</div>
              </div>
            )
          );
        })}
      </StyledDetailsContentContainer>
    </StyledImageDetailsContainer>
  );
}
