import React from "react";

import { Outlet, useLocation } from "react-router";

import ModalSystem from "../../Components/ModalSystem";
import Sidebar from "../../Components/Sidebar";
import { StyledLayout } from "../StyledLayout";

export default function AuthLayout({ mobileOutClickHandler, width, collapseMenu }) {
  // see if 404 page is opened. if it is, the left margin should be removed
  const { pathname } = useLocation();
  const is404 = !!pathname.includes("404");
  // only for all pages(pages where is table with data) and translation page, padding top should be 24px and not 100px
  const defaultPadding = () => ["all", "translations", "media", "dashboard"].some((el) => pathname.includes(el));
  const isSettingsLayout = pathname.includes("settings/layout");
  return (
    <>
      <Sidebar />
      <StyledLayout
        className={`${width < 992 ? "pcoded-main-container-mobile" : is404 ? "not-found" : ""}`}
        onClick={() => mobileOutClickHandler()}
        sidebarOpen={!collapseMenu}
        isDeffPage={defaultPadding()}
        isSettingsLayout={isSettingsLayout}
      >
        <div className="page-wrapper">
          <Outlet />
        </div>
      </StyledLayout>
      <ModalSystem />
    </>
  );
}
