import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { clearPageData, handlePageLoading } from "./slice";
import formService from "../../services/formService";
import { updateBtnLoadingStatus } from "../layoutSlice";

export const populateFormState = createAsyncThunk("form/populateFormState", async (data) => {
  return { state: data?.state, isChanged: data?.isChanged };
});

/**
 * Handles the form submission asynchronously.
 *
 * @param {Object} submitData - The data to be submitted.
 * @param {Object} options - The options object containing getState and dispatch functions.
 * @returns {Promise} - A promise that resolves to the response data.
 */
export const handleSubmitForm = createAsyncThunk("form/addNewForm", async (submitData, { getState, dispatch }) => {
  const { data, pageType, type, pageID } = submitData;

  const isSettingsLayout = getState()?.layout?.formPageType?.page?.includes("settings.layout");
  const apiMeth = type === "new" ? "post" : "put";
  const apiPath = pageID
    ? isSettingsLayout
      ? `/settings/updateLayout/${pageID}`
      : `/${pageType}/${pageID}`
    : `/${pageType}`;
  const portalIdentifier = localStorage.getItem("portal") || getState()?.settings?.portalSettings?.portal;
  const overrideData = isSettingsLayout ? { ...data, portal: portalIdentifier } : data;

  const res = await formService.formSubmit(apiPath, apiMeth, portalIdentifier, overrideData, isSettingsLayout);
  return res?.data;
});

/**
 * Fetches page data asynchronously.
 *
 * @param {Object} pageFrom - The page information.
 * @param {string} pageFrom.pageType - The type of the page.
 * @param {string} pageFrom.pageID - The ID of the page.
 * @param {string} pageFrom.objPath - The object path.
 * @param {boolean} pageFrom.retain - Indicates whether to retain the page data from previous fetch.
 * @param {AbortSignal} pageFrom.signal - The signal used to abort the fetch request.
 * @param {Object} reduxProps - The Redux props.
 * @returns {Promise<Object>} A promise that resolves to the page data.
 * @throws {string} If the request was canceled or failed to fetch data.
 */
export const handleGetPageData = createAsyncThunk("form/getPageData", async (pageFrom, reduxProps) => {
  const { pageType, pageID, objPath, retain, signal } = pageFrom;
  // clear page data from prev fetch
  try {
    !retain && reduxProps.dispatch(clearPageData());
    const portal = localStorage.getItem("portal") || reduxProps?.getState()?.settings.portalSettings.portal;
    const staticData = reduxProps?.getState()?.form.staticData;

    const portalIdentifier = portal || localStorage.getItem("portal");
    const apiPath = pageID ? `/${pageType}/${pageID}` : `/${pageType}/extended?portal=${portalIdentifier}&_limit=-1`;

    if (staticData[pageType]) {
      const isPaymentOrder = pageType === "payment-methods-orders";
      return {
        data: isPaymentOrder
          ? staticData[pageType]?.map((curr) => {
              return { slug: curr?.name, multilingual: [{ name: curr?.name }] };
            })
          : staticData[pageType],
        isSingle: Boolean(pageID),
        objPath,
      };
    }
    const res = await formService.getPageData(apiPath, signal);
    const reponseData = {
      data: ["payment-methods", "footers"].includes(pageType) ? res?.data[0] : res?.data,
      isSingle: Boolean(pageID),
      objPath,
    };
    return res?.data ? reponseData : reduxProps.rejectWithValue("Request was canceled");
  } catch (error) {
    return reduxProps.rejectWithValue("Failed to fetch data");
  }
});

/**
 * Handles the deletion of a page form.
 *
 * @param {Object} page - The page object containing pageType, pageID, and data.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise} - A promise that resolves with the result of the deletion.
 */
export const handleDeletePageForm = createAsyncThunk("form/handleDeletePageForm", async (page, { dispatch }) => {
  const MySwal = withReactContent(Swal);
  const { pageType, pageID, data } = page;
  const apiPath = `/${pageType}/${pageID}`;

  const deleteMessage =
    pageType === "game-providers"
      ? ["Disabled!", "The game provider has been disabled."]
      : ["Deleted!", "Your item has been deleted."];
  const dataToSubmit = {
    ...data,
    publishing: {
      ...data?.publishing,
      status: "trash",
    },
  };

  return MySwal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, proceed!",
  }).then((willDelete) => {
    if (willDelete.isConfirmed) {
      dispatch(deleteHandler({ dataToSubmit, apiPath })).then((res) => {
        dispatch(handleGetPageData({ pageType, pageID: null, objPath: "all" }));
      });
      return MySwal.fire(deleteMessage[0], deleteMessage[1], "success");
    }
  });
});

const deleteHandler = createAsyncThunk("form/deleteHandler", async (data) => {
  const { dataToSubmit, apiPath } = data;
  const res = await formService.deletePage(apiPath, dataToSubmit);
  return res?.data;
});

export const updateGameById = createAsyncThunk("form/updateGameById", async (data, { dispatch }) => {
  const { gameID, dataToSubmit, toGames } = data;
  dispatch(updateBtnLoadingStatus(gameID));

  await formService.updateGameById(gameID, dataToSubmit);

  const updated = await formService.getGameById(gameID).then((res) => {
    dispatch(updateBtnLoadingStatus(false));
    return res?.data;
  });

  return {
    data: updated,
    gameID,
    toGames,
  };
});
