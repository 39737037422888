import React, { useEffect, useState } from "react";

import { CircularProgress, Tab } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { updatePhotoByID } from "../../../Redux/slices/mediaSlice";
import DialogContainer from "../../Dialog/Index";
import { generateData, isFormValid, update } from "../../Utils/Form/formActions";
import Details from "./components/Details";
import Edit from "./components/Edit";
import Related from "./components/Related";
import {
  StyledImageContainer,
  StyledImageEditContainer,
  StyledImageEditModal,
  StyledImageEditModalBody,
  StyledSelectedTabHeader,
} from "./styledImageEdit";

export default function ImageEdit({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const photoState = useSelector((state) => state.media);
  const portal = useSelector((state) => state?.settings.portalSettings?.portal);

  const [activeTab] = useState("general");
  const [formError, setFormError] = useState(false);
  const [formdata, setFormdata] = useState({
    name: {
      element: "input",
      value: "",
      config: {
        name: "imgName_input",
        type: "text",
        placeholder: "Enter Name",
      },
      validation: {
        required: true,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
    alternativeText: {
      element: "input",
      value: "",
      config: {
        name: "imgAlt_input",
        type: "text",
        placeholder: "Enter Alternative Text",
      },
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
    caption: {
      element: "input",
      value: "",
      config: {
        name: "caption_input",
        type: "text",
        placeholder: "Enter Caption",
      },
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
  });

  const portalCode = localStorage.getItem("portal") || portal;
  const portalPath = `/${portalCode}/${photoState?.element?.pageType}/`;

  useEffect(() => {
    const name = photoState?.editPhoto?.name?.replace(portalPath, "");
    const extName = photoState?.editPhoto?.name && name.replace(photoState.editPhoto.ext, "");
    setFormdata({
      ...formdata,
      name: {
        ...formdata.name,
        value: extName || "",
      },
      alternativeText: {
        ...formdata.alternativeText,
        value: photoState?.editPhoto?.alternativeText || "",
      },
      caption: {
        ...formdata.caption,
        value: photoState?.editPhoto?.caption || "",
      },
    });
  }, [photoState.editPhoto]);

  const submitForm = (event) => {
    event.preventDefault();
    const data = generateData(formdata, "imageUpdate", portalPath, photoState?.editPhoto?.ext);
    const formIsVaid = isFormValid(formdata, "imageUpdate");

    const dataToSubmit = new FormData();
    dataToSubmit.append("fileInfo", JSON.stringify(data));

    if (formIsVaid) {
      dispatch(updatePhotoByID({ image: dataToSubmit, id: photoState?.editPhoto?.id }))
        .then((res) => {
          enqueueSnackbar("You have successfully updated image!", { variant: "success" });
        })
        .catch((err) => {
          if (err.response.status === 500) {
            enqueueSnackbar(`${err?.response?.data?.error}, please contact support.`, { variant: "error" });
          } else {
            Object.entries(err?.response?.data?.data?.errors).map(([key, val]) => {
              const errVal = val ? val.toString() : "There was an error, please contact support.";
              return enqueueSnackbar(`${errVal.charAt(0).toUpperCase() + errVal.slice(1)}`, { variant: "error" });
            });
          }
        });
    } else {
      setFormError(true);
    }

    onClose();
  };

  const updateForm = (event) => {
    const newFormdata = update(event, formdata, "login");
    setFormError(false);
    setFormdata(newFormdata);
  };

  return (
    <DialogContainer
      label="Edit Image"
      actionLabel={activeTab === "replace" ? "Replace Image" : "Update"}
      isOpen={isOpen}
      onAction={(event) => submitForm(event)}
      onClose={onClose}
    >
      <StyledImageEditModal>
        <StyledSelectedTabHeader value={activeTab} className="mui-tabs">
          <Tab className="mui-tab" value="general" label="General" />
        </StyledSelectedTabHeader>
        {!photoState?.editPhoto ? (
          <CircularProgress />
        ) : (
          <StyledImageEditModalBody>
            <StyledImageEditContainer>
              <StyledImageContainer>
                <img src={photoState?.editPhoto?.url} alt="" />
              </StyledImageContainer>
              <Details />
            </StyledImageEditContainer>
            <Edit
              formdata={formdata}
              handleBlur={(event) => updateForm(event)}
              handleChange={(event) => updateForm(event)}
            />
            <Related />
          </StyledImageEditModalBody>
        )}
      </StyledImageEditModal>
    </DialogContainer>
  );
}
