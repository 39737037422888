import React, { useState } from "react";

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SelectDropDown = ({ options, name, handleChange }) => {
  const [value, setValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (e) => {
    setValue(e.target.value);
    handleChange(e.target.value, name);
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ width: "100%" }} className={`form-control ${isOpen === name ? "opened" : ""}`}>
        <InputLabel id="select-label" className={`form-control-input-label ${value ? "has-value" : ""}`}>
          {name}
        </InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          className="modal-dropdown"
          value={value}
          onChange={(e) => handleSelect(e)}
          onOpen={() => setIsOpen(name)}
          onClose={() => setIsOpen(false)}
        >
          <MenuItem key="all" value="">
            Remove filter
          </MenuItem>
          {options?.map((item, idx) => {
            return (
              <MenuItem key={`form-select-${idx}`} name={`${item?.slug}-${idx}`} value={item?.slug}>
                {item?.slug}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectDropDown;
