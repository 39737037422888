import React, { useCallback, useEffect } from "react";

//
import { Checkbox, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

//
//
import useMediumPagination from "../../../../custom_hooks/useMediumPagination";
import { useWindowSize } from "../../../../custom_hooks/useWindowSize";
//
import MediumPagination from "../../../Pagination/MediumPagination";
//
import { StyledLogoLibrary } from "../Styled";

export default function LogoLibrary({ libraryKey, overrideImages, handleSetOverrideImage }) {
  const album = useSelector((state) => state.media.album);
  const loading = useSelector((state) => state.media.loading);

  const { width } = useWindowSize();
  const itemsPerPage = width >= 1280 ? 5 : 3;

  const {
    curentPage,
    setCurentPage,
    sliceArgFirst,
    sliceArgSecond,
    handleGoToPageOnEnter,
    handleKeyDown,
    goToPageInput,
  } = useMediumPagination(itemsPerPage, Math.round(album.length / itemsPerPage));

  const handleCheck = (id, url) => {
    handleSetOverrideImage(libraryKey, { imageId: id, imageUrl: url });
  };

  const renderGridImages = () => {
    if (loading) return <CircularProgress style={{ margin: "0 auto" }} />;
    return album?.slice(sliceArgFirst, sliceArgSecond)?.map((val, idx) => {
      return (
        <div className="album-img" key={idx}>
          <img src={val.url} alt="" />
          <div className="checkbox-wrapp">
            <Checkbox
              id={idx}
              value={val.id}
              checked={overrideImages[libraryKey].imageId === parseInt(val.id) || false}
              onChange={() => handleCheck(val?.id, val?.url)}
            />
          </div>
        </div>
      );
    });
  };

  const eventCallback = useCallback(() => {
    function handleSelectedImageCheck(e) {
      const imageUrl = e.detail.url;
      return handleCheck(e.detail.id, imageUrl);
    }

    window.addEventListener("file_uploaded", (e) => {
      handleSelectedImageCheck(e);
    });
    return () => window.removeEventListener("file_uploaded", handleSelectedImageCheck);
  }, [album?.length]);

  useEffect(() => {
    eventCallback();
  }, [eventCallback]);

  return (
    <StyledLogoLibrary loading={loading}>
      <div className="img-container-wrapp">
        {loading ? (
          <CircularProgress style={{ margin: "0 auto" }} />
        ) : album?.length ? (
          renderGridImages()
        ) : (
          <p>There are no uploaded images.</p>
        )}
      </div>
      <div className="img-pagination-wrapp">
        {Boolean(album?.length) && (
          <MediumPagination
            data={album}
            inputValue={goToPageInput.inputValue}
            isInputValid={goToPageInput.isValid}
            itemsPerPage={itemsPerPage}
            curentPage={curentPage}
            setCurentPage={setCurentPage}
            handleGoToPageOnEnter={handleGoToPageOnEnter}
            handleKeyDown={handleKeyDown}
          />
        )}
      </div>
    </StyledLogoLibrary>
  );
}
