import instance from "../api_config";

const getAllRevisions = (page, postID, signal) => {
  const portal = localStorage.getItem("portal");
  const revisionApi = postID
    ? `/revisions/?postType=${page}&postID=${postID}`
    : `/revisions/?postType=${page}&portal=${portal}&_limit=-1`;
  return instance.get(revisionApi, { signal: signal });
};

const revisionsService = {
  getAllRevisions,
};
export default revisionsService;
