import React from "react";

import { CircularProgress, DialogActions, DialogContent } from "@mui/material";

import { StyledDialog } from "./StyledDialog";
import CustomButton from "../CustomButton";

const DialogContainer = ({
  label,
  actionLabel,
  isLoading,
  isOpen,
  onAction,
  onClose,
  width,
  children,
  height,
  showActionButtons = true,
  headerActionButton = false,
}) => {
  return (
    <StyledDialog open={isOpen} onClose={onClose} aria-labelledby="dialog-title" width={width} height={height}>
      {headerActionButton ||
        (label && (
          <div className="dialog-header-wrapp">
            {label ? <h3 id="dialog-title">{label}</h3> : null}
            {headerActionButton && (
              <CustomButton type="button" styleType="outlined" variant="primary" onClick={onAction}>
                {actionLabel}
              </CustomButton>
            )}
          </div>
        ))}
      <DialogContent
        sx={{ pt: 0, height: height ? "100%" : null, width: height ? "100%" : null }}
        className="dialog-content"
      >
        {children}
      </DialogContent>
      {showActionButtons && !headerActionButton ? (
        <DialogActions sx={{ padding: "24px", direction: "flex", rowGap: "1em" }} className="dialog-actions">
          {isLoading ? (
            <CircularProgress sx={{ color: (theme) => theme.palette.primary.main, marginLeft: "auto", mt: "0.4em" }} />
          ) : (
            <>
              <CustomButton type="button" styleType="empty" variant="primary" onClick={onClose}>
                Close
              </CustomButton>
              {onAction && actionLabel && (
                <CustomButton styleType="filled" variant="primary" onClick={onAction}>
                  {actionLabel}
                </CustomButton>
              )}
            </>
          )}
        </DialogActions>
      ) : null}
    </StyledDialog>
  );
};

export default DialogContainer;
