import React from "react";
import Slider from "react-slick";
//
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { StyledNextArrow, StyledPrevArrow } from "../StyledDetailsComponent";
//

function NextArrow({ className, onClick }) {
  return (
    <StyledNextArrow className={className} onClick={onClick}>
      <ArrowForwardIosIcon />
    </StyledNextArrow>
  );
}

function PrevArrow({ className, onClick }) {
  return (
    <StyledPrevArrow className={className} onClick={onClick}>
      <ArrowBackIosNewIcon />
    </StyledPrevArrow>
  );
}

function ComponentPictures({ component, version, picsNumber }) {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const importAllComponentImages = () => {
    return [...Array(picsNumber).keys()].map((curr) => {
      return (
        <div className="component-slider-item" key={curr}>
          <img
            src={require(`../../../../assets/ComponentImages/${component}/${version}/${component + (curr + 1)}.webp`)}
            alt=""
          />
        </div>
      );
    });
  };

  return <Slider {...sliderSettings}>{importAllComponentImages()}</Slider>;
}

export default ComponentPictures;
