import instance from "../api_config";

const getPreviewDataByID = (pageFrom) => {
  const { pageType, pageID, isRevisions } = pageFrom;
  const dataApi = !isRevisions ? `/${pageType}/${pageID}` : `/revisions/?postType=${pageType}&id=${pageID}`;
  return instance.get(dataApi);
};

const modalService = {
  getPreviewDataByID,
};
export default modalService;
