import React from "react";

import { useDispatch, useSelector } from "react-redux";

import CategoryBulkAdd from "./CategoryBulkAdd";
import ComponentDetailsModal from "./ComponentDetailsModal";
import RevisionModal from "./Dashboard";
import FontsUploadModal from "./FontsUploadModal";
import ImageEdit from "./ImageEdit";
import ImageUpload from "./ImageUpload";
import LobbyPreviewModal from "./LobbyPreviewModal";
import OverrideMenu from "./LogoOverride/Menu/index";
import OverridePayment from "./LogoOverride/Payment/index";
import OrderModal from "./OrderModal";
import PaymentDescriptorsModal from "./PaymentDescriptors";
import PreviewModal from "./PreviewModal";
import RelatedPhotos from "./RelatedPhotos";
import ResetPassword from "./ResetPassword";
import TextCompareModal from "./TextCompareModal";
import TranslationsRevisions from "./TranslationsRevisions";
import { closeModal } from "../../Redux/slices/modalSlice";

export default function ModalSystem() {
  const dispatch = useDispatch();
  const {
    modal: { modal, isOpen, cb },
  } = useSelector((state) => state.modal);
  const close = () => dispatch(closeModal(modal));

  const dialogMap = {
    imageUpload_modal: <ImageUpload isOpen={isOpen} onClose={close} cb={cb} />,
    preview_modal: <PreviewModal isOpen={isOpen} onClose={close} />,
    imageEdit_modal: <ImageEdit isOpen={isOpen} onClose={close} />,
    reset_pass_modal: <ResetPassword isOpen={isOpen} onClose={close} />,
    related_photos: <RelatedPhotos isOpen={isOpen} onClose={close} />,
    imageOverride_modal: <OverridePayment isOpen={isOpen} onClose={close} cb={cb} />,
    iconOverride_modal: <OverrideMenu isOpen={isOpen} onClose={close} cb={cb} />,
    translations_revisions: <TranslationsRevisions isOpen={isOpen} onClose={close} />,
    order_modal: <OrderModal isOpen={isOpen} onClose={close} cb={cb} />,
    revision_modal: <RevisionModal isOpen={isOpen} onClose={close} />,
    text_compare_modal: <TextCompareModal isOpen={isOpen} onClose={close} />,
    fonts_upload_modal: <FontsUploadModal isOpen={isOpen} onClose={close} />,
    components_details_modal: <ComponentDetailsModal isOpen={isOpen} onClose={close} />,
    lobby_preview_modal: <LobbyPreviewModal isOpen={isOpen} onClose={close} />,
    category_bulk_add: <CategoryBulkAdd isOpen={isOpen} onClose={close} />,
    payment_descriptors_modal: <PaymentDescriptorsModal isOpen={isOpen} onClose={close} />,
  };

  const Dialog = dialogMap[modal];

  return <>{Dialog}</>;
}
