import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { useSelector } from "react-redux";

import { StyledLobby } from "../StyledLobbyPreview";

export default function V2({ findCategoryTitle, games }) {
  const lobbyOptions = useSelector((state) => state?.settings?.portalSettings?.layout?.lobby?.options);
  const activeCategory = useSelector((state) => state?.form?.orderCategory);

  return (
    <StyledLobby>
      <div className="filters">
        <div className="categories-wrapp">
          <span className={activeCategory === "all" ? "active" : ""}>All Games</span>
          {lobbyOptions?.gameCategories?.map((category, index) => {
            if (category?.slug === "all") return null;
            return (
              <span className={activeCategory === category ? "active" : ""} key={index}>
                {findCategoryTitle(category)}
              </span>
            );
          })}
        </div>
        <div className="fiter-wrapp">
          <div className="filt-select">
            Search <SearchIcon />
          </div>
          <div className="filt-select">
            Game Providers
            <TuneIcon />
          </div>
        </div>
      </div>
      <div className="game-preview">
        <div className="game-wrapper">{games}</div>
      </div>
    </StyledLobby>
  );
}
