import instance from "../api_config";

const login = (dataToSubmit) => {
  return instance.post(`/auth/local`, dataToSubmit);
};

const refresh = () => {
  return instance.get(`/users/me`);
};

const resetPassword = (dataToSubmit) => {
  return instance.put(`/users/${dataToSubmit.userID}`, dataToSubmit.data);
};

const forgotPassword = (email, redirectPath = "/administration/reset-password") => {
  return instance.post(`/auth/forgot-password`, {
    email,
    url: `${process.env.REACT_APP_API_URL}${redirectPath}`,
  });
};

// resetPasswordByCode is for anonymouse users
const resetPasswordByCode = (code, password, passwordConfirmation) => {
  return instance.post(`/auth/reset-password`, {
    code,
    password,
    passwordConfirmation,
  });
};

const userService = {
  login,
  refresh,
  resetPassword,
  forgotPassword,
  resetPasswordByCode,
};

export default userService;
