import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { deletePhotoByID, relatedPhotoByID } from "../../../Redux/slices/mediaSlice";
import { getPreviewDataByID } from "../../../Redux/slices/modalSlice";
import DialogContainer from "../../Dialog/Index";
import { renderTitleAndPathComponentFraction } from "../../Utils/TemplateHelpers/modalHelpers";
import {
  StyledImageRelatedPagesContainer,
  StyledOverallContainer,
  StyledRefreshIcon,
  StyledRelatedPhotosContainer,
} from "./styledRelatedPhotos";

export default function RelatedPhotos({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { relatedPhotos, relatedLoading } = useSelector((state) => state.media);
  const [relatedPhoto, setRelatedPhoto] = useState({});
  const pageFrom = useSelector((state) => state.modal?.modal?.pageFrom);

  useEffect(() => {
    dispatch(relatedPhotoByID(pageFrom)).then((res) => {
      res?.related.length &&
        res.related.map((photo) => {
          return dispatch(
            getPreviewDataByID({ pageType: photo?.__contentType, pageID: photo?.id, isRevisions: false })
          );
        });
    });
  }, [pageFrom]);

  useEffect(() => {
    setRelatedPhoto(relatedPhotos);
    if (!relatedPhotos.related.length) {
      onClose();
      dispatch(deletePhotoByID(relatedPhotos?.id));
    }
  }, [relatedPhotos]);

  return (
    <DialogContainer
      isOpen={isOpen}
      label="Related Image"
      actionLabel={relatedPhoto?.related?.length !== 0 && "Delete"}
      onAction={() => {
        dispatch(deletePhotoByID(relatedPhotos?.id));
      }}
      onClose={onClose}
    >
      <StyledOverallContainer>
        <StyledRelatedPhotosContainer>
          <div className="refresh-header-container">
            <h2>Are you sure you want to delete this photo?</h2>
            <StyledRefreshIcon onClick={() => dispatch(relatedPhotoByID(pageFrom))} />
          </div>
          <StyledImageRelatedPagesContainer>
            <p className="photo-related-paragraph">
              {relatedPhotos?.related?.length
                ? "This photo is related to:"
                : "There are no related pages to this image"}
            </p>
            {!relatedLoading ? renderTitleAndPathComponentFraction(relatedPhotos) : <CircularProgress />}
          </StyledImageRelatedPagesContainer>
        </StyledRelatedPhotosContainer>
      </StyledOverallContainer>
    </DialogContainer>
  );
}
