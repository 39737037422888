import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "24px",
  textTransform: "capitalize",
  "&:focus": {
    outline: "none !important",
  },
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  ".MuiTabs-scroller": { borderBottom: "1px solid", borderColor: theme.palette.grey[300] },
  ".MuiButtonBase-root": {
    color: theme.palette.grey[500],
  },
}));

export const StyledTermsParagraph = styled("p", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  textAlign: "justify",
  ".read-more-less": {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.darker,
    },
  },
}));

export const StyledFormImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "5px",
}));

export const StyledMultilingualTab = styled("div", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  color: exists ? theme.palette.primary.main : theme.palette.grey[500],
  position: "relative",
  top: exists ? "12px" : "10px",
  width: "100%",
  cursor: "pointer",
}));

export const StyledGeneralUnderline = styled("hr", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  background: exists ? theme.palette.primary.main : "",
  height: exists ? "2px" : "",
  zIndex: 2,
}));

export const StyledMultilingualUnderline = styled("hr", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  background: exists ? theme.palette.primary.main : "",
  height: exists ? "2px" : "",
  position: "relative",
  zIndex: 2,
}));

export const StyledHeaderUnderline = styled("hr", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  background: theme.palette.grey[500],
  marginTop: "-45px",
  width: "100%",
  position: "relative",
  zIndex: 1,
}));

export const StyledUnderline = styled("hr", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  background: theme.palette.primary.light,
  width: "100%",
  position: "relative",
  borderBottom: exists ? "" : "40px",
}));

export const StyledGridContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
}));

export const StyledGeneralInfo = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "40px",
}));

export const StyledDynamicFilesFields = styled("div", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  marginBottom: "40px",
  borderBottomStyle: exists ? "" : "dotted",
  borderColor: theme.palette.primary.light,
  ".files-fields-container": {
    marginBottom: "40px",
    display: "flex",
    ".dynamic-key": {
      width: "50%",
    },
    ".dynamic-file-field": {
      width: "50%",
    },
  },
}));

export const StyledTitle = styled("h5")(({ theme }) => ({
  color: theme.palette.common.black,
  marginBottom: "40px",
}));

export const StyledTitleUndefined = styled("h5")(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: "40px",
}));

export const StyledSlidesMenuTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  gap: "5px",
  marginBottom: "20px",
  marginTop: "20px",
}));

export const StyledSlidesMenuContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  rowGap: "40px",
  borderBottomStyle: exists ? "" : "dotted",
  borderColor: theme.palette.primary.light,
  ".files-fields-container": {
    marginBottom: "40px",
  },
}));

export const StyledFallback = styled("div", {
  shouldForwardProp: (prop) => prop !== "exists",
})(({ theme, exists }) => ({
  marginBottom: exists ? "" : "40px",
}));

export const StyledPublishing = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "40px",
}));
