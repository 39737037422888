import path from "path";

import React from "react";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { useWindowSize } from "../../../custom_hooks/useWindowSize";
import breakpoints from "../../../hoc/theme/breakpoints";
import { toggleSidebar } from "../../../Redux/slices/layoutSlice";
import { StyledSidebarCollapse, StyledTooltip } from "../StyledSidebar";

export default function SidebarCollapse({ item, toggle, isActive, setIsActive, collapseMenu }) {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.user.user?.role?.name);
  const relativePath = process.env.REACT_APP_PUBLIC_RELATIVE_PATH || "";
  const tabletSize = breakpoints?.values.tablet;
  const redirectTo = (url) => {
    navigate(url, { replace: true });
    if (width < tabletSize) {
      dispatch(toggleSidebar());
    }
    if (path.basename(url) === "payment-methods") {
      window.location.reload(true);
    }
  };

  const position = item.idx >= 10 ? "bottom" : "top";
  return (
    <StyledSidebarCollapse
      isActive={isActive}
      collapsemenu={collapseMenu.toString()}
      className={`sidebar-collapse  ${isActive === item.id ? "active" : ""} ${isActive === item.id && isActive}`}
    >
      <StyledTooltip
        className="item-tooltip"
        title={item.title}
        placement="right-end"
        collapsemenu={collapseMenu.toString()}
      >
        <div className="accordion-title" onClick={() => toggle(item.id)}>
          <span className="item-icon">{item.icon}</span>
          <span className="item-title">{item.title}</span>
          <div className={`toggle ${isActive === item.id ? "active" : ""}`}>
            {width && width > tabletSize && collapseMenu ? null : <ArrowBackIosNewOutlinedIcon />}
          </div>
        </div>
      </StyledTooltip>
      <div className={`accordion-content ${isActive !== item.id ? "active" : ""} ${position}`}>
        <div className="acc-wrapp">
          <ul>
            {width && width > tabletSize && collapseMenu ? (
              <li className="nav-link-desc" onClick={() => toggle(item.id)}>
                <span className="material-symbols-outlined">{item.icon}</span>
                {item.title}
                <ArrowBackIosNewOutlinedIcon />
              </li>
            ) : null}
            {item.children.map((el, idx) => {
              const url = relativePath + el.url;
              if (Boolean(el?.disableForNonAdmin) && userRole !== "admin") return null;
              return (
                <li key={el.id + idx}>
                  <NavLink to={url} className={`nav-link ${el.id}`} end onClick={() => redirectTo(url)}>
                    {el.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </StyledSidebarCollapse>
  );
}
