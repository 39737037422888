import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAccordion = styled("div")(({ theme, accHeight, previewElement, formKey }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: 10,
  order: formKey === 'seo' ? 99 : "unset",
  ".accordion-title": {
    padding: "16px 24px",
    backgroundColor: theme.palette.background.card,
    fontSize: 16,
    fontWeight: 400,
    borderRadius: previewElement ? 8 : 10,
    color: theme.palette.grey.main,
    textTransform: previewElement ? "" : "uppercase",
    display: "flex",
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      cursor: previewElement ? "unset" : "pointer",
    },
    ".toggle": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      marginLeft: "auto",
      transition: "all 0.5s ease-in-out",
      svg: {
        width: 25,
        height: 25,
        transition: "0.5s ease-in-out",
        transform: "rotateZ(270deg)",
        path: {
          transition: "0.5s ease-in-out",
          fill: theme.palette.grey.main,
        },
      },
      "&.active": {
        svg: {
          transform: "rotateZ(90deg)",
        },
      },
    },
  },
  ".accordion-content": {
    maxHeight: 0,
    padding: "0 24px",
    overflow: "hidden",
    border: `1px solid transparent`,
    backgroundColor: "transparent",
    transition: "max-height 0.5s ease-in-out, border 0.5s ease-in-out, background-color 0.5s ease-in-out",
    ".acc-wrapp": {
      padding: previewElement ? "20px 0 0" : "50px 0",
      "&:has(.form-dnd-acc)": {
        padding: "12px 0",
      },
    },
    "&.active": {
      maxHeight: "fit-content",
      border: previewElement ? "unset" : `1px solid ${theme.palette.primary.lighter}`,
      backgroundColor: previewElement ? theme.palette.grey[100] : theme.palette.common.white,
      borderRadius: "0 0 10px 10px",
      transition: "max-height 1s cubic-bezier(0, 1, 0, 1), border 0.5s ease-in-out, background-color 0.5s ease-in-out",
    },
  },
  "&.active": {
    ".accordion-title": {
      borderRadius: previewElement ? 8 : "10px 10px 0 0",
      backgroundColor: previewElement ? "#f2f2f5" : theme.palette.primary.lighter,
      color: previewElement ? theme.palette.grey.main : theme.palette.primary.main,
      transition: "all 0.5s ease-in-out",
      ".toggle": {
        transition: "all 0.5s ease-in-out",
        svg: {
          path: {
            transition: "0.5s ease-in-out",
            fill: theme.palette.primary.main,
          },
        },
      },
    },
  },
  "&.rep-form-err": {
    ".accordion-title": {
      border: `1px solid ${theme.palette.error.main}`,
    },
    "&.active": {
      ".accordion-title": {
        border: "unset",
      },
    },
  },
  "&.preview-modal-acc": {
    "&:not(:last-child)": {
      marginBottom: "1rem",
    },
    ".accordion-title": {
      img: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        objectFit: "scale-down",
        marginRight: 16,
      },
    },
    ".preview-last-item-divider": {
      display: "none",
      "&:not(:last-child)": {
        display: "block",
        marginBottom: "1rem",
        border: `1px dashed ${theme.palette.grey.light}`,
      },
    },
  },
  "&.dnd-order": {
    ".accordion-content": {
      "&.active": {
        maxHeight: "unset",
      },
    },
  },
}));

export const StyledInput = styled(TextField)(
  ({ theme, fontSize, textStyle, fontWeight, letterSpacing, lineSpacing, fontFamily }) => ({
    ".MuiInputBase-input": {
      fontWeight: fontWeight,
      fontSize: `${fontSize}rem`,
      fontStyle: textStyle,
      letterSpacing: letterSpacing,
      lineHeight: lineSpacing,
      fontFamily: fontFamily,
      height: 75,
      "&.Mui-disabled": {
        textFillColor: "#000",
      },
    },
    ".MuiInputBase-multiline": {
      height: 108,
    },
  })
);
