import React, { useEffect } from "react";

import { FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

//
import { StyledSwitch } from "../../Pages/Settings/StyleV2/StyledWrapp";
import { handleTypographyFontFamilyChange } from "../../Redux/slices/settingsSlice";

export default function PreviewSwitch({ changeFont, setChangeFont, typography, formEl }) {
  const dispatch = useDispatch();
  const activeSlide = useSelector((state) => state.settings.typograpghyForm.activeSlide);
  const palettes = useSelector((state) => state.settings.styles.palettes);

  useEffect(() => {
    const typographyActiveSlide = palettes[activeSlide].typography;
    const isActiveSwitch = typographyActiveSlide[formEl][0][`${formEl}FontFamily`] !== "mainFont";
    setChangeFont(isActiveSwitch);
  }, [activeSlide, palettes]);

  const handleChangeFont = () => {
    const switchValue = !changeFont;
    setChangeFont(switchValue);
    const elementValue = switchValue ? "secondaryFont" : "mainFont";
    dispatch(handleTypographyFontFamilyChange({ element: formEl, elementValue }));
  };

  return (
    <FormControlLabel
      control={
        <StyledSwitch
          sx={{ m: 1 }}
          defaultChecked={false}
          checked={changeFont}
          disabled={!typography?.secondaryFont?.value}
          onChange={() => handleChangeFont()}
        />
      }
      label="Switch to secondary font"
      labelPlacement="start"
    />
  );
}
