import React from "react";

import {
  ErrorWrap,
  GoToPageWrap,
  PaginationTextField,
  StyledPagination,
  StyledPaginationWrap,
  StyledTablePagination,
  SyledArrowForwardIosIcon,
} from "../Pagination/StyledPagination";

const CustomPagination = ({
  data,
  page,
  rowsPerPage,
  goToPageInput,
  paginationPageOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  handleChangePagination,
  handleKeyDown,
  handleGoToPageOnEnter,
  handleGoToPageOnClick,
}) => {
  const rowsPerPageOptions = [3, 6, 12, ...(data && data?.length ? [{ value: -1, label: "All" }] : [])];
  return (
    <StyledPaginationWrap>
      <StyledTablePagination
        component="div"
        labelDisplayedRows={() => false}
        rowsPerPageOptions={rowsPerPageOptions}
        count={data && data.length}
        page={data.length <= 0 ? 0 : page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <StyledPagination
        hideNextButton={true}
        hidePrevButton={true}
        onChange={handleChangePagination}
        page={page + 1}
        count={paginationPageOptions}
        size="small"
      />
      <GoToPageWrap>
        <p style={{ margin: 0 }}>Go to page:</p>
        <div style={{ position: "relative" }}>
          {!goToPageInput.isValid && <ErrorWrap>Invalid</ErrorWrap>}
          <PaginationTextField
            onChange={handleGoToPageOnEnter}
            onKeyDown={handleKeyDown}
            label=""
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
        <SyledArrowForwardIosIcon fontSize="small" onClick={handleGoToPageOnClick} />
      </GoToPageWrap>
    </StyledPaginationWrap>
  );
};

export default CustomPagination;
