import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import settingsService from "../services/settingsServices";

export const getPortalSettings = createAsyncThunk("settings/all", async (data, { getState, dispatch }) => {
  const res = await settingsService.getAll();
  return res?.data;
});

export const getLatestPortalSettings = createAsyncThunk("settings/latest-all", async (data, { getState, dispatch }) => {
  const res = await settingsService.getAll();
  return res?.data;
});

export const getFonts = createAsyncThunk("settings/fonts", async () => {
  const res = await settingsService.getFonts();
  return res?.data;
});

export const getIcons = createAsyncThunk("settings/icons", async () => {
  const res = await settingsService.getIcons();
  const newIconsArr = res?.data?.map((el) => {
    const newIconsObj = { iconPack: el.slug, iconSize: "16" };
    Object.entries(el).forEach(([key, value]) => {
      if (key === "slug") return null;
      const folderName = key
        .replace(/([A-Z])/g, " $1")
        .trim()
        .split(" ")[0];
      Object.assign(newIconsObj, { [key]: [{}] });
      value.forEach((icon, index) => {
        Object.assign(newIconsObj, {
          ...newIconsObj,
          [key]: [
            {
              ...newIconsObj[key][0],
              [icon]: {
                name: icon,
                id: `${process.env.REACT_APP_PUBLIC_RELATIVE_PATH}/icons/${el.slug}/${folderName}/${icon}.svg`,
                url: `${process.env.REACT_APP_PUBLIC_RELATIVE_PATH}/icons/${el.slug}/${folderName}/${icon}.svg`,
              },
            },
          ],
        });
      });
    });
    return newIconsObj;
  });
  return newIconsArr;
});

export const getStylesV2 = createAsyncThunk("settings/style", async (needsRestrusture, { getState, dispatch }) => {
  const res = await settingsService.getStylesV2();
  let styles = res?.data[0];

  if (needsRestrusture) {
    // this function if true parameter is passed to action, is used to restructure the layout object
    // to be compatible with the new schema, because schema uses a repeatable component to render the layout object,
    // so it needs to be restructured to be an array of objects
    const keys = ["rem", "padding", "margin", "radius"];
    const newLayoutObj = { rem: [{}], padding: [{}], margin: [{}], radius: [{}] };
    keys.forEach((keyValue) => {
      Object.entries(styles?.layout).forEach(([key, value]) => {
        if (["id", "baseRemSize"]?.includes(key)) return null;
        Object.assign(newLayoutObj[keyValue][0], {
          ...newLayoutObj[keyValue][0],
          ...(key.includes(keyValue) && { [key]: value }),
        });
      });
    });

    const generateTypographyObj = (obj, idx) => {
      const typograpghyKeys = [
        "headingOne",
        "headingTwo",
        "headingThree",
        "headingFour",
        "subtitleOne",
        "subtitileTwo",
        "bodyTextOne",
        "bodyTextTwo",
        "label",
        "caption",
        "overline",
      ];
      const newTypographyObj = { mainFont: obj?.mainFont || "", secondaryFont: obj?.secondaryFont || "" };
      const objToLoop = obj ?? { mainFont: "", secondaryFont: "" };
      typograpghyKeys.forEach((typoKey) => {
        Object.assign(newTypographyObj, { [typoKey]: [{}] });
        if (!obj) {
          Object.assign(objToLoop, { [typoKey]: [{}] });
        } else {
          Object.entries(objToLoop).forEach(([key, value]) => {
            Object.assign(newTypographyObj, {
              ...newTypographyObj,
              [typoKey]: [
                {
                  ...newTypographyObj[typoKey][0],
                  ...(key.includes(typoKey) && { [key]: value }),
                },
              ],
            });
          });
        }
      });
      return newTypographyObj;
    };

    const generateIconsObj = (obj, idx) => {
      const iconKeys = ["cashier", "casino", "default", "player", "social"];
      const newIconsObj = { iconPack: obj?.iconPack || "", iconSize: obj?.iconSize || "" };
      const objToLoop = obj ?? { iconPack: "", iconSize: "" };
      iconKeys.forEach((typoKey) => {
        Object.assign(newIconsObj, { [typoKey]: [{}] });
        if (!obj) {
          Object.assign(objToLoop, { [typoKey]: [{}] });
        } else {
          Object.entries(objToLoop).forEach(([key, value]) => {
            Object.assign(newIconsObj, {
              ...newIconsObj,
              [typoKey]: [
                {
                  ...newIconsObj[typoKey][0],
                  ...(key.includes(typoKey) && { [key]: value }),
                },
              ],
            });
          });
        }
      });
      return newIconsObj;
    };

    // restructuring a palette object response, so its easiear to render tab schema elements
    // each palette object is an object with a general, colors, icons and typography objects inside it, and other fields
    const newPalettes = styles?.palettes?.map((palette, idx) => {
      const fieldsToIgnore = ["id", "colors", "icons", "typography"];
      const newPaletteObj = { general: { logoDivider: "", iconDivider: "" } };
      const colorDividers = {
        primaryDivider: "",
        secondaryDivider: "",
        tertiaryDivider: "",
        emptyDivider: "",
        emptyDivider1: "",
        neutralDivider: "",
        emptyDivider2: "",
        emptyDivider3: "",
        errorDivider: "",
        emptyDivider4: "",
        emptyDivider5: "",
        customColorOneDivider: "",
      };

      Object.entries(palette).forEach(([key, value]) => {
        const valueObj =
          key === "colors" && Boolean(value) ? { [key]: { ...value, ...colorDividers } } : { [key]: value };
        const nonGeneralValue = {
          typography: { [key]: generateTypographyObj(value, idx) },
          icons: { [key]: generateIconsObj(value, idx) },
        };
        return Object.assign(newPaletteObj, {
          ...newPaletteObj,
          ...(fieldsToIgnore?.includes(key)
            ? nonGeneralValue[key] || valueObj
            : {
                general: {
                  ...newPaletteObj?.general,
                  [key]: value,
                },
              }),
        });
      });
      return newPaletteObj;
    });

    // restructuring a elevation object response, so its easiear to render tab schema elements
    // each elevation object is an object with 2 object inside of it, and fields are resorted to be either in boxShadow or in scrim object
    const newElevationObj = { boxShadow: [{}], scrim: [{}] };
    Object.entries(styles?.elevation).forEach(([key, value]) => {
      const keyName = key.includes("scrim") ? "scrim" : "boxShadow";
      Object.assign(newElevationObj[keyName][0], {
        ...newElevationObj[keyName][0],
        [key]: value,
      });
    });

    // restructuring a button object response, so its easiear to render tab schema elements
    const buttonKeys = ["default", "hovered", "focused", "pressed", "disabled"];
    const newButtons = styles?.buttons?.map((button) => {
      const newButtonObj = { ...button };
      buttonKeys.forEach((btnKey) => {
        Object.assign(newButtonObj, { [btnKey]: [{}] });
        Object.entries(button?.stateStyles).forEach(([key, value]) => {
          Object.assign(newButtonObj[btnKey][0], {
            ...newButtonObj[btnKey][0],
            ...(key.includes(btnKey) && { [key]: value }),
          });
        });
      });
      // removing stateStyles from the object
      return (({ stateStyles, ...e }) => e)(newButtonObj);
    });

    // restructuring a button object response, so its easiear to render tab schema elements
    const formKeys = ["default", "hovered", "selected", "focused", "disabled", "pressed", "error", "success"];
    const newFormFields = styles?.formFields?.map((form) => {
      const newFormObj = { ...form };
      formKeys.forEach((formKey) => {
        Object.assign(newFormObj, { [formKey]: [{}] });
        Object.entries(form?.stateStyles).forEach(([key, value]) => {
          Object.assign(newFormObj[formKey][0], {
            ...newFormObj[formKey][0],
            ...(key.includes(formKey) && { [key]: value }),
          });
        });
      });
      // removing stateStyles from the object
      return (({ stateStyles, ...e }) => e)(newFormObj);
    });

    styles = {
      ...styles,
      layout: {
        ...newLayoutObj,
        id: styles?.layout?.id,
        baseRemSize: styles?.layout?.baseRemSize,
      },
      palettes: newPalettes,
      elevation: {
        id: styles?.elevation?.id,
        ...newElevationObj,
      },
      buttons: newButtons,
      formFields: newFormFields,
    };
  }
  return styles;
});

export const uploadCustomFont = createAsyncThunk(
  "settings/fontUpload",
  async ({ font, type, isStyleV2, activePalette }) => {
    const res = await settingsService.uploadCustomFont(font);
    return {
      data: res?.data,
      type,
      ...(isStyleV2 && {
        isStyleV2,
        activePalette,
      }),
    };
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    portalSettings: null,
    latestPortalSettings: null,
    loading: false,
    fonts: null,
    icons: null,
    styles: null,
    typograpghyForm: {
      activeSlide: 0,
      headingOne: "mainFont",
      headingTwo: "mainFont",
      headingThree: "mainFont",
      headingFour: "mainFont",
      subtitleOne: "mainFont",
      subtitileTwo: "mainFont",
      bodyText: "mainFont",
      label: "mainFont",
      caption: "mainFont",
      overline: "mainFont",
    },
    customFonts: {
      main: "",
      text: "",
    },
    customV2Fonts: null,
  },
  reducers: {
    addPalette: (state, action) => {
      return {
        ...state,
        styles: {
          ...state.styles,
          palettes: [...state.styles.palettes, action.payload],
        },
      };
    },
    handleTypographyFontFamilyChange: (state, action) => {
      const { element, elementValue } = action.payload;
      return {
        ...state,
        typograpghyForm: {
          ...state.typograpghyForm,
          [element]: elementValue,
        },
      };
    },
    clearPortalSettings: (state, action) => {
      return {
        ...state,
        portalSettings: null,
      };
    },
    clearCustomFont: (state, action) => {
      return {
        ...state,
        customFonts: {
          ...state.customFonts,
          [action.payload]: "",
        },
      };
    },
    clearCustomV2Font: (state, action) => {
      const newCustomFontsArr = state.customV2Fonts?.map((el, idx) => {
        if (idx !== action.payload.activeSlide) return el;
        return {
          ...el,
          [action.payload.key]: "",
        };
      });

      return {
        ...state,
        customV2Fonts: newCustomFontsArr,
      };
    },
    clearDefaultButton: (state, action) => {
      const buttonsReseted = state.styles.buttons.map((el, idx) => {
        if (el.id !== action.payload.buttonID) return el;
        return {
          id: el.id,
          slug: el.slug,
          height: "",
          showText: true,
          showIcon: false,
          default: [{}],
          hovered: [{}],
          focused: [{}],
          pressed: [{}],
          disabled: [{}],
        };
      });
      return {
        ...state,
        styles: {
          ...state.styles,
          buttons: buttonsReseted,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPortalSettings.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getPortalSettings.fulfilled, (state, action) => {
      return {
        ...state,
        portalSettings: action.payload[0],
        loading: false,
        customFonts: {
          main: action.payload[0]?.style?.fonts?.main === "custommainfont" ? "custommainfont" : "",
          text: action.payload[0]?.style?.fonts?.text === "customtextfont" ? "customtextfont" : "",
        },
      };
    });
    builder.addCase(getLatestPortalSettings.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getLatestPortalSettings.fulfilled, (state, action) => {
      return {
        ...state,
        latestPortalSettings: action.payload[0],
        loading: false,
      };
    });
    builder.addCase(getFonts.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFonts.fulfilled, (state, action) => {
      return {
        ...state,
        fonts: action.payload,
        loading: false,
      };
    });
    builder.addCase(getStylesV2.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getStylesV2.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        styles: action.payload,
        customV2Fonts: action?.payload?.palettes?.map((el) => {
          return {
            main: el?.typography?.mainFont === "custommainfont" ? "custommainfont" : "",
            text: el?.typography?.secondaryFont === "customtextfont" ? "customtextfont" : "",
          };
        }),
      };
    });
    builder.addCase(getIcons.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getIcons.fulfilled, (state, action) => {
      return {
        ...state,
        icons: action.payload,
        loading: false,
      };
    });
    builder.addCase(uploadCustomFont.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(uploadCustomFont.fulfilled, (state, action) => {
      const { activePalette, isStyleV2, type } = action.payload;
      const newV2Fonts = state.customV2Fonts?.map((el, idx) => {
        if (idx !== activePalette) return el;
        return {
          ...el,
          [type]: `custom${type}font`,
        };
      });
      return {
        ...state,
        loading: false,
        ...(isStyleV2
          ? { customV2Fonts: newV2Fonts }
          : {
              customFonts: {
                ...state.customFonts,
                [type]: `custom${type}font`,
              },
            }),
      };
    });
  },
});
const { reducer, actions } = settingsSlice;
export const {
  addPalette,
  handleTypographyFontFamilyChange,
  clearPortalSettings,
  clearCustomFont,
  clearCustomV2Font,
  clearDefaultButton,
} = actions;
export default reducer;
