import dayjs from "dayjs";

export const orderPages = {
  "promotion-orders": ["category"],
  "faq-orders": ["category"],
  "payment-methods-orders": ["currency"],
  "game-orders": ["category", "subCategory", "extraCategories", "provider", "type"],
};

export const forCheck = (categories, providers, orderCategory, page) => {
  // This function returns value which is needed for filtering all page data
  // Sometimes category is slug sometimes is value and sometimes is id that we need to filter allPageData
  const findCategory = categories?.concat(providers)?.find((el) => (el?.slug ?? el?.value) === orderCategory);
  const searchBy = {
    "game-orders": findCategory?.slug,
    "payment-methods-orders": findCategory?.value,
  };
  return searchBy[page] ?? findCategory?.id;
};

export const filterPaymentMethodOrderData = (allData, filterBy, category) => {
  return allData?.multicurrency?.find((el) => el?.[category]?.toLowerCase() === filterBy)?.methods;
};

export const filterOtherOrdersData = (page, allData, filterBy, category) => {
  if (page === "game-orders") {
    return allData?.filter((el) => Boolean(el.available) && el?.[category]?.toString()?.includes(filterBy?.toString()));
  }
  return allData?.filter(
    (el) =>
      el?.[category] === filterBy &&
      el?.publishing?.status === "published" &&
      (el?.publishing?.expireDate ? !dayjs().isAfter(dayjs(el?.publishing?.expireDate).format("YYYY-MM-DD")) : true)
  );
};

export const dataPerPage = (pageData, category, page, orderCategory) => {
  const { categories, providers, all } = pageData;
  const filterBy = forCheck(categories, providers, orderCategory, page);

  return page === "payment-methods-orders"
    ? filterPaymentMethodOrderData(all, filterBy, category)
    : filterOtherOrdersData(page, all, filterBy, category);
};
