import React, { useMemo, useCallback } from "react";

import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";

import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import palette from "./palette";
import shadows, { customShadows } from "./shadows";
import typography from "./typography";
import { generateColorsArray } from "../../Components/Utils/helpers";

export default function ThemeProvider({ children }) {
  const activeTheme = useSelector((state) => state.layout.activeTheme);
  const styles = useSelector((state) => state.settings?.styles);
  const portalSettings = useSelector((state) => state.settings?.portalSettings);

  const generateStyleColors = useCallback(() => {
    if (portalSettings && styles) {
      const findActivePalette = styles?.palettes?.find((el) => Boolean(el?.general?.active)) || styles?.palettes[0];
      const generateV1Colors = generateColorsArray(portalSettings?.style?.main) || [];
      const generateV2Colors = generateColorsArray(findActivePalette?.colors) || [];
      const colorArr = {};
      [...generateV1Colors, ...generateV2Colors].forEach((color) => {
        Object.assign(colorArr, { [`--${color.value}`]: color.color });
      });
      return colorArr;
    }
    // fix for MUI components to avoid "undefined" palettes
    return {};
  }, [portalSettings, styles]);

  const overrideActiveTheme = (palette) => {
    const portalPalette = generateStyleColors();
    const themeSlug = localStorage.getItem("theme") || activeTheme;
    const findThemePalette = palette?.themePalettes?.find((el) => el.slug === themeSlug);
    const newPalette = { ...palette, primary: findThemePalette, portalPalette };
    return newPalette;
  };

  const themeOptions = useMemo(
    () => ({
      palette: overrideActiveTheme(palette),
      shape: { borderRadius: 10 },
      typography,
      shadows,
      customShadows,
      breakpoints,
    }),
    [activeTheme, portalSettings, styles]
  );
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
