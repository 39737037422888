import { TabPanel } from "@mui/lab";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "relative",
  width: "100%",
  "&.select": {
    fieldset: {
      borderWidth: 1,
      legend: {
        width: "unset",
      },
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused": {
      fieldset: {
        borderColor: theme.palette.primary.main,
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    ".MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
    ".Mui-disabled": {
      cursor: "not-allowed",
    },
  },
}));

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiFormLabel-root": {
    backgroundColor: "#fff",
    padding: "0 6px",
    "&.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    color: theme.palette.text.primary,
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.common.white,
      width: "100%",
      color: theme.palette.text.primary,
      justifyContent: "center",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const ModalContent = styled("div")(({ theme }) => ({
  ".modal-input-wrapp": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "10px 0",
    ".MuiInputBase-root.MuiOutlinedInput-root.select": {
      height: 56,
      ".MuiSelect-select": {
        paddingLeft: 18,
      },
      input: {
        padding: 8,
      },
    },
  },
  ".MuiTabPanel-root ": {
    padding: "15px 0",
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
}));
