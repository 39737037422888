import React from "react";

import { Search } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, InputAdornment } from "@mui/material";

import { ActionRowWrap, SearchTextField } from "./StyledRevisions";

const ActionHeader = ({ isCheck, onChangeCheckbox, onChangeSearch }) => {
  return (
    <ActionRowWrap>
      <FormGroup>
        <FormControlLabel
          sx={{ margin: 0 }}
          control={<Checkbox id="check_stat_draft" checked={isCheck} onChange={onChangeCheckbox} />}
          label="Show only deleted"
        />
      </FormGroup>
      <SearchTextField
        style={{ width: "250px" }}
        id="outlined-search"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="standard"
        type="search"
        onChange={onChangeSearch}
      />
    </ActionRowWrap>
  );
};

export default ActionHeader;
