import React, { useState } from "react";

import { Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { ItemLabel, ItemValue, ItemWrap, StyledPopoverWrapp, StyledPreviewPopover } from "./StyledSubComponents";

const PreviewFieldItem = ({ itemKey, itemLabel, itemValue }) => {
  const [popover, setPopover] = useState(null);
  const countriesList = useSelector((state) => state?.form?.staticData?.["st-countries-list"]);

  const handlePopoverOpen = (event) => setPopover(event ? event.currentTarget : null);

  const getCountryName = (tags) =>
    tags?.split(",").map((tag) => {
      const countriesName = countriesList?.find((country) => country?.value === tag);
      return countriesName?.label;
    });

  const generateGeoBlockValue = (geoblock) => {
    const open = Boolean(popover);
    const id = open ? "simple-popover" : undefined;

    if (Boolean(geoblock) && geoblock?.length && geoblock !== "undefined") {
      return (
        <StyledPopoverWrapp>
          <Button aria-describedby={id} variant="contained" onClick={(e) => handlePopoverOpen(e)}>
            {getCountryName(geoblock).length === 1
              ? getCountryName(geoblock)[0]
              : `${getCountryName(geoblock)[0]} and ${getCountryName(geoblock).length - 1} more`}
          </Button>
          <StyledPreviewPopover
            id={id}
            open={open}
            anchorEl={popover}
            onClose={() => handlePopoverOpen()}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {getCountryName(geoblock).map((country, index) => {
              return <Typography key={`preview-${index}`}>{country}</Typography>;
            })}
          </StyledPreviewPopover>
        </StyledPopoverWrapp>
      );
    }
    return "There is no blocked country";
  };

  const generateFieldValue = () => {
    const publishingKeys = ["publishDate", "expireDate", "maintenanceStart", "maintenanceEnd"];
    if (itemKey === "geoFilter") {
      return generateGeoBlockValue(itemValue?.blocked);
    } else if (publishingKeys.includes(itemKey)) {
      return dayjs(itemValue).format("DD MMMM YYYY HH:mm");
      // else for imageELement
    } else if (itemValue?.hasOwnProperty("ext") && itemValue?.hasOwnProperty("previewUrl")) {
      return itemValue?.url ? (
        <img src={itemValue?.url} alt="" style={{ width: "50px", height: "50px", borderRadius: "5px" }} />
      ) : (
        "Not defined"
      );
    }
    return itemValue.toString()?.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const cleanedFieldValue =
    typeof generateFieldValue() === "string" && generateFieldValue()?.length > 20
      ? generateFieldValue()?.substring(0, 17) + "..."
      : generateFieldValue();

  return (
    <ItemWrap item md={4}>
      <ItemLabel>{itemLabel}</ItemLabel>
      <ItemValue {...(typeof generateFieldValue() === "string" && { title: generateFieldValue() })}>
        {cleanedFieldValue}
      </ItemValue>
    </ItemWrap>
  );
};

export default PreviewFieldItem;
