import React from "react";

//
import { CircularProgress } from "@mui/material";

//
import ImageLibrary from "../../components/ImageLibrary";
//
import { DragDropSelect, StyledIconWrapperBox } from "../../Styled";

export default function OverrideImgSelector({
  handleActiveDropzone,
  getInputProps,
  isLoading,
  getRootProps,
  overrideIcons,
  handleSetOverrideIcon,
}) {
  const fields = ["iconOverride", "iconOverrideHover"];

  const generateFileFieldLabel = (key) => {
    const fieldLabel = key.charAt(0).toUpperCase() + key.slice(1);
    return fieldLabel?.replace(/([A-Z])/g, " $1").trim();
  };

  return (
    <StyledIconWrapperBox>
      {fields?.map((field, idx) => {
        return (
          <div className="field-wrapper" key={idx}>
            <h1>{generateFileFieldLabel(field)}</h1>
            <div className="upload-library-wrapper">
              <DragDropSelect className="drop-zone">
                <div {...getRootProps({ className: "dropzone", onClick: () => handleActiveDropzone(field) })}>
                  <input {...getInputProps()} />
                  <div className="drop-zone-img-wrapp">
                    <div className="img-wrapper drop-zone-wrapper">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <span>Drop your files here</span>
                          <span>or</span>
                          <p>Select files</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </DragDropSelect>
              <ImageLibrary
                libraryKey={field}
                overrideImages={overrideIcons}
                handleSetOverrideImage={(key, obj) => handleSetOverrideIcon(key, obj)}
              />
            </div>
          </div>
        );
      })}
    </StyledIconWrapperBox>
  );
}
