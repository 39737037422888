import React, { useEffect } from "react";

import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { relatedPhotoByID } from "../../../../Redux/slices/mediaSlice";
import { getPreviewDataByID } from "../../../../Redux/slices/modalSlice";
import { renderTitleAndPathComponentFraction } from "../../../Utils/TemplateHelpers/modalHelpers";
import { StyledImageRelatedPages, StyledImageRelatedPagesContainer } from "../styledImageEdit";

export default function Related() {
  const dispatch = useDispatch();
  const { relatedLoading, editPhoto, relatedPhotos } = useSelector((state) => state.media);

  useEffect(() => {
    // dispatch(relatedPhotoByID(editPhoto?.id));
    // return (
    //   relatedPhotos?.length &&
    //   relatedPhotos?.map((photo) => {
    //     return dispatch(getPreviewDataByID({ pageType: photo?.__contentType, pageID: photo?.id, isRevisions: false }));
    //   })
    // );
    dispatch(relatedPhotoByID(editPhoto?.id)).then((res) => {
      return (
        res?.payload?.related.length &&
        res.payload?.related.forEach((photo) => {
          dispatch(getPreviewDataByID({ pageType: photo?.__contentType, pageID: photo?.id, isRevisions: false }));
        })
      );
    });
  }, [editPhoto?.id]);

  const renderRelatedContent = () => {
    if (!relatedPhotos?.related?.length) {
      return <p>There are no related pages to this image</p>;
    }

    return renderTitleAndPathComponentFraction(relatedPhotos);
  };

  return (
    <StyledImageRelatedPages>
      <h4>Image Related Pages</h4>
      <StyledImageRelatedPagesContainer>
        {relatedLoading ? <CircularProgress /> : renderRelatedContent()}
      </StyledImageRelatedPagesContainer>
    </StyledImageRelatedPages>
  );
}
