import { styled } from "@mui/system";

const handleType = (type, color, variant) => {
  const mainColor = color?.palette[variant]?.main;
  const lightColor = color?.palette[variant]?.light;
  const typeObj = {};
  switch (type) {
    case "outlined":
      typeObj.border = `1px solid ${mainColor}`;
      typeObj.background = "#fff";
      typeObj.color = mainColor;
      break;
    case "filled":
      typeObj.background = `${mainColor}`;
      typeObj.border = "none";
      typeObj.color = "#FFF";
      break;
    case "light":
      typeObj.background = `${lightColor}`;
      typeObj.border = "none";
      typeObj.color = `${mainColor}`;
      break;
    case "empty":
      typeObj.background = "none";
      typeObj.border = "none";
      typeObj.color = `${mainColor}`;
      break;
    default:
      break;
  }
  return typeObj;
};

export const StyledCustomButton = styled("button")(
  ({ theme, styleType, variant, iconPosition, isLoading, disabled, hover }) => ({
    padding: "12px 16px",
    borderRadius: 10,
    fontSize: "16px",
    ...handleType(styleType, theme, variant),
    display: "flex",
    opacity: isLoading ? 0.75 : 1,
    flexDirection: iconPosition === "left" ? "row" : "row-reverse",
    cursor: isLoading ? "no-drop" : "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s all ease-in-out",
    ...(hover && {
      "&:hover": {
        background: theme.palette.primary.light,
        transition: "0.3s all ease-in-out",
      },
    }),
    ...(disabled && {
      "&:disabled": {
        opacity: 0.75,
      },
      "&:hover": {
        cursor: "not-allowed",
      },
    }),
    "&:focus": {
      outline: "none",
    },
    ".btn-progress": {
      margin: isLoading ? "0 0 0 10px" : 0,
      color: theme.palette.common.white,
    },
    svg: {
      margin: iconPosition === "left" ? "0 10px 0 0" : isLoading ? 0 : "0 0 0 10px ",
    },
    ".btn-label": {
      display: "flex",
      alignItems: "center",
      height: "22px",
    },
  })
);

export const StyledCollapseButton = styled("ul")(({ theme, isOpen, styleType, variant, iconPosition, isLoading }) => ({
  display: "flex",
  position: "relative",
  marginBottom: 0,
  cursor: isLoading ? "no-drop" : "pointer",
  pointerEvents: isLoading && "none",
  ".accordion-title": {
    opacity: isLoading ? 0.75 : 1,
    padding: "12px 16px",
    borderRadius: 10,
    display: "flex",
    flexDirection: iconPosition === "left" ? "row-reverse" : "row",
    ...handleType(styleType, theme, variant),

    ".btn-progress": {
      margin: isLoading ? "0 0 0 10px" : 0,
      color: theme.palette.common.white,
    },

    ".toggle": {
      display: "flex",
      transition: "0.3s ease",
      margin: iconPosition === "left" ? "0 10px 0 0" : "0 0 0 10px ",
      ".MuiSvgIcon-root": {
        width: 15,
        transition: "all 0.5s cubic-bezier(0, 1, 0, 1)",
        transform: "rotateZ(90deg)",
      },
      "&.active": {
        ".MuiSvgIcon-root": {
          transition: "all 0.5s cubic-bezier(0, 1, 0, 1)",
          transform: "rotateZ(270deg)",
        },
      },
    },
  },
  ".accordion-content": {
    position: "absolute",
    minWidth: 170,
    width: 170,
    maxHeight: 1000,
    top: 52,
    right: 0,
    zIndex: 1,
    background: theme.palette.common.white,
    padding: 16,
    borderRadius: 10,
    overflowY: "scroll",
    overflowX: "hidden",
    border: `1px solid ${theme.palette.grey.light}`,
    transition: "max-height 0.5s ease-in-out, border 0.5s ease-in-out, padding 0.5s ease-in-out",
    "&::-webkit-scrollbar": {
      width: 0.2,
    },

    ".acc-wrapp": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      li: {
        height: 40,
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        color: theme.palette[variant].main,
        listStyle: "none",
        padding: "11px 16px",
        borderRadius: 8,
        "&:hover": {
          background: theme.palette[variant].lighter,
        },
      },
    },
    "&.active": {
      zIndex: -1,
      maxHeight: 0,
      padding: 0,
      border: `1px solid transparent`,
      background: `transparent`,
      transition:
        "max-height 1s cubic-bezier(0, 1, 0, 1), border 0.5s ease-in-out, padding 1s ease-in-out, background 1s ease-in-out",
    },
  },
}));
