import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Pagination, TablePagination, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPaginationWrap = styled("div")(({ theme, type, backgroundColor, padding }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: type === "basic" ? "center" : "space-between",
  backgroundColor: backgroundColor || theme.palette.grey[100],
  padding: padding || "0 16px",
  border: "none",
}));

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
  },
  ".MuiTablePagination-selectLabel": { margin: "0" },
  ".MuiToolbar-root-MuiTablePagination-toolbar": { padding: "0" },
  ".MuiTablePagination-actions": { display: "none" },
  ".MuiSvgIcon-root": { color: theme.palette.primary.main },
  ".MuiTablePagination-select": {
    fontSize: "14px",
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 24px 0 8px !important",
  },
}));

export const StyledPagination = styled(Pagination)(({ theme, styletype }) => ({
  button: {
    backgroundColor: styletype === "widget" ? theme.palette.grey[300] : null,
    borderRadius: styletype === "widget" ? "5px" : null,
    "&.Mui-selected": {
      backgroundColor: styletype === "widget" ? theme.palette.primary.light : "inherit !important",
      color: theme.palette.primary.main,
      borderRadius: styletype === "widget" ? "5px" : null,
    },
    "&:focus": {
      outline: "none !important",
    },
  },
}));

export const PaginationTextField = styled(TextField)(({ theme }) => ({
  width: "32px",
  color: theme.palette.primary.main,
  ".MuiInputBase-input": { padding: 0 },
  ".MuiInput-underline:after": { borderBottom: `1px solid ${theme.palette.primary.main} !important` },
  ".MuiInput-underline:before": { borderBottom: `1px solid ${theme.palette.primary.main} !important` },
}));

export const SyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

export const GoToPageWrap = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "18px",
  alignItems: "center",
}));

export const ErrorWrap = styled("span")(({ theme }) => ({
  position: "absolute",
  display: "inline-block",
  top: "18px",
  color: theme.palette.error.main,
  fontSize: 12,
}));

export const EmptyPlaceholder = styled("div")(({ theme }) => ({
  width: "160px",
}));
