import { Grid } from "@material-ui/core";
import { MenuItem, Skeleton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const getHex = (theme, color, defColor) => {
  // since the color values are "var(--colorName)", remove "var" and brackets
  const removeCharacters = color?.slice(4);
  const finalWords = removeCharacters?.slice(0, removeCharacters.length - 1);
  // search for hex code in colors by colorName
  const findHexCode = theme.palette?.portalPalette?.[finalWords];
  const finalHex = findHexCode !== undefined ? findHexCode : defColor;
  return finalHex;
};

export const handleSize = (size, type) => {
  const typeObj = {};
  const defaultSize = size || "s"; // if cms doesn't return size, default to small

  if (type === "thumb") {
    switch (defaultSize) {
      case "s":
        typeObj.width = "18px";
        typeObj.height = "18px";
        break;
      case "m":
        typeObj.width = "24px";
        typeObj.height = "24px";
        break;
      case "l":
        typeObj.width = "30px";
        typeObj.height = "30px";
        break;
      default:
        break;
    }
  } else if (type === "track") {
    switch (defaultSize) {
      case "s":
        typeObj.width = "39px";
        typeObj.height = "24px";
        typeObj.borderRadius = "35px";
        break;
      case "m":
        typeObj.width = "52px";
        typeObj.height = "32px";
        typeObj.borderRadius = "35px";
        break;
      case "l":
        typeObj.width = "65px";
        typeObj.height = "40px";
        typeObj.borderRadius = "35px";
        break;
      default:
        break;
    }
  } else if (type === "thumb-padding") {
    switch (defaultSize) {
      case "s":
        typeObj.top = "3px";
        typeObj.left = "13px";
        break;
      case "m":
        typeObj.top = "6px";
        typeObj.left = "21px";
        break;
      case "l":
        typeObj.top = "9px";
        typeObj.left = "28px";
        break;
      default:
        break;
    }
  } else if (type === "thumb-translate") {
    switch (defaultSize) {
      case "s":
        typeObj.transform = "translateX(13px)"; // formula = thumb width - thumb padding
        break;
      case "m":
        typeObj.transform = "translateX(20px)";
        break;
      case "l":
        typeObj.transform = "translateX(25px)";
        break;
      default:
        break;
    }
  } else {
    switch (defaultSize) {
      case "s":
        typeObj.width = "76px";
        typeObj.height = "42px";
        break;
      case "m":
        typeObj.width = "103px";
        typeObj.height = "54px";
        break;
      case "l":
        typeObj.width = "130px";
        typeObj.height = "66px";
        break;
      default:
        break;
    }
  }

  return typeObj;
};

export const StyledWrapp = styled(Grid)(({ theme }) => ({}));

export const StyledTabListWrapp = styled(Grid)(({ theme, isDisabled }) => ({}));

export const StyledTabList = styled("div")(({ theme, isDisabled }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: 16,
  border: `2px solid ${theme.palette.primary.light}`,
  borderRadius: 10,
  ...(isDisabled && { cursor: "not-allowed" }),
}));

export const StyledTabListButton = styled("div")(({ theme, isDisabled }) => ({
  width: "100%",
  height: 40,
  fwontSize: 14,
  fontWeight: 500,
  color: theme.palette.grey.main,
  border: "none",
  borderRadius: 10,
  backgroundColor: "transparent",
  transition: "all 0.3s ease",
  textAlign: "left",
  padding: "0 16px",
  cursor: isDisabled ? "not-allowed" : "pointer",
  pointerEvents: isDisabled ? "none" : "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  "&.active": {
    color: isDisabled ? theme.palette.grey.main : theme.palette.primary.main,
    backgroundColor: isDisabled ? theme.palette.grey.light : theme.palette.primary.light,
  },
  "&:not(:last-child)": {
    marginBottom: 8,
  },
}));

export const StyledPalettePicker = styled("div")(({ theme, isDisabled }) => ({
  width: "100%",
  position: "relative",
  marginBottom: 24,
  ...(isDisabled && { cursor: "not-allowed" }),
  ".palette-slider-item": {
    minHeight: 300,
    border: `2px solid ${theme.palette.primary.light}`,
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 16px 8px",
    gap: 16,
    borderRadius: 10,
    transition: "all 0.3s ease",
    ".palette-tabs-head": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      p: {
        margin: 0,
        color: theme.palette.grey.main,
        fontWeight: 700,
        fontSize: 16,
        transition: "all 0.3s ease",
        textAlign: "center",
        marginBottom: 16,
      },
      ".icon": {
        img: {
          width: "50%",
          transition: "all 0.3s ease",
          margin: "0 auto",
        },
        "&:not(.active)": {
          img: {
            filter: "grayscale(1)",
          },
        },
      },
      button: {
        margin: "24px auto 8px",
        fontWeight: 500,
        fontSize: 16,
        padding: "12px 16px",
        color: theme.palette.grey.main,
        backgroundColor: theme.palette.grey.light,
        borderRadius: 10,
        border: "none",
        transition: "all 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
        },
        "&.active": {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        },
      },
    },
    ".palette-tabs-body": {
      width: "100%",
    },
    ".palette-tabs-footer": {
      width: "100%",
      label: {
        width: "100%",
        margin: 0,
        ".MuiTypography-root": {
          marginRight: "auto",
        },
      },
    },
    "&.active": {
      ".palette-tabs-head": {
        p: {
          color: theme.palette.primary.main,
        },
        img: {
          filter: "grayscale(0)",
        },
        button: {
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

export const StyledSwitch = styled(Switch)(({ theme, isDisabled, disabled }) => ({
  padding: 8,
  margin: "0 !important",
  height: "40px !important",
  width: "60px !important",
  cursor: isDisabled || disabled ? "not-allowed" : "pointer",
  pointerEvents: isDisabled ? "none" : "auto",
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 18,
      height: 18,
    },
    "&:before": {
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.common.white)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 18,
    height: 18,
    margin: 2,
    backgroundColor: theme.palette.common.white,
  },
  ".MuiSwitch-switchBase.Mui-checked": {
    "&+ .MuiSwitch-track": {
      opacity: "1 !important",
    },
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
}));

export const StyledNextArrow = styled("div")(({ theme }) => ({
  right: "-16px !important",
  width: "32px !important",
  height: "32px !important",
  borderRadius: "50%",
  boxShadow: `0px 0px 4px ${alpha(theme.palette.primary.main, 0.25)}`,
  backgroundColor: `${theme.palette.common.white} !important`,
  display: "flex !important",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9,
  svg: {
    path: {
      fill: theme.palette.primary.main,
    },
  },
  "&:before": {
    display: "none",
  },
  "&.slick-disabled": {
    boxShadow: `0px 0px 4px ${alpha(theme.palette.grey.main, 0.25)}`,
    cursor: "not-allowed",
    svg: {
      path: {
        fill: theme.palette.grey.main,
      },
    },
  },
}));

export const StyledPrevArrow = styled("div")(({ theme }) => ({
  left: "-16px !important",
  width: "32px !important",
  height: "32px !important",
  borderRadius: "50%",
  boxShadow: `0px 0px 4px ${alpha(theme.palette.primary.main, 0.25)}`,
  backgroundColor: `${theme.palette.common.white} !important`,
  display: "flex !important",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9,
  svg: {
    path: {
      fill: theme.palette.primary.main,
    },
  },
  "&:before": {
    display: "none",
  },
  "&.slick-disabled": {
    boxShadow: `0px 0px 4px ${alpha(theme.palette.grey.main, 0.25)}`,
    cursor: "not-allowed",
    svg: {
      path: {
        fill: theme.palette.grey.main,
      },
    },
  },
}));

export const StyledStyleMainForm = styled("div")(({ theme }) => ({
  paddingLeft: 48,
  form: {
    padding: 0,
    ".pageMainFormCard": {
      paddingTop: 0,
    },
  },
}));

export const StyledVariantPicker = styled("div")(({ theme }) => ({
  ".variant-head": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    p: {
      margin: "0 auto",
      textAlign: "center",
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.grey.main,
    },
    ".add-btn": {
      cursor: "pointer",
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      transition: "all 0.3s ease-in-out",
      borderRadius: 10,
      padding: "8px 24px",
      svg: {
        marginRight: 10,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  ".variant-body": {
    marginTop: 40,
    display: "flex",
    flexWrap: "wrap",
  },
}));

export const StyledPreviewCard = styled("div")(({ theme, colorsPalette, styles, activeElement, checked }) => ({
  ".card-wrapper": {
    backgroundColor: theme.palette.primary.lighter,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 16px",
    margin: 10,
    width: 300,
    height: activeElement === "input-field" ? 200 : 145,
    ".card-header": {
      color: theme.palette.grey[900],
      fontWeight: 600,
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 25px",
      justifyContent: "space-between",
      a: {
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
    ".card-disabled": {
      width: "100%",
      ".MuiCheckbox-root": {
        padding: 0,
        margin: 0,
        color: "#a0adb8",
      },
      label: {
        margin: "0 5px",
        cursor: "pointer",
      },
    },
    ".card-body": {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ".MuiFormControlLabel-root": {
        margin: 0,
      },
      ".select-preview": {
        width: "80%",
      },
      ...(activeElement === "input-field"
        ? {
            ".MuiFormControl-root.preview-textfield": {
              label: {
                color: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultLabelTextColor?.value,
                    colorsPalette?.colors?.surfaceOn
                  ),
                  parseInt(styles?.default?.forms[0]?.defaultPercentLabel?.value) / 100 || 1
                ),
              },
              ".MuiInputBase-root": {
                borderRadius: styles?.radius?.value || "10px",
                width: styles?.size?.value || "200px",
                backgroundColor:
                  alpha(
                    getHex(
                      theme,
                      styles?.default?.forms[0]?.defaultBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFour
                    ),
                    parseInt(styles?.default?.forms[0]?.defaultPercentBackground?.value) / 100 || 1
                  ) || "none",
                boxShadow: styles?.default?.forms[0]?.defaultBoxShadow?.value
                  ? `${styles?.default?.forms[0]?.defaultShadowPosition?.value} ${
                      styles?.default?.forms[0]?.defaultShadowBlur?.value
                    } ${alpha(
                      getHex(theme, styles?.default?.forms[0]?.defaultShadowColor?.value, theme.palette.primary.main),
                      parseInt(styles?.default?.forms[0]?.defaultPercentShadow?.value) / 100 || 1
                    )}`
                  : "none",
                input: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.default?.forms[0]?.defaultTypingTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.default?.forms[0]?.defaultPercentTyping?.value) / 100 || 1
                  ),
                  "&::placeholder": {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.default?.forms[0]?.defaultPlaceholderTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.default?.forms[0]?.defaultPercentPlaceholder?.value) / 100 || 1
                    ),
                  },
                },
                fieldset: {
                  borderWidth: styles?.default?.forms[0]?.defaultBorderWidth?.value || "1px",
                  borderColor: alpha(
                    getHex(theme, styles?.default?.forms[0]?.defaultBorderColor?.value, colorsPalette?.colors?.outline),
                    parseInt(styles?.default?.forms[0]?.defaultPercentBorder?.value) / 100 || 1
                  ),
                },
              },

              "&:hover": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.hovered?.forms[0]?.hoveredLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.hovered?.forms[0]?.hoveredPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.hovered?.forms[0]?.hoveredBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFive
                    ),
                    parseInt(styles?.hovered?.forms[0]?.hoveredPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.hovered?.forms[0]?.hoveredBoxShadow?.value
                    ? `${styles?.hovered?.forms[0]?.hoveredShadowPosition?.value} ${
                        styles?.hovered?.forms[0]?.hoveredShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.hovered?.forms[0]?.hoveredShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.hovered?.forms[0]?.hoveredPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.hovered?.forms[0]?.hoveredTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.hovered?.forms[0]?.hoveredPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.hovered?.forms[0]?.hoveredPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.hovered?.forms[0]?.hoveredPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.hovered?.forms[0]?.hoveredBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.hovered?.forms[0]?.hoveredBorderColor?.value,
                        colorsPalette?.colors?.outline
                      ),
                      parseInt(styles?.hovered?.forms[0]?.hoveredPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              "&:active": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.selected?.forms[0]?.selectedLabelTextColor?.value,
                      colorsPalette?.colors?.primary
                    ),
                    parseInt(styles?.selected?.forms[0]?.selectedPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.selected?.forms[0]?.selectedBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceLight
                    ),
                    parseInt(styles?.selected?.forms[0]?.selectedPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.selected?.forms[0]?.selectedBoxShadow?.value
                    ? `${styles?.selected?.forms[0]?.selectedShadowPosition?.value} ${
                        styles?.selected?.forms[0]?.selectedShadowBlur?.value
                      } ${alpha(
                        getHex(
                          theme,
                          styles?.selected?.forms[0]?.selectedShadowColor?.value,
                          theme.palette.primary.main
                        ),
                        parseInt(styles?.selected?.forms[0]?.selectedPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.selected?.forms[0]?.selectedTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.selected?.forms[0]?.selectedPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.selected?.forms[0]?.selectedPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.selected?.forms[0]?.selectedPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.selected?.forms[0]?.selectedBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.selected?.forms[0]?.selectedBorderColor?.value,
                        colorsPalette?.colors?.primary
                      ),
                      parseInt(styles?.selected?.forms[0]?.selectedPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              "&:focus": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.focused?.forms[0]?.focusedLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.focused?.forms[0]?.focusedPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.focused?.forms[0]?.focusedBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFive
                    ),
                    parseInt(styles?.focused?.forms[0]?.focusedPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.focused?.forms[0]?.focusedBoxShadow?.value
                    ? `${styles?.focused?.forms[0]?.focusedShadowPosition?.value} ${
                        styles?.focused?.forms[0]?.focusedShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.focused?.forms[0]?.focusedShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.focused?.forms[0]?.focusedPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.focused?.forms[0]?.focusedTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.focused?.forms[0]?.focusedPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.focused?.forms[0]?.focusedPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.focused?.forms[0]?.focusedPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.focused?.forms[0]?.outlineWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(theme, styles?.focused?.forms[0]?.outlineColor?.value, colorsPalette?.colors?.primary),
                      parseInt(styles?.focused?.forms[0]?.percentoutline?.value) / 100 || 1
                    ),
                  },
                },
              },

              "&.disabled": {
                pointerEvents: "none",
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.disabled?.forms[0]?.disabledLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceVariantOn
                    ),
                    parseInt(styles?.disabled?.forms[0]?.disabledPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.disabled?.forms[0]?.disabledBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFour
                    ),
                    parseInt(styles?.disabled?.forms[0]?.disabledPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.disabled?.forms[0]?.disabledBoxShadow?.value
                    ? `${styles?.disabled?.forms[0]?.disabledShadowPosition?.value} ${
                        styles?.disabled?.forms[0]?.disabledShadowBlur?.value
                      } ${alpha(
                        getHex(
                          theme,
                          styles?.disabled?.forms[0]?.disabledShadowColor?.value,
                          theme.palette.primary.main
                        ),
                        parseInt(styles?.disabled?.forms[0]?.disabledPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.disabled?.forms[0]?.disabledTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.disabled?.forms[0]?.disabledPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.disabled?.forms[0]?.disabledPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.disabled?.forms[0]?.disabledPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.disabled?.forms[0]?.disabledBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.disabled?.forms[0]?.disabledBorderColor?.value,
                        colorsPalette?.colors?.outlineVariant
                      ),
                      parseInt(styles?.disabled?.forms[0]?.disabledPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              "&.error": {
                label: {
                  color: alpha(
                    getHex(theme, styles?.error?.forms[0]?.errorLabelTextColor?.value, colorsPalette?.colors?.error),
                    parseInt(styles?.error?.forms[0]?.errorPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  borderRadius: styles?.roundness?.value || "10px",
                  width: styles?.size?.value || "200px",
                  backgroundColor:
                    alpha(
                      getHex(
                        theme,
                        styles?.error?.forms[0]?.errorBackgroundColor?.value,
                        colorsPalette?.colors?.surfaceColorFour
                      ),
                      parseInt(styles?.error?.forms[0]?.errorPercentBackground?.value) / 100 || 1
                    ) || "none",
                  boxShadow: styles?.error?.forms[0]?.errorBoxShadow?.value
                    ? `${styles?.error?.forms[0]?.errorShadowPosition?.value} ${
                        styles?.error?.forms[0]?.errorShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.error?.forms[0]?.errorShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.error?.forms[0]?.errorPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.error?.forms[0]?.errorTypingTextColor?.value,
                        colorsPalette?.colors?.errorOn
                      ),
                      parseInt(styles?.error?.forms[0]?.errorPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.error?.forms[0]?.errorPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.error?.forms[0]?.errorPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.error?.forms[0]?.errorBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(theme, styles?.error?.forms[0]?.errorBorderColor?.value, colorsPalette?.colors?.error),
                      parseInt(styles?.error?.forms[0]?.errorPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
                "& + .error-msg": {
                  color: alpha(
                    getHex(theme, styles?.error?.forms[0]?.errorCaptionTextColor?.value, colorsPalette?.colors?.error),
                    parseInt(styles?.error?.forms[0]?.errorPercentCaption?.value) / 100 || 1
                  ),
                },
              },
              "&.success": {
                label: {
                  color: alpha(
                    getHex(theme, styles?.success?.forms[0]?.successLabelTextColor?.value, theme.palette.grey[500]),
                    parseInt(styles?.success?.forms[0]?.successPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  borderRadius: styles?.roundness?.value || "10px",
                  width: styles?.size?.value || "200px",
                  backgroundColor:
                    alpha(
                      getHex(
                        theme,
                        styles?.success?.forms[0]?.successBackgroundColor?.value,
                        theme.palette.primary.lighter
                      ),
                      parseInt(styles?.success?.forms[0]?.successPercentBackground?.value) / 100 || 1
                    ) || "none",
                  boxShadow: styles?.success?.forms[0]?.successBoxShadow?.value
                    ? `${styles?.success?.forms[0]?.successShadowPosition?.value} ${
                        styles?.success?.forms[0]?.successShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.success?.forms[0]?.successShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.success?.forms[0]?.successPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(theme, styles?.success?.forms[0]?.successTypingTextColor?.value, theme.palette.grey[500]),
                      parseInt(styles?.success?.forms[0]?.successPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.success?.forms[0]?.successPlaceholderTextColor?.value,
                          theme.palette.grey[500]
                        ),
                        parseInt(styles?.success?.forms[0]?.successPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.success?.forms[0]?.successBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(theme, styles?.success?.forms[0]?.successBorderColor?.value, theme.palette.grey[500]),
                      parseInt(styles?.success?.forms[0]?.successPercentBorder?.value) / 100 || 1
                    ),
                  },
                  ".field-icon": {
                    fill: styles?.success?.forms[0]?.successCaptionTextColor?.value || theme.palette.success.main,
                  },
                },
                "& + .success-msg": {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.success?.forms[0]?.successCaptionTextColor?.value,
                      theme.palette.success.main
                    ),
                    parseInt(styles?.success?.forms[0]?.successPercentCaption?.value) / 100 || 1
                  ),
                },
              },
            },
          }
        : activeElement === "toggle"
        ? {
            ".MuiFormControlLabel-root": {
              margin: 0,
              ".MuiSwitch-root.preview-switch": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: styles?.size?.value.fieldWidth || "60px",
                // height: styles?.size?.value.fieldHeight || "40px",
                ...handleSize(styles?.size?.value, "field"),
                // unchecked
                ".MuiButtonBase-root": {
                  ...handleSize(styles?.size?.value, "thumb-padding"),
                  ".MuiSwitch-thumb": {
                    borderRadius: styles?.roundness?.value || "50%",
                    // width: styles?.size?.value?.thumb || "20px",
                    // height: styles?.size?.value?.thumb || "20px",
                    ...handleSize(styles?.size?.value, "thumb"),
                    color:
                      styles?.default?.forms[0]?.defaultUnselectedRippleColor?.value ||
                      colorsPalette?.colors?.surfaceOn,
                  },
                  "& + .MuiSwitch-track": {
                    // width: styles?.size?.value?.trackWidth || "30px",
                    // height: styles?.size?.value?.trackHeight || "15px",
                    ...handleSize(styles?.size?.value, "track"),
                    backgroundColor:
                      styles?.default?.forms[0]?.defaultUnselectedBackgroundColor?.value ||
                      colorsPalette?.colors?.surfaceLight,
                  },
                  // checked
                  "&.Mui-checked": {
                    ...handleSize(styles?.size?.value, "thumb-translate"),
                    ".MuiSwitch-thumb": {
                      backgroundColor:
                        styles?.default?.forms[0]?.defaultSelectedRippleColor?.value || colorsPalette?.colors?.primary,
                    },
                    "& + .MuiSwitch-track": {
                      backgroundColor:
                        styles?.default?.forms[0]?.defaultSelectedBackgroundColor?.value ||
                        colorsPalette?.colors?.primaryContainer,
                    },
                  },
                },
              },
              ".MuiTypography-root": {
                color: checked
                  ? styles?.default?.forms[0]?.defaultSelectedLabelColor || colorsPalette?.colors?.surfaceOn
                  : styles?.default?.forms[0]?.defaultUnselectedLabelColor || colorsPalette?.colors?.surfaceOn,
              },
              "&:hover": {
                ".MuiSwitch-root": {
                  ".MuiButtonBase-root": {
                    backgroundColor: alpha(
                      getHex(
                        theme,
                        styles?.default?.forms[0]?.defaultSelectedRippleColor?.value,
                        colorsPalette?.colors?.primary
                      ),
                      0.08
                    ),
                    ".MuiSwitch-thumb": {
                      color:
                        styles?.hovered?.forms[0]?.hoveredUnselectedRippleColor?.value ||
                        colorsPalette?.colors?.surfaceOn,
                    },
                    "& + .MuiSwitch-track": {
                      backgroundColor:
                        styles?.hovered?.forms[0]?.hoveredUnselectedBackgroundColor?.value ||
                        colorsPalette?.colors?.surfaceLight,
                    },
                    // checked
                    "&.Mui-checked": {
                      ".MuiSwitch-thumb": {
                        backgroundColor:
                          styles?.hovered?.forms[0]?.hoveredSelectedRippleColor?.value ||
                          colorsPalette?.colors?.primary,
                      },
                      "& + .MuiSwitch-track": {
                        backgroundColor:
                          styles?.hovered?.forms[0]?.hoveredSelectedBackgroundColor?.value ||
                          colorsPalette?.colors?.primaryContainer,
                      },
                      "& + .MuiTypography-root": {
                        color:
                          styles?.hovered?.forms[0]?.hoveredSelectedLabelColor?.value ||
                          colorsPalette?.colors?.surfaceOn,
                      },
                    },
                  },
                },
                ".MuiTypography-root": {
                  color:
                    styles?.hovered?.forms[0]?.hoveredUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                },
              },

              "&:active": {
                ".MuiSwitch-root": {
                  ".MuiButtonBase-root": {
                    backgroundColor: alpha(
                      getHex(
                        theme,
                        styles?.default?.forms[0]?.defaultSelectedRippleColor?.value,
                        colorsPalette?.colors?.primary
                      ),
                      0.2
                    ),
                    ".MuiSwitch-thumb": {
                      color:
                        styles?.pressed?.forms[0]?.pressedUnselectedRippleColor?.value ||
                        colorsPalette?.colors?.surfaceOn,
                    },
                    "& + .MuiSwitch-track": {
                      backgroundColor:
                        styles?.pressed?.forms[0]?.pressedUnselectedBackgroundColor?.value ||
                        colorsPalette?.colors?.surfaceLight,
                    },
                    // checked
                    "&.Mui-checked": {
                      ".MuiSwitch-thumb": {
                        backgroundColor:
                          styles?.pressed?.forms[0]?.pressedSelectedRippleColor?.value ||
                          colorsPalette?.colors?.primary,
                      },
                      "& + .MuiSwitch-track": {
                        backgroundColor:
                          styles?.pressed?.forms[0]?.pressedSelectedBackgroundColor?.value ||
                          colorsPalette?.colors?.primaryContainer,
                      },
                      "& + .MuiTypography-root": {
                        color:
                          styles?.pressed?.forms[0]?.pressedSelectedLabelColor?.value ||
                          colorsPalette?.colors?.surfaceOn,
                      },
                    },
                  },
                },
                ".MuiTypography-root": {
                  color:
                    styles?.pressed?.forms[0]?.pressedUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                },
              },

              "&:focus": {
                ".MuiSwitch-root": {
                  ".MuiButtonBase-root": {
                    backgroundColor: alpha(
                      getHex(
                        theme,
                        styles?.default?.forms[0]?.defaultSelectedRippleColor?.value,
                        colorsPalette?.colors?.primary
                      ),
                      0.2
                    ),
                    ".MuiSwitch-thumb": {
                      color:
                        styles?.focused?.forms[0]?.focusedUnselectedRippleColor?.value ||
                        colorsPalette?.colors?.surfaceOn,
                    },
                    "& + .MuiSwitch-track": {
                      backgroundColor:
                        styles?.focused?.forms[0]?.focusedUnselectedBackgroundColor?.value ||
                        colorsPalette?.colors?.surfaceLight,
                    },
                    // checked
                    "&.Mui-checked": {
                      ".MuiSwitch-thumb": {
                        backgroundColor:
                          styles?.focused?.forms[0]?.focusedSelectedRippleColor?.value ||
                          colorsPalette?.colors?.primary,
                      },
                      "& + .MuiSwitch-track": {
                        backgroundColor:
                          styles?.focused?.forms[0]?.focusedSelectedBackgroundColor?.value ||
                          colorsPalette?.colors?.primaryContainer,
                      },
                      "& + .MuiTypography-root": {
                        color:
                          styles?.focused?.forms[0]?.focusedSelectedLabelColor?.value ||
                          colorsPalette?.colors?.surfaceOn,
                      },
                    },
                  },
                },
                ".MuiTypography-root": {
                  color:
                    styles?.focused?.forms[0]?.focusedUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                },
              },

              ".MuiFormControlLabel-root.disabled": {
                cursor: "not-allowed",
                pointerEvents: "none",
                opacity: "0.38",
                ".MuiSwitch-root": {
                  ".MuiButtonBase-root": {
                    ".MuiSwitch-thumb": {
                      color:
                        styles?.disabled?.forms[0]?.disabledUnselectedRippleColor?.value ||
                        colorsPalette?.colors?.surfaceOn,
                    },
                    "& + .MuiSwitch-track": {
                      backgroundColor:
                        styles?.disabled?.forms[0]?.disabledUnselectedBackgroundColor?.value ||
                        colorsPalette?.colors?.surfaceLight,
                    },
                    // checked
                    "&.Mui-checked": {
                      ".MuiSwitch-thumb": {
                        backgroundColor:
                          styles?.disabled?.forms[0]?.disabledSelectedRippleColor?.value ||
                          colorsPalette?.colors?.primary,
                      },
                      "& + .MuiSwitch-track": {
                        backgroundColor:
                          styles?.disabled?.forms[0]?.disabledSelectedBackgroundColor?.value ||
                          colorsPalette?.colors?.primaryContainer,
                      },
                      "& + .MuiTypography-root": {
                        color:
                          styles?.disabled?.forms[0]?.disabledSelectedLabelColor?.value ||
                          colorsPalette?.colors?.surfaceOn,
                      },
                    },
                  },
                },
                ".MuiTypography-root": {
                  color:
                    styles?.disabled?.forms[0]?.disabledUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                },
              },
            },
          }
        : activeElement === "radio-button"
        ? {
            margin: 0,
            ".MuiRadio-root.preview-radio": {
              ".MuiSvgIcon-root": {
                width: styles?.size?.value || "30px",
                height: styles?.size?.value || "30px",
              },
              // unselected
              span: {
                color:
                  styles?.default?.forms[0]?.defaultUnselectedButtonColor?.value || colorsPalette?.colors?.surfaceOn,
              },
              // selected
              "&.Mui-checked": {
                span: {
                  color: styles?.default?.forms[0]?.defaultSelectedButtonColor?.value || colorsPalette?.colors?.primary,
                },
                "& + .MuiTypography-root": {
                  color:
                    styles?.default?.forms[0]?.defaultSelectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                },
              },
            },
            ".MuiTypography-root": {
              color: styles?.default?.forms[0]?.defaultUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
            },

            "&:hover": {
              ".MuiRadio-root.preview-radio": {
                span: {
                  color:
                    styles?.hovered?.forms[0]?.hoveredUnselectedButtonColor?.value || colorsPalette?.colors?.surfaceOn,
                },
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultSelectedButtonColor?.value,
                    colorsPalette?.colors?.primary
                  ),
                  0.08
                ),
                "&.Mui-checked": {
                  span: {
                    color:
                      styles?.hovered?.forms[0]?.hoveredSelectedButtonColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.hovered?.forms[0]?.hoveredSelectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color:
                  styles?.hovered?.forms[0]?.hoveredUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },

            "&:active": {
              ".MuiRadio-root.preview-radio": {
                span: {
                  color:
                    styles?.pressed?.forms[0]?.pressedUnselectedButtonColor?.value || colorsPalette?.colors?.surfaceOn,
                },
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultSelectedButtonColor?.value,
                    colorsPalette?.colors?.primary
                  ),
                  0.2
                ),
                "&.Mui-checked": {
                  span: {
                    color:
                      styles?.pressed?.forms[0]?.pressedSelectedButtonColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.pressed?.forms[0]?.pressedSelectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color:
                  styles?.pressed?.forms[0]?.pressedUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },

            "&:focus": {
              ".MuiRadio-root.preview-radio": {
                span: {
                  color:
                    styles?.focused?.forms[0]?.focusedUnselectedButtonColor?.value || colorsPalette?.colors?.surfaceOn,
                },
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultSelectedButtonColor?.value,
                    colorsPalette?.colors?.primary
                  ),
                  0.2
                ),
                "&.Mui-checked": {
                  span: {
                    color:
                      styles?.focused?.forms[0]?.focusedSelectedButtonColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.focused?.forms[0]?.focusedSelectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color:
                  styles?.focused?.forms[0]?.focusedUnselectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },

            ".MuiFormControlLabel-root.disabled": {
              pointerEvents: "none",
              opacity: "0.38",
              ".MuiRadio-root.preview-radio": {
                span: {
                  color:
                    styles?.disabled?.forms[0]?.disabledUnselectedButtonColor?.value ||
                    colorsPalette?.colors?.surfaceVariantOn,
                },
                "&.Mui-checked": {
                  span: {
                    color:
                      styles?.disabled?.forms[0]?.disabledSelectedButtonColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.disabled?.forms[0]?.disabledSelectedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color:
                  styles?.disabled?.forms[0]?.disabledUnselectedLabelColor?.value ||
                  colorsPalette?.colors?.surfaceVariantOn,
              },
            },
          }
        : activeElement === "checkbox"
        ? {
            ".MuiCheckbox-root.preview-checkbox": {
              margin: 0,
              svg: {
                width: styles?.size?.value || "1em",
                height: styles?.size?.value || "1em",
                fill:
                  styles?.default?.forms[0]?.defaultUncheckedCheckboxColor?.value || colorsPalette?.colors?.surfaceOn,
              },
              "&.Mui-checked": {
                svg: {
                  fill: styles?.default?.forms[0]?.defaultCheckedCheckboxColor?.value || colorsPalette?.colors?.primary,
                },
                "& + .MuiTypography-root": {
                  color: styles?.default?.forms[0]?.defaultCheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                },
              },
            },
            ".MuiTypography-root": {
              color: styles?.default?.forms[0]?.defaultUncheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
            },

            "&:hover": {
              ".MuiCheckbox-root.preview-checkbox": {
                svg: {
                  fill:
                    styles?.hovered?.forms[0]?.hoveredUncheckedCheckboxColor?.value || colorsPalette?.colors?.surfaceOn,
                },
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultCheckedCheckboxColor?.value,
                    colorsPalette?.colors?.primary
                  ),
                  0.08
                ),
                "&.Mui-checked": {
                  svg: {
                    fill:
                      styles?.hovered?.forms[0]?.hoveredCheckedCheckboxColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.hovered?.forms[0]?.hoveredCheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color: styles?.hovered?.forms[0]?.hoveredUncheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },

            "&:active": {
              ".MuiCheckbox-root.preview-checkbox": {
                svg: {
                  fill:
                    styles?.pressed?.forms[0]?.pressedUncheckedCheckboxColor?.value || colorsPalette?.colors?.surfaceOn,
                },
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultCheckedCheckboxColor?.value,
                    colorsPalette?.colors?.primary
                  ),
                  0.2
                ),
                "&.Mui-checked": {
                  svg: {
                    fill:
                      styles?.pressed?.forms[0]?.pressedCheckedCheckboxColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.pressed?.forms[0]?.pressedCheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color: styles?.pressed?.forms[0]?.pressedUncheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },

            "&:focus": {
              ".MuiCheckbox-root.preview-checkbox": {
                svg: {
                  fill:
                    styles?.focused?.forms[0]?.focusedUncheckedCheckboxColor?.value || colorsPalette?.colors?.surfaceOn,
                },
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultCheckedCheckboxColor?.value,
                    colorsPalette?.colors?.primary
                  ),
                  0.2
                ),
                "&.Mui-checked": {
                  svg: {
                    fill:
                      styles?.focused?.forms[0]?.focusedCheckedCheckboxColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.focused?.forms[0]?.focusedCheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color: styles?.focused?.forms[0]?.focusedUncheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },

            ".MuiFormControlLabel-root.disabled": {
              pointerEvents: "none",
              opacity: "0.38",
              ".MuiCheckbox-root.preview-checkbox": {
                svg: {
                  fill:
                    styles?.disabled?.forms[0]?.disabledUncheckedCheckboxColor?.value ||
                    colorsPalette?.colors?.surfaceOn,
                },
                "&.Mui-checked": {
                  svg: {
                    fill:
                      styles?.disabled?.forms[0]?.disabledCheckedCheckboxColor?.value || colorsPalette?.colors?.primary,
                  },
                  "& + .MuiTypography-root": {
                    color:
                      styles?.disabled?.forms[0]?.disabledCheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
                  },
                },
              },
              ".MuiTypography-root": {
                color:
                  styles?.disabled?.forms[0]?.disabledUncheckedLabelColor?.value || colorsPalette?.colors?.surfaceOn,
              },
            },
          }
        : activeElement === "dropdown"
        ? {
            ".preview-select": {
              label: {
                color: alpha(
                  getHex(theme, styles?.default?.forms[0]?.defaultLabelTextColor?.value, theme.palette.grey[500]),
                  parseInt(styles?.default?.forms[0]?.defaultPercentLabel?.value) / 100 || 1,
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultLabelTextColor?.value,
                    colorsPalette?.colors?.surfaceOn
                  ),
                  parseInt(styles?.default?.forms[0]?.defaultPercentLabel?.value) / 100 || 1
                ),
              },
              width: styles?.size?.value || "80%",
              ".no-animation-label": {
                transform: "none",
                transition: "none",
              },
              ".MuiInputBase-root": {
                borderRadius: styles?.radius?.value || "10px",
                backgroundColor:
                  alpha(
                    getHex(
                      theme,
                      styles?.default?.forms[0]?.defaultBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFour
                    ),
                    parseInt(styles?.default?.forms[0]?.defaultPercentBackground?.value) / 100 || 1
                  ) || "none",
                boxShadow: styles?.default?.forms[0]?.defaultBoxShadow?.value
                  ? `${styles?.default?.forms[0]?.defaultShadowPosition?.value} ${
                      styles?.default?.forms[0]?.defaultShadowBlur?.value
                    } ${alpha(
                      getHex(theme, styles?.default?.forms[0]?.defaultShadowColor?.value, theme.palette.primary.main),
                      parseInt(styles?.default?.forms[0]?.defaultPercentShadow?.value) / 100 || 1
                    )}`
                  : "none",
                input: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.default?.forms[0]?.defaultTypingTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.default?.forms[0]?.defaultPercentTyping?.value) / 100 || 1
                  ),
                  "&::placeholder": {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.default?.forms[0]?.defaultPlaceholderTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.default?.forms[0]?.defaultPercentPlaceholder?.value) / 100 || 1
                    ),
                  },
                },
                fieldset: {
                  borderWidth: styles?.default?.forms[0]?.defaultBorderWidth?.value || "1px",
                  borderColor: alpha(
                    getHex(theme, styles?.default?.forms[0]?.defaultBorderColor?.value, colorsPalette?.colors?.outline),
                    parseInt(styles?.default?.forms[0]?.defaultPercentBorder?.value) / 100 || 1
                  ),
                },
              },

              "&:hover": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.hovered?.forms[0]?.hoveredLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.hovered?.forms[0]?.hoveredPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.hovered?.forms[0]?.hoveredBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFive
                    ),
                    parseInt(styles?.hovered?.forms[0]?.hoveredPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.hovered?.forms[0]?.hoveredBoxShadow?.value
                    ? `${styles?.hovered?.forms[0]?.hoveredShadowPosition?.value} ${
                        styles?.hovered?.forms[0]?.hoveredShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.hovered?.forms[0]?.hoveredShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.hovered?.forms[0]?.hoveredPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.hovered?.forms[0]?.hoveredTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.hovered?.forms[0]?.hoveredPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.hovered?.forms[0]?.hoveredPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.hovered?.forms[0]?.hoveredPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.hovered?.forms[0]?.hoveredBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.hovered?.forms[0]?.hoveredBorderColor?.value,
                        colorsPalette?.colors?.outline
                      ),
                      parseInt(styles?.hovered?.forms[0]?.hoveredPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              "&:active": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.selected?.forms[0]?.selectedLabelTextColor?.value,
                      colorsPalette?.colors?.primary
                    ),
                    parseInt(styles?.selected?.forms[0]?.selectedPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.selected?.forms[0]?.selectedBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceLight
                    ),
                    parseInt(styles?.selected?.forms[0]?.selectedPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.selected?.forms[0]?.selectedBoxShadow?.value
                    ? `${styles?.selected?.forms[0]?.selectedShadowPosition?.value} ${
                        styles?.selected?.forms[0]?.selectedShadowBlur?.value
                      } ${alpha(
                        getHex(
                          theme,
                          styles?.selected?.forms[0]?.selectedShadowColor?.value,
                          theme.palette.primary.main
                        ),
                        parseInt(styles?.selected?.forms[0]?.selectedPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.selected?.forms[0]?.selectedTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.selected?.forms[0]?.selectedPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.selected?.forms[0]?.selectedPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.selected?.forms[0]?.selectedPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.selected?.forms[0]?.selectedBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.selected?.forms[0]?.selectedBorderColor?.value,
                        colorsPalette?.colors?.primary
                      ),
                      parseInt(styles?.selected?.forms[0]?.selectedPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: alpha(
                  getHex(
                    theme,
                    styles?.focused?.forms[0]?.focusedLabelTextColor?.value,
                    colorsPalette?.colors?.surfaceOn
                  ),
                  parseInt(styles?.focused?.forms[0]?.focusedPercentLabel?.value) / 100 || 1
                ),
              },
              ".MuiInputBase-root.Mui-focused": {
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.focused?.forms[0]?.focusedBackgroundColor?.value,
                    colorsPalette?.colors?.surfaceColorFive
                  ),
                  parseInt(styles?.focused?.forms[0]?.focusedPercentBackground?.value) / 100 || 1
                ),
                boxShadow: styles?.focused?.forms[0]?.focusedBoxShadow?.value
                  ? `${styles?.focused?.forms[0]?.focusedShadowPosition?.value} ${
                      styles?.focused?.forms[0]?.focusedShadowBlur?.value
                    } ${alpha(
                      getHex(theme, styles?.focused?.forms[0]?.focusedShadowColor?.value, theme.palette.primary.main),
                      parseInt(styles?.focused?.forms[0]?.focusedPercentShadow?.value) / 100 || 1
                    )}`
                  : "none",
                input: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.focused?.forms[0]?.focusedTypingTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.focused?.forms[0]?.focusedPercentTyping?.value) / 100 || 1
                  ),
                  "&::placeholder": {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.focused?.forms[0]?.focusedPlaceholderTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.focused?.forms[0]?.focusedPercentPlaceholder?.value) / 100 || 1
                    ),
                  },
                },
                fieldset: {
                  borderWidth: styles?.focused?.forms[0]?.outlineWidth?.value || "1px",
                  borderColor: alpha(
                    getHex(theme, styles?.focused?.forms[0]?.outlineColor?.value, colorsPalette?.colors?.primary),
                    parseInt(styles?.focused?.forms[0]?.percentoutline?.value) / 100 || 1
                  ),
                },
              },

              "&.disabled": {
                pointerEvents: "none",
                opacity: "0.38",
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.disabled?.forms[0]?.disabledLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceVariantOn
                    ),
                    parseInt(styles?.disabled?.forms[0]?.disabledPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.disabled?.forms[0]?.disabledBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFour
                    ),
                    parseInt(styles?.disabled?.forms[0]?.disabledPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.disabled?.forms[0]?.disabledBoxShadow?.value
                    ? `${styles?.disabled?.forms[0]?.disabledShadowPosition?.value} ${
                        styles?.disabled?.forms[0]?.disabledShadowBlur?.value
                      } ${alpha(
                        getHex(
                          theme,
                          styles?.disabled?.forms[0]?.disabledShadowColor?.value,
                          theme.palette.primary.main
                        ),
                        parseInt(styles?.disabled?.forms[0]?.disabledPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.disabled?.forms[0]?.disabledTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.disabled?.forms[0]?.disabledPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.disabled?.forms[0]?.disabledPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.disabled?.forms[0]?.disabledPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.disabled?.forms[0]?.disabledBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.disabled?.forms[0]?.disabledBorderColor?.value,
                        colorsPalette?.colors?.outlineVariant
                      ),
                      parseInt(styles?.disabled?.forms[0]?.disabledPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
            },
            ".MuiMenu-root": {
              ".MuiPaper-root.MuiMenu-paper": {
                ".MuiList-root": {
                  ".MuiButtonBase-root.MuiMenuItem-root": {
                    "&.preview-menu": {
                      color: alpha(
                        getHex(theme, styles?.default?.forms[0]?.defaultMenuTextColor?.value, theme.palette.grey[500]),
                        parseInt(styles?.default?.forms[0]?.defaultPercentText?.value) / 100 || 1
                      ),

                      "&:hover": {
                        color: alpha(
                          getHex(
                            theme,
                            styles?.hovered?.forms[0]?.hoveredMenuTextColor?.value,
                            theme.palette.grey[500]
                          ),
                          parseInt(styles?.hovered?.forms[0]?.hoveredPercentText?.value) / 100 || 1
                        ),
                        backgroundColor: alpha(
                          getHex(
                            theme,
                            styles?.hovered?.forms[0]?.hoveredMenuBackground?.value,
                            theme.palette.grey[500]
                          ),
                          parseInt(styles?.hovered?.forms[0]?.hoveredPercentMenuBackground?.value) / 100 || 1
                        ),
                      },

                      "&:active": {
                        color: alpha(
                          getHex(
                            theme,
                            styles?.selected?.forms[0]?.selectedMenuTextColor?.value,
                            theme.palette.grey[500]
                          ),
                          parseInt(styles?.selected?.forms[0]?.selectedPercentText?.value) / 100 || 1
                        ),
                        backgroundColor: alpha(
                          getHex(
                            theme,
                            styles?.selected?.forms[0]?.selectedMenuBackground?.value,
                            theme.palette.grey[500]
                          ),
                          parseInt(styles?.selected?.forms[0]?.selectedPercentMenuBackground?.value) / 100 || 1
                        ),
                      },

                      "&:focus": {
                        color: alpha(
                          getHex(
                            theme,
                            styles?.focused?.forms[0]?.focusedMenuTextColor?.value,
                            theme.palette.grey[500]
                          ),
                          parseInt(styles?.focused?.forms[0]?.focusedPercentText?.value) / 100 || 1
                        ),
                        backgroundColor: alpha(
                          getHex(
                            theme,
                            styles?.focused?.forms[0]?.focusedMenuBackground?.value,
                            theme.palette.grey[500]
                          ),
                          parseInt(styles?.focused?.forms[0]?.focusedPercentMenuBackground?.value) / 100 || 1
                        ),
                      },
                    },
                  },
                },
              },
            },
          }
        : activeElement === "date-picker"
        ? {
            ".MuiFormControl-root.preview-datepicker": {
              label: {
                color: alpha(
                  getHex(
                    theme,
                    styles?.default?.forms[0]?.defaultLabelTextColor?.value,
                    colorsPalette?.colors?.surfaceOn
                  ),
                  parseInt(styles?.default?.forms[0]?.defaultPercentLabel?.value) / 100 || 1
                ),
              },
              ".MuiInputBase-root": {
                borderRadius: styles?.radius?.value || "10px",
                width: styles?.size?.value || "200px",
                backgroundColor:
                  alpha(
                    getHex(
                      theme,
                      styles?.default?.forms[0]?.defaultBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFour
                    ),
                    parseInt(styles?.default?.forms[0]?.defaultPercentBackground?.value) / 100 || 1
                  ) || "none",
                boxShadow: styles?.default?.forms[0]?.defaultBoxShadow?.value
                  ? `${styles?.default?.forms[0]?.defaultShadowPosition?.value} ${
                      styles?.default?.forms[0]?.defaultShadowBlur?.value
                    } ${alpha(
                      getHex(theme, styles?.default?.forms[0]?.defaultShadowColor?.value, theme.palette.primary.main),
                      parseInt(styles?.default?.forms[0]?.defaultPercentShadow?.value) / 100 || 1
                    )}`
                  : "none",
                input: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.default?.forms[0]?.defaultTypingTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.default?.forms[0]?.defaultPercentTyping?.value) / 100 || 1
                  ),
                  "&::placeholder": {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.default?.forms[0]?.defaultPlaceholderTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.default?.forms[0]?.defaultPercentPlaceholder?.value) / 100 || 1
                    ),
                  },
                },
                fieldset: {
                  borderWidth: styles?.default?.forms[0]?.defaultBorderWidth?.value || "1px",
                  borderColor: alpha(
                    getHex(theme, styles?.default?.forms[0]?.defaultBorderColor?.value, colorsPalette?.colors?.outline),
                    parseInt(styles?.default?.forms[0]?.defaultPercentBorder?.value) / 100 || 1
                  ),
                },
              },

              "&:hover": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.hovered?.forms[0]?.hoveredLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.hovered?.forms[0]?.hoveredPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.hovered?.forms[0]?.hoveredBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFive
                    ),
                    parseInt(styles?.hovered?.forms[0]?.hoveredPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.hovered?.forms[0]?.hoveredBoxShadow?.value
                    ? `${styles?.hovered?.forms[0]?.hoveredShadowPosition?.value} ${
                        styles?.hovered?.forms[0]?.hoveredShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.hovered?.forms[0]?.hoveredShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.hovered?.forms[0]?.hoveredPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.hovered?.forms[0]?.hoveredTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.hovered?.forms[0]?.hoveredPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.hovered?.forms[0]?.hoveredPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.hovered?.forms[0]?.hoveredPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.hovered?.forms[0]?.hoveredBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.hovered?.forms[0]?.hoveredBorderColor?.value,
                        colorsPalette?.colors?.outline
                      ),
                      parseInt(styles?.hovered?.forms[0]?.hoveredPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              "&:active": {
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.selected?.forms[0]?.selectedLabelTextColor?.value,
                      colorsPalette?.colors?.primary
                    ),
                    parseInt(styles?.selected?.forms[0]?.selectedPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.selected?.forms[0]?.selectedBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceLight
                    ),
                    parseInt(styles?.selected?.forms[0]?.selectedPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.selected?.forms[0]?.selectedBoxShadow?.value
                    ? `${styles?.selected?.forms[0]?.selectedShadowPosition?.value} ${
                        styles?.selected?.forms[0]?.selectedShadowBlur?.value
                      } ${alpha(
                        getHex(
                          theme,
                          styles?.selected?.forms[0]?.selectedShadowColor?.value,
                          theme.palette.primary.main
                        ),
                        parseInt(styles?.selected?.forms[0]?.selectedPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.selected?.forms[0]?.selectedTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceOn
                      ),
                      parseInt(styles?.selected?.forms[0]?.selectedPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.selected?.forms[0]?.selectedPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.selected?.forms[0]?.selectedPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.selected?.forms[0]?.selectedBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.selected?.forms[0]?.selectedBorderColor?.value,
                        colorsPalette?.colors?.primary
                      ),
                      parseInt(styles?.selected?.forms[0]?.selectedPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: alpha(
                  getHex(
                    theme,
                    styles?.focused?.forms[0]?.focusedLabelTextColor?.value,
                    colorsPalette?.colors?.surfaceOn
                  ),
                  parseInt(styles?.focused?.forms[0]?.focusedPercentLabel?.value) / 100 || 1
                ),
              },
              ".MuiInputBase-root.Mui-focused": {
                backgroundColor: alpha(
                  getHex(
                    theme,
                    styles?.focused?.forms[0]?.focusedBackgroundColor?.value,
                    colorsPalette?.colors?.surfaceColorFive
                  ),
                  parseInt(styles?.focused?.forms[0]?.focusedPercentBackground?.value) / 100 || 1
                ),
                boxShadow: styles?.focused?.forms[0]?.focusedBoxShadow?.value
                  ? `${styles?.focused?.forms[0]?.focusedShadowPosition?.value} ${
                      styles?.focused?.forms[0]?.focusedShadowBlur?.value
                    } ${alpha(
                      getHex(theme, styles?.focused?.forms[0]?.focusedShadowColor?.value, theme.palette.primary.main),
                      parseInt(styles?.focused?.forms[0]?.focusedPercentShadow?.value) / 100 || 1
                    )}`
                  : "none",
                input: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.focused?.forms[0]?.focusedTypingTextColor?.value,
                      colorsPalette?.colors?.surfaceOn
                    ),
                    parseInt(styles?.focused?.forms[0]?.focusedPercentTyping?.value) / 100 || 1
                  ),
                  "&::placeholder": {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.focused?.forms[0]?.focusedPlaceholderTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.focused?.forms[0]?.focusedPercentPlaceholder?.value) / 100 || 1
                    ),
                  },
                },
                fieldset: {
                  borderWidth: styles?.focused?.forms[0]?.outlineWidth?.value || "1px",
                  borderColor: alpha(
                    getHex(theme, styles?.focused?.forms[0]?.outlineColor?.value, colorsPalette?.colors?.primary),
                    parseInt(styles?.focused?.forms[0]?.percentoutline?.value) / 100 || 1
                  ),
                },
              },

              "&.disabled": {
                pointerEvents: "none",
                label: {
                  color: alpha(
                    getHex(
                      theme,
                      styles?.disabled?.forms[0]?.disabledLabelTextColor?.value,
                      colorsPalette?.colors?.surfaceVariantOn
                    ),
                    parseInt(styles?.disabled?.forms[0]?.disabledPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  backgroundColor: alpha(
                    getHex(
                      theme,
                      styles?.disabled?.forms[0]?.disabledBackgroundColor?.value,
                      colorsPalette?.colors?.surfaceColorFour
                    ),
                    parseInt(styles?.disabled?.forms[0]?.disabledPercentBackground?.value) / 100 || 1
                  ),
                  boxShadow: styles?.disabled?.forms[0]?.disabledBoxShadow?.value
                    ? `${styles?.disabled?.forms[0]?.disabledShadowPosition?.value} ${
                        styles?.disabled?.forms[0]?.disabledShadowBlur?.value
                      } ${alpha(
                        getHex(
                          theme,
                          styles?.disabled?.forms[0]?.disabledShadowColor?.value,
                          theme.palette.primary.main
                        ),
                        parseInt(styles?.disabled?.forms[0]?.disabledPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.disabled?.forms[0]?.disabledTypingTextColor?.value,
                        colorsPalette?.colors?.surfaceVariantOn
                      ),
                      parseInt(styles?.disabled?.forms[0]?.disabledPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.disabled?.forms[0]?.disabledPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.disabled?.forms[0]?.disabledPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.disabled?.forms[0]?.disabledBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(
                        theme,
                        styles?.disabled?.forms[0]?.disabledBorderColor?.value,
                        colorsPalette?.colors?.outlineVariant
                      ),
                      parseInt(styles?.disabled?.forms[0]?.disabledPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
              },
              "&.error": {
                label: {
                  color: alpha(
                    getHex(theme, styles?.error?.forms[0]?.errorLabelTextColor?.value, colorsPalette?.colors?.error),
                    parseInt(styles?.error?.forms[0]?.errorPercentLabel?.value) / 100 || 1
                  ),
                },
                ".MuiInputBase-root": {
                  borderRadius: styles?.roundness?.value || "10px",
                  width: styles?.size?.value || "200px",
                  backgroundColor:
                    alpha(
                      getHex(
                        theme,
                        styles?.error?.forms[0]?.errorBackgroundColor?.value,
                        colorsPalette?.colors?.surfaceColorFour
                      ),
                      parseInt(styles?.error?.forms[0]?.errorPercentBackground?.value) / 100 || 1
                    ) || "none",
                  boxShadow: styles?.error?.forms[0]?.errorBoxShadow?.value
                    ? `${styles?.error?.forms[0]?.errorShadowPosition?.value} ${
                        styles?.error?.forms[0]?.errorShadowBlur?.value
                      } ${alpha(
                        getHex(theme, styles?.error?.forms[0]?.errorShadowColor?.value, theme.palette.primary.main),
                        parseInt(styles?.error?.forms[0]?.errorPercentShadow?.value) / 100 || 1
                      )}`
                    : "none",
                  input: {
                    color: alpha(
                      getHex(
                        theme,
                        styles?.error?.forms[0]?.errorTypingTextColor?.value,
                        colorsPalette?.colors?.errorOn
                      ),
                      parseInt(styles?.error?.forms[0]?.errorPercentTyping?.value) / 100 || 1
                    ),
                    "&::placeholder": {
                      color: alpha(
                        getHex(
                          theme,
                          styles?.error?.forms[0]?.errorPlaceholderTextColor?.value,
                          colorsPalette?.colors?.surfaceVariantOn
                        ),
                        parseInt(styles?.error?.forms[0]?.errorPercentPlaceholder?.value) / 100 || 1
                      ),
                    },
                  },
                  fieldset: {
                    borderWidth: styles?.error?.forms[0]?.errorBorderWidth?.value || "1px",
                    borderColor: alpha(
                      getHex(theme, styles?.error?.forms[0]?.errorBorderColor?.value, colorsPalette?.colors?.error),
                      parseInt(styles?.error?.forms[0]?.errorPercentBorder?.value) / 100 || 1
                    ),
                  },
                },
                "& + .error-msg": {
                  color: alpha(
                    getHex(theme, styles?.error?.forms[0]?.errorCaptionTextColor?.value, colorsPalette?.colors?.error),
                    parseInt(styles?.error?.forms[0]?.errorPercentCaption?.value) / 100 || 1
                  ),
                },
              },
            },
          }
        : {}),
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme, styles }) => ({
  color: alpha(
    getHex(theme, styles?.default?.forms[0]?.defaultMenuTextColor?.value, theme.palette.grey[500]),
    parseInt(styles?.default?.forms[0]?.defaultPercentText?.value) / 100 || 1
  ),
  "&:hover": {
    color: alpha(
      getHex(theme, styles?.hovered?.forms[0]?.hoveredMenuTextColor?.value, theme.palette.grey[500]),
      parseInt(styles?.hovered?.forms[0]?.hoveredPercentText?.value) / 100 || 1
    ),
    backgroundColor: alpha(
      getHex(theme, styles?.hovered?.forms[0]?.hoveredMenuBackground?.value, theme.palette.grey[500]),
      parseInt(styles?.hovered?.forms[0]?.hoveredPercentMenuBackground?.value) / 100 || 1
    ),
  },
  "&:active": {
    color: alpha(
      getHex(theme, styles?.selected?.forms[0]?.selectedMenuTextColor?.value, theme.palette.grey[500]),
      parseInt(styles?.selected?.forms[0]?.selectedPercentText?.value) / 100 || 1
    ),
    backgroundColor: alpha(
      getHex(theme, styles?.selected?.forms[0]?.selectedMenuBackground?.value, theme.palette.grey[500]),
      parseInt(styles?.selected?.forms[0]?.selectedPercentMenuBackground?.value) / 100 || 1
    ),
  },
  "&:focus": {
    color: alpha(
      getHex(theme, styles?.focused?.forms[0]?.focusedMenuTextColor?.value, theme.palette.grey[500]),
      parseInt(styles?.focused?.forms[0]?.focusedPercentText?.value) / 100 || 1
    ),
    backgroundColor: alpha(
      getHex(theme, styles?.focused?.forms[0]?.focusedMenuBackground?.value, theme.palette.grey[500]),
      parseInt(styles?.focused?.forms[0]?.focusedPercentMenuBackground?.value) / 100 || 1
    ),
  },
}));

export const StyledButton = styled("button")(({ theme, styles }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: "14px 24px",
  height: styles?.height?.value || "45px",
  minWidth: styles?.minimalWidth?.value || "40px",
  width: "fit-content",
  backgroundColor: alpha(
    getHex(theme, styles?.default?.forms[0]?.defaultBackgroundColor?.value, theme.palette.primary.main),
    parseInt(styles?.default?.forms[0]?.defaultPercentBackground?.value) / 100 || 1
  ),
  color: styles?.default?.forms[0]?.defaultTextColor?.value || theme.palette.primary.contrastText,
  borderWidth: styles?.default?.forms[0]?.defaultBorder?.value
    ? `${styles?.default?.forms[0]?.defaultBorderWidth?.value}px`
    : "0px",
  borderStyle: styles?.default?.forms[0]?.defaultBorder?.value && "solid",
  borderColor: styles?.default?.forms[0]?.defaultBorder?.value
    ? alpha(
        getHex(theme, styles?.default?.forms[0]?.defaultBorderColor?.value, theme.palette.primary.main),
        parseInt(styles?.default?.forms[0]?.defaultBorderPercent?.value) / 100 || 1
      )
    : theme.palette.common.black,
  borderRadius: styles?.default?.forms[0]?.defaultBorder?.value
    ? styles?.default?.forms[0]?.defaultBorderRadius?.value
    : "20px",
  boxShadow: styles?.default?.forms[0]?.defaultBoxShadow?.value
    ? `${styles?.default?.forms[0]?.defaultShadowPosition?.value} ${
        styles?.default?.forms[0]?.defaultShadowPosition?.value
      } ${styles?.default?.forms[0]?.defaultShadowBlur?.value} ${
        styles?.default?.forms[0]?.defaultBoxShadowSpread?.value
      } ${alpha(
        getHex(theme, styles?.default?.forms[0]?.defaultShadowColor?.value, theme.palette.primary.main),
        parseInt(styles?.default?.forms[0]?.defaultPercentShadow?.value) / 100 || 1
      )}`
    : "none",
  cursor: "pointer",
  transition: styles?.default?.forms[0]?.defaultAnimation?.value
    ? `all ${styles?.default?.forms[0]?.defaultTransitionEffectTime?.value} ${styles?.default?.forms[0]?.defaultAnimationType?.value}`
    : "none",
  ".btn-icon": {
    display: !styles?.showIcon?.value && "none",
  },
  ".btn-text": {
    display: !styles?.showText?.value && "none",
  },
  "&:hover": {
    backgroundColor: alpha(
      getHex(theme, styles?.hovered?.forms[0]?.hoveredBackgroundColor?.value, theme.palette.primary.dark),
      parseInt(styles?.hovered?.forms[0]?.percentBackground?.value) / 100 || 1
    ),
    color: styles?.hovered?.forms[0]?.hoveredTextColor?.value || theme.palette.primary.contrastText,
    borderWidth: styles?.hovered?.forms[0]?.hoveredBorder?.value
      ? `${styles?.hovered?.forms[0]?.hoveredBorderWidth?.value}px`
      : "0px",
    borderStyle: styles?.default?.forms[0]?.hoveredBorder?.value && "solid",

    borderColor: styles?.hovered?.forms[0]?.hoveredBorder?.value
      ? alpha(
          getHex(theme, styles?.hovered?.forms[0]?.hoveredBorderColor?.value, theme.palette.primary.dark),
          parseInt(styles?.hovered?.forms[0]?.hoveredBorderPercent?.value) / 100 || 1
        )
      : "black",
    borderRadius: styles?.hovered?.forms[0]?.hoveredBorder?.value
      ? styles?.hovered?.forms[0]?.hoveredBorderRadius?.value
      : "20px",
    boxShadow: styles?.hovered?.forms[0]?.hoveredBoxShadow?.value
      ? `${styles?.hovered?.forms[0]?.hoveredShadowPosition?.value} ${
          styles?.hovered?.forms[0]?.hoveredShadowPosition?.value
        } ${styles?.hovered?.forms[0]?.hoveredShadowBlur?.value} ${
          styles?.hovered?.forms[0]?.hoveredBoxShadowSpread?.value
        } ${alpha(
          getHex(theme, styles?.hovered?.forms[0]?.hoveredShadowColor?.value, theme.palette.primary.dark),
          parseInt(styles?.hovered?.forms[0]?.hoveredPercentShadow?.value) / 100 || 1
        )}`
      : "none",
    ...(styles?.hovered?.forms[0]?.hoveredAnimation?.value && {
      transition: `all ${styles?.hovered?.forms[0]?.hoveredTransitionEffectTime?.value} ${styles?.hovered?.forms[0]?.hoveredAnimationType?.value}`,
    }),
  },
  "&:focus": {
    borderWidth: styles?.focused?.forms[0]?.outlineWidth?.value || "1px",

    borderColor: alpha(
      getHex(theme, styles?.focused?.forms[0]?.outlineColor?.value, theme.palette.primary.main),
      parseInt(styles?.focused?.forms[0]?.percentOutline?.value) / 100 || 1
    ),
  },
  "&:active": {
    backgroundColor: alpha(
      getHex(theme, styles?.pressed?.forms[0]?.pressedBackgroundColor?.value, theme.palette.primary.dark),
      parseInt(styles?.pressed?.forms[0]?.pressedPercentBackground?.value) / 100 || 1
    ),
    color: styles?.pressed?.forms[0]?.pressedTextColor?.value || theme.palette.primary.contrastText,
    borderStyle: styles?.default?.forms[0]?.pressedBorder?.value && "solid",

    borderWidth: styles?.pressed?.forms[0]?.pressedBorder?.value
      ? `${styles?.pressed?.forms[0]?.pressedBorderWidth?.value}`
      : "0px",
    bordercolor: styles?.pressed?.forms[0]?.pressedBorder?.value
      ? alpha(
          getHex(theme, styles?.pressed?.forms[0]?.pressedBorderColor?.value, theme.palette.primary.dark),
          parseInt(styles?.pressed?.forms[0]?.pressedBorderPercent?.value) / 100 || 1
        )
      : "black",
    borderRadius: styles?.pressed?.forms[0]?.pressedBorder?.value
      ? styles?.pressed?.forms[0]?.pressedBorderRadius?.value
      : "20px",
    boxShadow: styles?.pressed?.forms[0]?.pressedBoxShadow?.value
      ? `${styles?.pressed?.forms[0]?.pressedShadowPosition?.value} ${
          styles?.pressed?.forms[0]?.pressedShadowPosition?.value
        } ${styles?.pressed?.forms[0]?.pressedShadowBlur?.value} ${
          styles?.pressed?.forms[0]?.pressedBoxShadowSpread?.value
        } ${alpha(
          getHex(theme, styles?.pressed?.forms[0]?.pressedShadowColor?.value, theme.palette.primary.main),
          parseInt(styles?.pressed?.forms[0]?.pressedPercentShadow?.value) / 100 || 1
        )}`
      : "none",
    ...(styles?.pressed?.forms[0]?.pressedAnimation?.value && {
      transition: `all ${styles?.pressed?.forms[0]?.pressedTransitionEffectTime?.value} ${styles?.pressed?.forms[0]?.pressedAnimationType?.value}`,
    }),
  },
  "&.disabled": {
    backgroundColor: alpha(
      getHex(theme, styles?.disabled?.forms[0]?.disabledBackgroundColor?.value, theme.palette.primary.main),
      parseInt(styles?.disabled?.forms[0]?.disabledPercentBackground?.value) / 100 || 1
    ),
    color: styles?.disabled?.forms[0]?.disabledTextColor?.value || theme.palette.primary.contrastText,
    borderStyle: styles?.default?.forms[0]?.disabledBorder?.value && "solid",

    borderWidth: styles?.disabled?.forms[0]?.disabledBorder?.value
      ? `${styles?.disabled?.forms[0]?.disabledBorderWidth?.value}`
      : "0px",
    borderColor: styles?.disabled?.forms[0]?.disabledBorder?.value
      ? alpha(
          getHex(theme, styles?.disabled?.forms[0]?.disabledBorderColor?.value, theme.palette.primary.main),
          parseInt(styles?.disabled?.forms[0]?.disabledBorderPercent?.value) / 100 || 1
        )
      : "black",
    borderRadius: styles?.disabled?.forms[0]?.disabledBorder?.value
      ? styles?.disabled?.forms[0]?.disabledBorderRadius?.value
      : "20px",
    boxShadow: styles?.disabled?.forms[0]?.disabledBoxShadow?.value
      ? `${styles?.disabled?.forms[0]?.disabledShadowPosition?.value} ${
          styles?.disabled?.forms[0]?.disabledShadowPosition?.value
        } ${styles?.disabled?.forms[0]?.disabledShadowBlur?.value} ${
          styles?.disabled?.forms[0]?.disabledBoxShadowSpread?.value
        } ${alpha(
          getHex(theme, styles?.disabled?.forms[0]?.disabledShadowColor?.value, theme.palette.primary.main),
          parseInt(styles?.disabled?.forms[0]?.disabledPercentShadow?.value) / 100 || 1
        )}`
      : "none",
    cursor: "not-allowed",
  },
  // disabled ?
}));

export const StyledFormRenderer = styled("div")(({ theme }) => ({
  ".form-head": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    h1: {
      margin: "0 auto",
      textAlign: "center",
      fontSize: 24,
      fontWeight: 500,
      color: theme.palette.grey.main,
    },
    ".back-btn": {
      cursor: "pointer",
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      transition: "all 0.3s ease-in-out",
      borderRadius: 10,
      padding: "8px 24px",
      svg: {
        marginRight: 10,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  ".preview-wrapper": {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "20px 0",
  },
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  "&:after": {
    background: `linear-gradient( 90deg, transparent, ${theme.palette.primary.light}, transparent );`,
  },
}));

export const StyledFontsV2Form = styled(Grid)(({ theme, mainDisabled, textDisabled }) => ({
  ".pageMainFormCard": {
    padding: 0,
    ".font-field-main": {
      opacity: mainDisabled ? 0.5 : 1,
      pointerEvents: mainDisabled ? "none" : "unset",
    },
    ".font-field-text": {
      opacity: textDisabled ? 0.5 : 1,
      pointerEvents: textDisabled ? "none" : "unset",
    },
  },
  ".upload-btn": {
    margin: "15px 0 0 15px",
  },
}));
