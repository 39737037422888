import React from "react";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { useSelector } from "react-redux";

import { StyledLobbyV3 } from "../StyledLobbyPreview";

export default function V3({ findCategoryTitle, games }) {
  const lobbyOptions = useSelector((state) => state?.settings?.portalSettings?.layout?.lobby?.options);
  const activeCategory = useSelector((state) => state?.form?.orderCategory);

  return (
    <StyledLobbyV3>
      <div className="categories-wrapp">
        <span className={activeCategory === "all" ? "active" : ""}>All Games</span>
        {lobbyOptions?.gameCategories?.map((category, index) => {
          if (category?.slug === "all") return null;
          return (
            <span className={activeCategory === category ? "active" : ""} key={index}>
              {findCategoryTitle(category)}
            </span>
          );
        })}
      </div>
      <div className="filters">
        <div className="filt-select">
          Game Providers
          <TuneIcon />
        </div>
        <div className="filt-select search">
          Search <SearchIcon />
        </div>
        <div className="action-filters">
          <div className="filt-select">
            <FavoriteIcon />
          </div>
          <div className="filt-select">
            <FiberNewIcon />
          </div>
          <div className="filt-select">
            <WhatshotIcon />
          </div>
        </div>
      </div>
      <div className="game-preview">
        <div className="game-wrapper">{games}</div>
      </div>
    </StyledLobbyV3>
  );
}
