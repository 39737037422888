import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    activeTheme: "cherry",
    collapseMenu: false,
    formPageType: null,
    btnLoadingStatus: false,
    showMigrateContentButton: false,
  },
  reducers: {
    handleThemeSwitch: (state, action) => {
      localStorage.setItem("theme", action.payload);
      return {
        ...state,
        activeTheme: action.payload,
      };
    },
    updateBtnLoadingStatus: (state, action) => {
      return {
        ...state,
        btnLoadingStatus: action.payload,
      };
    },
    updateFormPageType: (state, action) => {
      return {
        ...state,
        formPageType: {
          ...state.formPageType,
          ...action.payload,
        },
      };
    },
    updateFormPage: (state, action) => {
      return {
        ...state,
        formPageType: {
          ...state.formPageType,
          activeCurrencies: action.payload.activeCurrencies,
          allCurrencyTabs: action.payload.allCurrencyTabs,
        },
      };
    },
    toggleSidebar: (state, action) => {
      return {
        ...state,
        collapseMenu: !state.collapseMenu,
      };
    },
    toggleShowMigrateContentButton: (state, action) => {
      return {
        ...state,
        showMigrateContentButton: action.payload,
      };
    },
  },
});

export const {
  handleThemeSwitch,
  updateBtnLoadingStatus,
  updateFormPageType,
  toggleSidebar,
  updateFormPage,
  toggleShowMigrateContentButton,
} = layoutSlice.actions;
export default layoutSlice.reducer;
