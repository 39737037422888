import { useEffect, useState } from "react";

const useMediumPagination = (itemsPerPage, paginationOptions) => {
  const [curentPage, setCurentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState({ isValid: true, inputValue: null });
  const arrayOfPageOptions = Array.from({ length: paginationOptions }, (value, index) => index + 1);

  useEffect(() => {
    setCurentPage(1);
  }, [itemsPerPage, paginationOptions]);

  const handleGoToPageOnEnter = (e) => {
    if (arrayOfPageOptions.includes(parseInt(e.target.value)) || e.target.value === "") {
      setGoToPageInput({ isValid: true, inputValue: parseInt(e.target.value) });
    } else setGoToPageInput({ isValid: false, inputValue: null });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      isNaN(parseInt(e.target.value)) ? setCurentPage(1) : setCurentPage(e.target.value);
    }
  };

  const sliceArgFirst = curentPage * itemsPerPage - itemsPerPage;
  const sliceArgSecond = curentPage * itemsPerPage;

  return {
    curentPage,
    setCurentPage,
    sliceArgFirst,
    sliceArgSecond,
    handleGoToPageOnEnter,
    handleKeyDown,
    goToPageInput,
  };
};

export default useMediumPagination;
