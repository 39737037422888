import instance from "../api_config";

const getAllTranslations = () => {
  const portal = localStorage.getItem("portal");
  return instance.get(`/translations?portal=${portal}&_limit=-1`);
};

const updateTranslation = (id, dataToSubmit) => {
  return instance.put(`/translations/${id}`, dataToSubmit);
};

const addTranslation = (dataToSubmit) => {
  return instance.post("/translations", dataToSubmit);
};
const deleteTranslation = (id) => {
  instance.delete(`/translations/${id}`);
};

const translationsService = {
  getAllTranslations,
  updateTranslation,
  addTranslation,
  deleteTranslation,
};
export default translationsService;
