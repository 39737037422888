import React from "react";

const pages = [
  { key: "pages", title: "Pages" },
  { key: "articles", title: "Articles" },
  { key: "promotions", title: "Promotions" },
  { key: "affiliates", title: "Affiliates" },
  { key: "slides", title: "Slides" },
  { key: "footer", title: "Footer" },
  { key: "payment-methods", title: "Payment Methods" },
  { key: "games", title: "Games" },
  { key: "landing-pages", title: "Landing Pages" },
  { key: "additional-infos", title: "Additional Infos" },
  { key: "bonus-infos", title: "Bonus Infos" },
];

export const handleKeyTransform = (key) => {
  return (
    key.charAt(0).toUpperCase() +
    key
      .slice(1)
      .split(/(?=[A-Z])/)
      .join(" ")
  );
};

const renderPath = (photo) => {
  const obj = {
    "bonus-info": `/administration/${pages.find((el) => el.key === "bonus-infos")?.key}/edit/${photo.id}`,
    "additional-info": `/administration/${pages.find((el) => el.key === "additional-infos")?.key}/edit/${photo.id}`,
    sliders: `/administration/${photo?.__contentType}/edit/${photo.id}`,
    "payment-methods": `/administration/${photo?.__contentType}`,
    "game-tags": `/administration/games/tags/${photo.id}`,
    "game-providers": `/administration/games/provider/${photo.id}`,
  };

  return (
    obj[photo?.__contentType] ||
    `/administration/${pages.find((el) => el.key === photo?.__contentType)?.key}/edit/${photo.id}`
  );
};

export const renderTitleAndPathComponentFraction = (relatedPhotos) => {
  return relatedPhotos?.related?.map((el, idx) => {
    const { __contentType, path, id } = el;
    const title = __contentType.replace("-", " ");
    const transformedTitle = title.replace(/(^\w|\s\w)/g, (strFirstLetter) => strFirstLetter.toUpperCase());
    const photoPath = renderPath(el);
    return (
      <div className="single-image-related-pages-container" key={idx}>
        <a className="single-image-related-pages-link" href={photoPath} target="__blank">
          <div>{`${transformedTitle} ID:${id} (${path})`}</div>
        </a>
      </div>
    );
  });
};
