import { useEffect, useState } from "react";

import { objectIncludeFilterValues } from "../Components/Utils/helpers";

const INITIAL_FILTERS = {
  search: {
    query: "",
    fieldsToCheck: ["question", "name", "title", "slug"],
  },
  filterType: {},
  statuses: ["published"],
};

export const useFilter = (data, page) => {
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const handleChange = (value, property, key) => {
    const functionType = {
      search: () => setFilters({ ...filters, search: { ...filters?.search, query: value } }),
      status: () =>
        setFilters((prevState) =>
          prevState?.statuses?.includes(value)
            ? { ...prevState, statuses: [...prevState?.statuses?.filter((el) => el !== value)] }
            : { ...prevState, statuses: [...prevState?.statuses, value] }
        ),
      select: () =>
        setFilters((prevState) => ({
          ...prevState,
          filterType: { ...prevState.filterType, [key]: value || "" },
        })),
      default: () => {},
    };
    return (functionType?.[property] || functionType?.default)();
  };

  const filteredData = filters?.statuses?.reduce((newTable, stat) => {
    newTable = [
      ...newTable,
      ...(Array.isArray(data)
        ? data.filter((el) => {
            return searchFilter(el) && statusFilter(el, stat) && objectIncludeFilterValues(el, filters?.filterType);
          })
        : []),
    ];

    return newTable;
  }, []);

  function searchFilter(property) {
    const { fieldsToCheck, query } = filters?.search;
    const filterBySearch = fieldsToCheck.some((field) => {
      const value = property?.[field];
      return value && value.toLowerCase().includes(query.toLowerCase());
    });
    return query ? filterBySearch : true;
  }
  function statusFilter(el, stat) {
    const activeStat = stat === "deleted" ? "trash" : stat;
    return el?.status ? el?.status === activeStat : el;
  }

  useEffect(() => {
    return setFilters(INITIAL_FILTERS);
  }, [page]);

  return { filteredData, filters, handleChange };
};
