import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { styled } from "@mui/material/styles";

export const GeneralWrap = styled("div")(({ theme }) => ({
  paddingTop: "24px",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "repeat(2, 1fr)",
  rowGap: "20px",
  ".general-items": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    ".general-item-value": {
      fontSize: "16px",
      color: "#242731",
    },
  },
}));

export const AccordionWrap = styled("div")(({ theme }) => ({
  paddingTop: "24px",
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  borderRadius: "8px",
  paddingRight: "12px",
  ".MuiAccordionSummary-content": {
    display: "flex",
    gap: "16px",
    paddingLeft: "24px",
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
  ".flag": {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "16px 0px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  ".items": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    ".item-value": {
      fontSize: "16px",
      color: "#242731",
      ".read-more-btn": {
        background: "none",
        border: "none",
        color: theme.palette.primary.main,
        cursor: "pointer",
        outline: "none",
      },
    },
  },
  ".seo-label": {
    fontSize: "16px",
    color: "#242731",
  },
  ".seo-content-grid": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    rowGap: "20px",
    columnGap: "4px",
    ".items": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      ".item-value": {
        fontSize: "16px",
        color: "#242731",
      },
    },
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&:before": {
    cotent: "none !important",
    opacity: "0 !important",
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0",
  },
  ".MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "48px",
  },
  marginBottom: "4px",
}));

export const Divider = styled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: "1px dashed #8B8484",
}));
