import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import mediaService from "../services/mediaService";
import { closeModal } from "./modalSlice";

export const getAllPhotos = createAsyncThunk("media/all", async () => {
  const res = await mediaService.getAllPhotos();
  return res?.data;
});

export const postNewImage = createAsyncThunk("media/postNewImage", async (image) => {
  const res = await mediaService.postNewImage(image);
  return res.data;
});

export const getAllAlbumPhotos = createAsyncThunk("albumPhotos/all", async (aciveGallery, { dispatch }) => {
  const { page, album } = aciveGallery;
  dispatch(clearAlbumPhotos());
  const res = await mediaService.getAllAlbumPhotos(album);
  return { album: res.data, page: page };
});

export const editPhotoByID = createAsyncThunk("edit/photo/id", async (id) => {
  const res = await mediaService.editPhotoByID(id);
  return res.data;
});

export const addNewImage = createAsyncThunk("add/new/image", async (image) => {
  const res = await mediaService.addNewImage(image);
  return res?.data;
});

export const updatePhotoByID = createAsyncThunk("update/photo/id", async (photo) => {
  const { image, id } = photo;
  const res = await mediaService.updatePhotoByID(image, id);
  return res.data;
});
export const deletePhotoByID = createAsyncThunk("delete/image", async (id, { dispatch }) => {
  const MySwal = withReactContent(Swal);
  return MySwal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, proceed!",
  }).then((willDelete) => {
    if (willDelete.isConfirmed) {
      dispatch(deleteHandler(id));
      dispatch(closeModal());
      return MySwal.fire("Deleted!", "Your item has been deleted.", "success");
    }
  });
});
const deleteHandler = createAsyncThunk("delete/handler", async (id) => {
  const res = await mediaService.deletePhotoByID(id);
  return res.data;
});
export const relatedPhotoByID = createAsyncThunk("related/photo", async (id) => {
  const res = await mediaService.relatedPhotoByID(id);
  const mediaStateIds = res?.data?.related?.map((el) => el.id);
  const filteredMediaState = res?.data?.related?.filter(({ id }, index) => !mediaStateIds.includes(id, index + 1));
  const newResponseData = { ...res?.data, related: filteredMediaState };
  return newResponseData;
});

const mediaSlice = createSlice({
  name: "media",
  initialState: {
    mediaData: [],
    album: [],
    albumPage: null,
    loading: false,
    error: null,
    element: "",
    photo: [],
    newPhoto: [],
    deletedPhoto: null,
    editPhoto: null,
    editPhotoSuccess: null,
    relatedPhotos: [],
    relatedLoading: false,
  },
  reducers: {
    clearAlbumPhotos: (state, action) => {
      return {
        ...state,
        album: [],
        albumPage: null,
      };
    },
    setImageId: (state, action) => {
      // if new selected photo for some form file field does not exist in state.photo,
      // it will add it to state.photo
      // if it exists, it will remove it from state.photo, and add new selected photo for that specific form file field
      const filterPhotoArr = state.photo.filter((el) => el.element !== action.payload.element);
      const image = {
        id: action.payload.id,
        url: action.payload.url,
        element: action.payload.element,
        isNew: action.payload.isNew || false,
      };

      return {
        ...state,
        photo: [...filterPhotoArr, image],
      };
    },
    removePhotoId: (state, action) => {
      return {
        ...state,
        element: "",
        photo: [],
        editPhoto: null,
        editPhotoSuccess: null,
      };
    },
    removePreviewPhotoByFieldName: (state, action) => {
      return {
        ...state,
        photo: action.payload
          ? state.photo.filter((el) => {
              return el.element !== action.payload;
            })
          : [],
      };
    },
    setPhotoElement: (state, action) => {
      return {
        ...state,
        element: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPhotos.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getAllPhotos.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        mediaData: action.payload,
      };
    });
    builder.addCase(getAllPhotos.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    });
    builder.addCase(postNewImage.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        mediaData: action.payload,
      };
    });
    builder.addCase(postNewImage.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    });
    builder.addCase(getAllAlbumPhotos.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getAllAlbumPhotos.fulfilled, (state, action) => {
      const { page, album } = action.payload;
      const newAlbum = Object.values(album)?.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      if (state.deletedPhoto !== null) {
        const find = newAlbum.find(({ id }) => id === state.deletedPhoto.id);
        const albIndex = newAlbum.indexOf(find);
        if (albIndex > -1) {
          newAlbum.splice(albIndex, 1);
        }
      }
      return {
        ...state,
        loading: false,
        error: null,
        album: newAlbum,
        albumPage: page,
      };
    });
    builder.addCase(getAllAlbumPhotos.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    });
    builder.addCase(addNewImage.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(addNewImage.fulfilled, (state, action) => {
      const newAlbumAdded = [action.payload[0], ...state.album];
      return {
        ...state,
        newPhoto: action.payload,
        album: newAlbumAdded,
        loading: false,
      };
    });
    builder.addCase(addNewImage.rejected, (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    });
    builder.addCase(updatePhotoByID.fulfilled, (state, action) => {
      return {
        ...state,
        editPhotoSuccess: action.payload,
        editPhoto: null,
      };
    });
    builder.addCase(updatePhotoByID.rejected, (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    });
    builder.addCase(relatedPhotoByID.fulfilled, (state, action) => {
      return {
        ...state,
        relatedPhotos: action.payload,
      };
    });
    builder.addCase(editPhotoByID.fulfilled, (state, action) => {
      return {
        ...state,
        editPhoto: action.payload,
      };
    });
    builder.addCase(deleteHandler.fulfilled, (state, action) => {
      const newAlbumDel = state.album.filter(function (el) {
        return el.id !== action.payload.id;
      });
      return {
        ...state,
        loading: false,
        error: null,
        album: newAlbumDel,
        deletedPhoto: action.payload,
      };
    });
    builder.addCase(deleteHandler.rejected, (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    });
  },
});

export const { clearAlbumPhotos, setImageId, removePhotoId, removePreviewPhotoByFieldName, setPhotoElement } =
  mediaSlice.actions;
export default mediaSlice.reducer;
