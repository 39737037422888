import React, { useEffect, useState } from "react";

//
import { useSnackbar } from "notistack";
//
import { useDispatch, useSelector } from "react-redux";

import useDropzoneOverride from "../../../../custom_hooks/useDropzoneOverride";
import { clearAlbumPhotos, getAllAlbumPhotos } from "../../../../Redux/slices/mediaSlice";
import { setFieldData } from "../../../../Redux/slices/modalSlice";
//
import DialogContainer from "../../../Dialog/Index";
import { isFormValid, update } from "../../../Utils/Form/formActions";
//
import Select from "../components/Select";
import { ModalContainer } from "../Styled";
import OverrideImgSelector from "./components/OverrideImgSelector";
//

export default function OverrideMenu({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modalState = useSelector((state) => state.modal);
  const portalSettings = useSelector((state) => state.settings.portalSettings);
  const palettes = portalSettings?.style?.palettes;
  const pageType = useSelector((state) => state?.layout?.formPageType?.page);
  const albumPage = useSelector((state) => state?.media?.albumPage);

  const [formdata, setFormdata] = useState({
    paletteName: {
      element: "select",
      value: "",
      config: {
        name: "paletteName_input",
        placeholder: "Choose palette",
        label: "Select Palette Name",
        options: [],
      },
      validation: {
        required: true,
        email: false,
      },
      valid: true,
      touched: true,
      validationMessage: "",
    },
  });

  const [overrideIcons, setOverrideIcons] = useState({
    iconOverride: {
      imageId: null,
      imageUrl: null,
    },
    iconOverrideHover: {
      imageId: null,
      imageUrl: null,
    },
  });

  const { getRootProps, getInputProps, setActiveDropzone, submit, isLoading, activeDropzone } =
    useDropzoneOverride("menus");

  useEffect(() => {
    if (pageType !== albumPage) {
      dispatch(getAllAlbumPhotos({ album: pageType?.split(".")?.[0], page: pageType }));
    }
  }, [pageType]);

  useEffect(() => {
    setFormdata((prevState) => ({
      ...prevState,
      paletteName: {
        ...prevState.paletteName,
        config: {
          ...prevState.paletteName.config,
          options: palettes?.map((pal, idx) => {
            return {
              id: idx,
              slug: pal?.paletteSlug,
              value: pal?.paletteSlug,
              label: pal?.paletteName,
            };
          }),
        },
      },
    }));
  }, [portalSettings]);

  const updateForm = (element) => {
    const newFormdata = update(element, formdata, "overrideMenu");
    setFormdata(newFormdata);
  };

  const handleSubmit = () => {
    const formIsVaid = isFormValid(formdata);
    const prevOverrideState = modalState?.modal?.pageFrom?.value || null;

    if (formIsVaid && Boolean(formdata.paletteName.value)) {
      const newObj = {
        [formdata?.paletteName?.value]: {
          paletteName: formdata?.paletteName?.value,
          iconOverride: overrideIcons.iconOverride,
          iconOverrideHover: overrideIcons.iconOverrideHover,
        },
      };
      if (prevOverrideState) {
        Object.entries(prevOverrideState).map(([key, val]) => {
          return Object.assign(newObj, { ...newObj, [key]: val });
        });
      }
      dispatch(setFieldData({ [modalState?.modal?.pageFrom?.elemName]: newObj }));
      onClose();
    } else {
      enqueueSnackbar("Palette Name field is required!", { variant: "error" });
    }
  };

  const handleSetOverrideIcon = (iconKey, iconObj) => {
    setOverrideIcons((prevState) => ({
      ...prevState,
      [iconKey]: iconObj,
    }));
  };

  return (
    <DialogContainer
      label="Logo Override"
      actionLabel="Submit"
      isLoading={isLoading}
      isOpen={isOpen}
      onAction={handleSubmit}
      onClose={onClose}
    >
      <ModalContainer>
        <div className="modal-input-wrapp">
          <Select
            label="Select Palette Name"
            handleChange={(event) => updateForm({ event, id: "paletteName" })}
            selectedValue={formdata.paletteName.value}
            placeholder={formdata.paletteName.config.placeholder}
            options={formdata.paletteName.config.options}
          />
        </div>
        <OverrideImgSelector
          handleActiveDropzone={(field) => setActiveDropzone(field)}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isLoading={isLoading}
          overrideIcons={overrideIcons}
          handleSetOverrideIcon={(key, obj) => handleSetOverrideIcon(key, obj)}
        />
      </ModalContainer>
    </DialogContainer>
  );
}
