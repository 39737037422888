import React from "react";

//
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useDispatch, useSelector } from "react-redux";

//
import { handleReadySubmit } from "../../../Redux/slices/form/slice";
//
import DialogContainer from "../../Dialog/Index";
import { StyledAlert } from "./Styled";

export default function TextCompareModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { old: oldJSON, new: newJSON } = useSelector((state) => state.modal?.modal?.previewData);
  const pageType = useSelector((state) => state.layout?.formPageType?.page);
  const scriptName = pageType?.split(".")?.at(1);

  const newStyles = {
    variables: {
      light: {
        codeFoldGutterBackground: "#6F767E",
        codeFoldBackground: "#E2E4E5",
      },
    },
  };

  const handleSubmit = () => {
    dispatch(handleReadySubmit());
    onClose();
  };

  return (
    <DialogContainer
      label={`Settings ${scriptName} Comparator`}
      isOpen={isOpen}
      onClose={onClose}
      actionLabel="Submit"
      onAction={() => handleSubmit()}
    >
      <StyledAlert severity="info">
        If you are sure that you want to save the changes, click the <span>Submit</span> button at the bottom of the
        modal, and then the <span>Save</span> button at the top of the page.
      </StyledAlert>
      <ReactDiffViewer
        oldValue={oldJSON}
        newValue={newJSON}
        splitView={true}
        compareMethod={DiffMethod.CHARS}
        styles={newStyles}
        leftTitle={`Your ${scriptName} Version`}
        rightTitle={`Latest Saved ${scriptName} Version`}
      />
    </DialogContainer>
  );
}
