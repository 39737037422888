import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4a1b8c02a0803222cf4dd0af8b878013@o4506789565497344.ingest.sentry.io/4506812461547520",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.2,
  tracePropagationTargets: ["localhost", /^https:\/\/.*\.igp\.cloud\//],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
