import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledOrderContainer = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const StyledSearchContainer = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  marginBottom: 14,
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  position: "absolute",
  bottom: "5px",
  left: "5px",
  color: theme.palette.grey.lighter,
}));

export const StyledSelectAll = styled("div")(({ theme }) => ({
  
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100% !important",
  label: {
    marginLeft: "35px",
  },
  ".MuiInputBase-input": {
    paddingLeft: "35px",
  },
  ".MuiInput-underline:after": {
    borderColor: theme.palette.primary.main,
  },
  ".MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
}));

export const GalleryWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  flexFlow: "wrap",
  ".item": {
    width: "calc(100% / 5)",
    padding: 12,
    ".item-wrapp": {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: 10,
      backgroundColor: theme.palette.primary.lighter,
      padding: "0 0 10px",
      cursor: "pointer",
      position: "relative",
      img: {
        width: "100%",
        height: 150,
        objectFit: "scale-down",
        borderRadius: 10,
      },
      p: {
        margin: "5px 0 0",
        color: theme.palette.primary.main,
      },
      ".check": {
        position: "absolute",
        top: 16,
        right: 16,
        width: 30,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: theme.palette.common.white,
        span: {
          width: 18,
          height: 18,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
          border: `1px solid ${theme.palette.grey.main}`,
        },
        "&.active": {
          span: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
            svg: {
              width: "100%",
              height: "100%",
            },
          },
        },
      },
    },
  },
  "&>div:last-child": {
    marginTop: 24,
  },
  ".no-result": {
    margin: "50px auto",
  },
}));
