import React from "react";

import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { SnackbarUtilConfig } from "./Components/Utils/SnackbarUtilConfig";
import store from "./Redux/index";

import "./sentry";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <SnackbarUtilConfig />
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);
