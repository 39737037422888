import React, { useState } from "react";

//
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab, CircularProgress } from "@mui/material";

//
import ImageLibrary from "../../components/ImageLibrary";
import { DragDropSelect, StyledOverrideTabBox } from "../../Styled";
//

export default function PaymentOverrideTabs({
  handleActiveDropzone,
  getInputProps,
  isLoading,
  getRootProps,
  overrideLogos,
  handleSetOverrideLogo,
}) {
  const [activeTab, setActiveTab] = useState("deposit");

  const tabs = [
    {
      key: "deposit",
      label: "Deposit logos",
      fields: ["depositLogo", "depositLogoHover"],
    },
    {
      key: "withdraw",
      label: "Withdraw logos",
      fields: ["withdrawLogo", "withdrawLogoHover"],
    },
  ];

  const generateFileFieldLabel = (key) => {
    const fieldLabel = key.charAt(0).toUpperCase() + key.slice(1);
    return fieldLabel?.replace(/([A-Z])/g, " $1").trim();
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, tab) => setActiveTab(tab)}>
            {tabs?.map((tab, idx) => (
              <Tab label={tab.label} value={tab.key} key={idx} sx={{ fontSize: 18 }} />
            ))}
          </TabList>
        </Box>
        {tabs?.map((tab, idx) => (
          <StyledOverrideTabBox value={tab.key} key={idx} style={{ px: 0 }}>
            {tab?.fields?.map((field, f_idx) => (
              <div className="field-wrapper" key={f_idx}>
                <h1>{generateFileFieldLabel(field)}</h1>
                <div className="upload-library-wrapper">
                  <DragDropSelect className="drop-zone">
                    <div {...getRootProps({ className: "dropzone", onClick: () => handleActiveDropzone(field) })}>
                      <input {...getInputProps()} />
                      <div className="drop-zone-img-wrapp">
                        <div className="img-wrapper drop-zone-wrapper">
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <span>Drop your files here</span>
                              <span>or</span>
                              <p>Select files</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </DragDropSelect>
                  <ImageLibrary
                    libraryKey={field}
                    overrideImages={overrideLogos}
                    handleSetOverrideImage={(key, obj) => handleSetOverrideLogo(key, obj)}
                  />
                </div>
              </div>
            ))}
          </StyledOverrideTabBox>
        ))}
      </TabContext>
    </Box>
  );
}
