import { FormGroup, FormControl } from "@mui/material";
import { styled } from "@mui/system";

export const StyledEditor = styled(FormGroup)(({ theme }) => ({
  ".tox ": {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ccc",
    borderRadius: 10,
    overflow: "hidden",
    ".tox-collection--list .tox-collection__item--active": {
      background: `${theme.palette.primary.lighter} !important`,
    },
  },
  ".tox-mbtn.tox-mbtn--select:hover": {
    background: `${theme.palette.primary.lighter} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  ".tox-toolbar .tox-toolbar__group button:hover": {
    background: `${theme.palette.primary.lighter} !important`,
    color: `${theme.palette.primary.main} !important`,
    span: {
      svg: {
        fill: `${theme.palette.primary.main} !important`,
        "#tox-icon-text-color__color": {
          fill: `${theme.palette.primary.main} !important`,
        },
      },
    },
    ".tox-tbtn__select-chevron": {
      svg: {
        fill: `${theme.palette.primary.main} !important`,
      },
    },
  },
  ".tox-split-button:hover": {
    ".tox-tbtn": {
      background: `${theme.palette.primary.lighter} !important`,
      color: `${theme.palette.primary.main} !important`,
      span: {
        svg: {
          fill: `${theme.palette.primary.main} !important`,
          "#tox-icon-text-color__color": {
            fill: `${theme.palette.primary.main} !important`,
          },
          "#tox-icon-highlight-bg-color__color": {
            fill: `${theme.palette.primary.main} !important`,
          },
        },
      },
    },
    ".tox-split-button__chevron": {
      svg: {
        fill: `${theme.palette.primary.main} !important`,
      },
    },
  },
  ".error-wrap": {
    fontSize: "0.75rem",
    color: `${theme.palette.error.main} !important`,
  },
}));

export const StyledSwitch = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: "10px 0",
  margin: "10px 0 12px",
  div: {
    padding: "5px 10px",
    position: "relative",
    fontSize: "16px",
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    cursor: "pointer",
    border: `5px solid ${theme.palette.primary.light}`,
    display: "flex",
    alignItems: "center",
    span: {
      zIndex: 1,
    },
  },
  ".show-code-btn": {
    borderRadius: "10px 0 0 10px",
  },
  ".show-editor-btn": {
    borderRadius: "0 10px 10px 0",
  },
  "div.show-editor-btn--active:after, div.show-code-btn--active:after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    background: theme.palette.common.white,
    borderRadius: "8px",
  },
}));

export const StyledCodeEditor = styled(FormControl)(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "Open Sans,sans-serif",
  border: `1px solid ${theme.palette.grey.light}`,
  borderRadius: "10px",
  padding: "16.5px 14px",
  color: theme.palette.grey.main,
}));
