import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import modalService from "../services/modalService";

export const getPreviewDataByID = createAsyncThunk("preview/data", async (pageFrom) => {
  const { pageType, isRevisions } = pageFrom;
  const res = await modalService.getPreviewDataByID(pageFrom);
  if (isRevisions) {
    return {
      type: "revisions",
      page: pageType,
      data: res?.data?.at(0) || [],
    };
  } else {
    return res.data;
  }
});
const modalSlice = createSlice({
  name: "modal",
  initialState: {
    modal: {
      type: "",
      isOpen: false,
      pageFrom: "",
      previewData: null,
      cb: null,
    },
    formFieldData: [],
  },
  reducers: {
    openModal: (state, action) => {
      const { modal, pageType, cb, previewData } = action.payload;
      return {
        ...state,
        modal: {
          pageFrom: pageType,
          modal: modal,
          isOpen: true,
          previewData: previewData,
          cb,
        },
      };
    },
    closeModal: (state, action) => {
      return {
        ...state,
        modal: {
          type: "",
          isOpen: false,
        },
      };
    },
    setFieldData: (state, action) => {
      const data = action.payload;
      return {
        ...state,
        formFieldData: { ...state.formFieldData, ...data },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreviewDataByID.fulfilled, (state, action) => {
      return {
        ...state,
        previewData: action.payload,
      };
    });
  },
});

export const { openModal, closeModal, setFieldData } = modalSlice.actions;
export default modalSlice.reducer;
