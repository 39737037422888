import Alert from "@mui/material/Alert";
import { styled } from "@mui/system";

export const StyledAlert = styled(Alert)(({ theme }) => ({
  margin: "0 0 1rem",
  backgroundColor: "rgb(225 252 255)",
  ".MuiAlert-message": {
    color: "#2BBCEA",
    span: {
      fontWeight: "bold",
    },
  },
}));
