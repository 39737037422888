import React, { useState } from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import General from "./components/General";
import Multilingual from "./components/Multilingual";
import DialogContainer from "../../Dialog/Index";

const RevisionModal = ({ isOpen, onClose }) => {
  const {
    modal: { previewData, pageFrom },
  } = useSelector((state) => state.modal);

  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = ["general", "multilingual"];
  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const navigate = useNavigate();
  const handleEditAction = () => {
    onClose();
    navigate(
      `/administration/${pageFrom}/edit/${
        previewData.type === "revisions" ? previewData.data[0].postID || previewData.postID : previewData.postID
      }`,
      { replace: true }
    );
  };

  return (
    <DialogContainer label="Preview" actionLabel="Edit" isOpen={isOpen} onAction={handleEditAction} onClose={onClose}>
      <Tabs value={selectedTab} onChange={handleChange}>
        {tabs.map((tab, idx) => {
          return (
            <Tab key={idx} label={tab} sx={{ textTransform: "capitalize", fontSize: "18px", fontWeight: "500" }} />
          );
        })}
      </Tabs>
      {!selectedTab ? (
        <General data={previewData} />
      ) : (
        <Multilingual data={previewData?.postContent ? previewData?.postContent : previewData} />
      )}
    </DialogContainer>
  );
};

export default RevisionModal;
