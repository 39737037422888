import { FormControl } from "@mui/material";
import { styled } from "@mui/system";

export const FormWrap = styled("form")(({ theme }) => ({
  paddingTop: "8px",
  height: "100%",
  width: "100%",
}));

export const StyledFormGroupContainer = styled("div")(({ theme }) => ({
  gap: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

export const StyledFormGroup = styled(FormControl)(({ theme, isValid }) => ({
  input: {
    padding: "16px 14px !important",
  },
  label: {
    "&.MuiFormLabel-root": {
      color: !isValid ? theme.palette.grey.main : theme.palette.error.main,
      "&.Mui-focused": {
        color: !isValid ? theme.palette.primary.main : theme.palette.error.main,
      },
    },
  },
  "p.MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));
