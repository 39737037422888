import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import translationsService from "../services/translationService";

export const getAllTranslations = createAsyncThunk("translations/all", async () => {
  const res = await translationsService.getAllTranslations();
  return res.data;
});

export const updateTranslation = createAsyncThunk("translations/update", async ({ id, data }, { getState }) => {
  const username = getState()?.user?.user?.username;
  const customData = {
    ...data,
    updatedByClient: username,
  };
  const res = await translationsService.updateTranslation(id, customData);
  return res.data;
});

export const addTranslation = createAsyncThunk("translations/add", async (dataToSubmit, { getState }) => {
  const username = getState()?.user?.user?.username;
  const customData = {
    ...dataToSubmit,
    updatedByClient: username,
  };
  const res = await translationsService.addTranslation(customData);
  return res.data;
});

export const deleteTranslation = createAsyncThunk("translations/delete", async (id) => {
  const res = await translationsService.deleteTranslation(id);
  return res;
});

const translationSlice = createSlice({
  name: "translations",
  initialState: {
    translationsData: [],
    loading: true,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTranslations.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getAllTranslations.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        translationsData: action.payload,
      };
    });
    builder.addCase(getAllTranslations.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    });
    builder.addCase(updateTranslation.pending, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(updateTranslation.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        translationsData: action.payload,
      };
    });
    builder.addCase(updateTranslation.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    });
    builder.addCase(addTranslation.pending, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(addTranslation.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        translationsData: action.payload,
      };
    });
    builder.addCase(addTranslation.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    });
    builder.addCase(deleteTranslation.pending, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(deleteTranslation.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        translationsData: action.payload,
      };
    });
    builder.addCase(deleteTranslation.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    });
  },
});
export default translationSlice.reducer;
