import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";

import {
  AccordionWrap,
  Divider,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./StyledComponents";
import { getPortalSettings } from "../../../../Redux/slices/settingsSlice";
import { transformText } from "../util/helpers";

const Multilingual = ({ data }) => {
  const portalSettings = useSelector((state) => state.settings.portalSettings);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPortalSettings());
  }, []);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (language) => (_, isExpanded) => {
    setExpanded(isExpanded ? language : false);
  };

  const [readMore, setReadMore] = useState(false);
  function toggleReadMore() {
    setReadMore(!readMore);
  }

  const multilingualItems = data?.multilingual?.map((obj) => ({
    language: obj?.language,
    "Title:": obj?.title || "There is no value defined",
    "Content:": transformText(obj?.content) || "There is no value defined",
    "SEO:": obj?.seo
      ? {
          "Title:": obj.seo?.title || "There is no value defined",
          "Meta description:": obj.seo?.metaDescription || "There is no value defined",
          "Meta keywords:": obj.seo?.metaKeywords || "There is no value defined",
          "OG title:": obj.seo?.ogTitle || "There is no value defined",
          "OG description:": obj.seo?.ogDescription || "There is no value defined",
          "OG type:": obj.seo?.ogType || "There is no value defined",
          "OG image:": obj.seo?.ogImage?.url || "There is no value defined",
        }
      : null,
  }));

  return (
    <AccordionWrap>
      {multilingualItems?.map((el, idx) => {
        const languageData = portalSettings?.general?.languages?.find((lang) => lang.code === el.language);
        return (
          <StyledAccordion key={idx} expanded={expanded === el.language} onChange={handleChange(el.language)}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} id={el.language}>
              <img
                className="flag"
                src={languageData?.flag && languageData?.flag?.url}
                alt={languageData?.flag && languageData?.flag?.alternativeText}
              />
              <div>{languageData?.name}</div>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <div className="items">
                <p className="item-key">{Object.keys(el)[1]}</p>
                <p className="item-value">{el["Title:"]}</p>
              </div>
              <div className="items">
                <p className="item-key">{Object.keys(el)[2]}</p>
                <p className="item-value">
                  {el["Content:"].length >= 100
                    ? readMore
                      ? el["Content:"] + " "
                      : el["Content:"].substring(0, 100) + "... "
                    : el["Content:"]}
                  {el["Content:"].length >= 100 && (
                    <button className="read-more-btn" onClick={toggleReadMore}>
                      {readMore ? "read less" : "read more"}
                    </button>
                  )}
                </p>
              </div>
              <Divider />
              <div className="seo-label">{Object.keys(el)[3]}</div>
              <div className="seo-content-grid">
                {el["SEO:"]
                  ? Object.entries(el["SEO:"])?.map(([key, value]) => (
                      <div key={key} className="items">
                        <p className="item-key">{key}</p>
                        <p className="item-value">{value}</p>
                      </div>
                    ))
                  : "There is no SEO defined"}
              </div>
            </StyledAccordionDetails>
          </StyledAccordion>
        );
      })}
    </AccordionWrap>
  );
};

export default Multilingual;
