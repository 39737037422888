import React from "react";

import { StyledLoader, StyledLoadingScreen } from "./StyledLoadingScreen";

export default function LoadingScreen() {
  return (
    <StyledLoadingScreen className="loading-wrapp">
      <StyledLoader>
        <svg viewBox="0 0 80 80">
          <circle id="test" cx="40" cy="40" r="32"></circle>
        </svg>
      </StyledLoader>

      <StyledLoader className="triangle">
        <svg viewBox="0 0 86 80">
          <polygon points="43 8 79 72 7 72"></polygon>
        </svg>
      </StyledLoader>

      <StyledLoader>
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
        </svg>
      </StyledLoader>
    </StyledLoadingScreen>
  );
}
