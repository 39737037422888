import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useWindowSize } from "../../custom_hooks/useWindowSize";
import { getPortalSettings } from "../../Redux/slices/settingsSlice";
import SidebarContent from "./components/SidebarContent";
import SidebarHeader from "./components/SidebarHeader";
import { StyledSidebar } from "./StyledSidebar";

export default function Sidebar() {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const collapseMenu = useSelector((state) => state.layout.collapseMenu);
  const portalSettings = useSelector((state) => state.settings.portalSettings);
  const isFullScreen = useSelector((state) => state.layout.isEditorFullScreen);

  // see if 404 page is opened. if it is, close the whole sidebar through styles
  const windowPathname = window.location.pathname;
  const is404 = !!windowPathname.includes("404");

  useEffect(() => {
    !portalSettings && dispatch(getPortalSettings());
  }, []);

  return (
    <StyledSidebar
      width={width}
      className={collapseMenu ? "collapse-sidebar" : is404 ? "not-found-sidebar" : ""}
      is404={is404}
      isFullScreen={isFullScreen}
    >
      <SidebarHeader width={width} />
      <SidebarContent />
    </StyledSidebar>
  );
}
